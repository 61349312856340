import React, { useMemo, useCallback, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Redirect, useHistory } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Button from 'components/Common/Button';
import IconButton from 'components/Common/IconButton';
import { logout } from 'state/actions/auth';
import {
  selectAuthenticatedUserState,
  selectLogoutState,
} from 'state/selectors/auth';
import ossaLogo from 'assets/logos/ossa-logo.png';
import checkIcon from 'assets/icons/check-violet.svg';
import logoutIcon from 'assets/icons/logout.png';
import Path from 'enums/path.enum';

import Tooltip from 'components/Common/Tooltip';
import classes from './Application.module.scss';

const Application = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const { success } = useSelector(selectLogoutState, shallowEqual);

  const userVerified = useMemo(() => !!user && user.enabled, [
    user,
    user?.enabled,
  ]);

  const authenticated = useMemo(() => !!user, [user]);

  const redirect = useMemo(() => userVerified && <Redirect to={Path.Home} />, [
    userVerified,
  ]);

  useEffect(() => {
    if (success) {
      history.push(Path.Login);
    }
  }, [success]);

  const onClickLogoutHandler = useCallback(() => {
    dispatch(logout());
  }, []);

  return (
    <>
      {redirect}
      <div className={classes.container}>
        <div className={classes.header}>
          <div className={classes.logo}>
            <img src={ossaLogo} alt="Ossa Logo" />
          </div>
          {authenticated && (
            <Tooltip
              component={() => (
                <IconButton
                  onClick={onClickLogoutHandler}
                  className={classes.logout}
                >
                  <img
                    src={logoutIcon}
                    alt="logout"
                    className={classes.logoutIcon}
                  />
                </IconButton>
              )}
              className={classes.tooltip}
            >
              <Body size={BodySize.XXS} className={classes.tooltipText}>
                Logout
              </Body>
            </Tooltip>
          )}
        </div>
        <div className={classes.text}>
          <img src={checkIcon} alt="check" className={classes.icon} />
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Thanks for applying
          </Body>
          <Body
            letterCase={BodyLetterCase.Lowercase}
            size={BodySize.XS}
            className={classes.subtext}
          >
            We are currently reviewing your application and will be in touch
            within 48 hours.
          </Body>
          {!authenticated && (
            <Button
              className={classes.button}
              onClick={() => history.push(Path.Login)}
            >
              Go to login
            </Button>
          )}
        </div>
        <div />
      </div>
    </>
  );
};

export default Application;
