import React from 'react';
import { useDispatch } from 'react-redux';
import { deleteCampaign } from 'state/actions/campaigns';
import PropTypes from 'prop-types';

import Button from 'components/Common/Button';
import Body, { Size, Weight } from 'components/Typography/Body';

import classes from './DeleteCampaignModal.module.scss';

const DeleteCampaignModal = ({ campaign, onClose }) => {
  const dispatch = useDispatch();

  const onDeleteCampaignHandler = () => {
    dispatch(deleteCampaign(campaign.uid));
    onClose();
  };

  return (
    <div className={classes.modalContent}>
      <Body size={Size.M} className={classes.title}>
        Are you sure you want to cancel the campaign{' '}
        <span className={classes.bold}>&quot;{campaign.name}</span>
        &quot;?
      </Body>
      <Body size={Size.S} weight={Weight.Light} className={classes.text}>
        Cancelling this campaign will also cancel all it&apos;s pending offers.
      </Body>
      <div className={classes.buttons}>
        <Button onClick={onClose} className={classes.cancelButton}>
          Close
        </Button>
        <Button
          onClick={onDeleteCampaignHandler}
          className={classes.deleteButton}
        >
          Confirm
        </Button>
      </div>
    </div>
  );
};

DeleteCampaignModal.propTypes = {
  campaign: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string,
  }).isRequired,
  onClose: PropTypes.func.isRequired,
};

export default DeleteCampaignModal;
