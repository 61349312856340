import StoragePaths from 'enums/storage/storagePaths.enum';
import { storage } from 'firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const uploadLogo = async ({ logo, email }) => {
  try {
    const logoPath = `${StoragePaths.LOGOS}${email}`;

    const storageRef = ref(storage, logoPath);

    await uploadBytes(storageRef, logo);

    const url = await getDownloadURL(ref(storage, logoPath));

    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export default uploadLogo;
