import { collection, doc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const getCampaignReference = ({ brandId }) => {
  try {
    const documentReference = doc(
      collection(
        firestore,
        `${Collections.Brands}/${brandId}/${Collections.Campaigns}`
      )
    );

    return documentReference;
  } catch (error) {
    throw new Error(error);
  }
};

export default getCampaignReference;
