const Path = {
  Home: '/',
  Login: '/login',
  SignUp: '/register',
  ResetPassword: '/resetPassword',
  NewPassword: '/newPassword',
  ThanksForApplying: '/application',
  Settings: '/settings',
  Campaigns: '/campaigns',
  NewCampaign: '/newCampaign',
  Podcasts: '/podcasts',
  CampaignPodcasts: '/podcasts/:campaignId',
  Summary: '/summary',
  CampaignSummary: '/summary/:campaignId/:error?',
  Checkout: '/checkout',
  Submitted: '/submitted',
  SubmittedCampaign: '/submitted/:campaignId',
};

export default Path;
