import React from 'react';

import classes from './Divider.module.scss';

const Divider = () => (
  <div className={classes.container}>
    <div className={classes.divider} />
  </div>
);

export default Divider;
