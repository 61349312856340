const adPlacements = [
  {
    label: 'pre-roll',
    value: 'preRoll',
  },
  {
    label: 'mid-roll',
    value: 'midRoll',
  },
  {
    label: 'post-roll',
    value: 'postRoll',
  },
];

export default adPlacements;
