export const campaignHeaders = [
  { label: 'Campaign', key: 'name' },
  { label: 'Ad Formats', key: 'adFormats' },
  { label: 'Ad Placements', key: 'adPlacements' },
  { label: 'Age Ranges', key: 'age' },
  { label: 'Airing From', key: 'airingFrom' },
  { label: 'Airing To', key: 'airingTo' },
  { label: 'Budget', key: 'budget' },
  { label: 'Categories', key: 'categories' },
  { label: 'Countries', key: 'countries' },
  { label: 'Cpm Rate', key: 'cpmRate' },
  { label: 'Description', key: 'description' },
  { label: 'Ethnicities', key: 'ethnicity' },
  { label: 'Gender', key: 'gender' },
  { label: 'Income', key: 'income' },
  { label: 'Is Paid', key: 'isPaid' },
  { label: 'Marital Status', key: 'maritalStatus' },
  { label: 'Network/s', key: 'networks' },
  { label: 'Number of Episodes', key: 'numberOfEpisodes' },
  { label: 'Total Offers', key: 'offersLength' },
  { label: 'Talking Points', key: 'talkingPoints' },
];

export const offerHeaders = [
  { label: 'Campaign Name', key: 'title' },
  { label: 'Podcast Name', key: 'podcastName' },
  { label: 'Ad Formats', key: 'adFormats' },
  { label: 'Ad Placements', key: 'adPlacements' },
  { label: 'Episodes', key: 'episodes' },
  { label: 'Impressions', key: 'impressions' },
  { label: 'Amount $', key: 'amount' },
];
