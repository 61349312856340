import React from 'react';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Link from 'components/Typography/Link';
import Path from 'enums/path.enum';
import { backgroundImageText } from 'assets/white-label';

import classes from './LoginLayout.module.scss';

const renderTextWithLineBreaks = (text) => {
  if (!text) return null;

  const lines = text.split(' ');
  return lines.map((line, index) => (
    <>
      {line}
      {index < lines.length - 1 && <br />}
    </>
  ));
};

const LoginLayout = ({ children }) => (
  <main className={classes.main}>
    <div className={classes.left}>
      <Body
        size={BodySize.L}
        color={BodyColor.White}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.L}
        className={classes.text}
      >
        {renderTextWithLineBreaks(backgroundImageText)}
      </Body>
    </div>
    <div className={classes.right}>
      <div className={classes.children}>
        {children}
        <div className={classes.footer}>
          <Link
            to={{ pathname: Path.TermsOfService }}
            underline={false}
            target="_blank"
          >
            <Body
              color={BodyColor.Gray}
              size={BodySize.XXS}
              letterCase={BodyLetterCase.Uppercase}
              spacing={BodySpacing.S}
              className={classes.bold}
            >
              Terms of service
            </Body>
          </Link>
          <Link
            to={{ pathname: Path.Privacy }}
            underline={false}
            target="_blank"
          >
            <Body
              color={BodyColor.Gray}
              size={BodySize.XXS}
              letterCase={BodyLetterCase.Uppercase}
              spacing={BodySpacing.S}
              className={classes.bold}
            >
              Privacy
            </Body>
          </Link>
        </div>
      </div>
    </div>
  </main>
);

LoginLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default LoginLayout;
