import { getAuth, sendPasswordResetEmail } from 'firebase/auth';

const resetPassword = async (email) => {
  try {
    const auth = getAuth();
    await sendPasswordResetEmail(auth, email);
  } catch (error) {
    throw new Error(error);
  }
};

export default resetPassword;
