const adFormats = [
  {
    label: 'Baked-in',
    value: 'bakedIn',
  },
  {
    label: 'Dynamic Ad (Episodes)',
    value: 'dynamicAdEpisodes',
  },
  {
    label: 'Dynamic Ad (Time)',
    value: 'dynamicAdTime',
  },
  {
    label: 'Dynamic',
    value: 'dynamicAd',
  },
  {
    label: 'Affiliate',
    value: 'affiliate',
  },
  { label: 'Run Of Network', value: 'programmatic' },
  {
    label: 'Youtube Simulcast',
    value: 'youtubeSimulcast',
  },
];

export default adFormats;
