/* eslint-disable react/jsx-props-no-spreading */
import React, { useEffect, useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Button, { Type as ButtonType } from 'components/Common/Button';

import classes from './Input.module.scss';

export const Type = Object.freeze({
  Text: 'text',
  Number: 'number',
  Password: 'password',
  Submit: 'submit',
  Reset: 'reset',
  Radio: 'radio',
  Checkbox: 'checkbox',
  Button: 'button',
  File: 'file',
  Image: 'image',
  Email: 'email',
  Tel: 'tel',
});

export const Size = Object.freeze({
  M: 'M',
  S: 'S',
  XS: 'XS',
});

export const IconPosition = Object.freeze({
  Left: 'left',
  Right: 'right',
});

const preventNegativeNumbers = (event) => {
  if (event.key === 'ArrowDown' && event.target.value <= 0) {
    event.preventDefault();
  }
};

const Input = ({
  type,
  label,
  name,
  allowNegativeValues,
  defaultValue,
  required,
  placeholder,
  disabled,
  readOnly,
  error,
  register,
  onChange,
  altIcon,
  icon,
  iconPosition,
  size,
  className,
  containerClassName,
  labelClassName,
  inputClassname,
  minNumber,
  onClickIcon,
  onKeyPress,
  validKeys,
  invalidKeys,
  ...rest
}) => {
  const ref = useRef(null);

  useEffect(() => {
    if ((invalidKeys || validKeys) && ref.current) {
      ref.current.addEventListener('keydown', (e) => {
        if (!allowNegativeValues) {
          preventNegativeNumbers(e);
        }

        if (invalidKeys && invalidKeys[e.key]) {
          e.preventDefault();
        }

        if (validKeys && !validKeys[e.key]) {
          e.preventDefault();
        }
      });
    }
  }, [invalidKeys, validKeys, ref, allowNegativeValues]);

  return (
    <div className={classNames(containerClassName, classes.container)}>
      {label && (
        <Body
          size={BodySize[size]}
          className={classNames(labelClassName, classes.labelText)}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <input
        className={classNames(className, classes.input, {
          [classes.inputHasIconLeft]:
            icon && iconPosition === IconPosition.Left,
          [classes.inputHasIconRight]:
            icon && iconPosition === IconPosition.Right,
          [classes.hasError]: error,
          [classes.isDisabled]: disabled,
          [classes.readOnly]: readOnly,
        })}
        id={name}
        name={name}
        // defaultValue={defaultValue || ''} TODO
        ref={(currentRef) => {
          ref.current = currentRef;
          register(currentRef);
        }}
        type={type}
        placeholder={placeholder}
        onChange={onChange}
        aria-label={name}
        disabled={disabled}
        readOnly={readOnly}
        min={minNumber}
        onKeyPress={(event) => {
          if (event.key === 'Enter') {
            onKeyPress();
          }
        }}
        {...rest}
      />
      {icon && onClickIcon ? (
        <Button
          type={ButtonType.Submit}
          className={classNames(classes.button, classes.icon, {
            [classes.rightIcon]: iconPosition === IconPosition.Right,
            [classes.leftIcon]: iconPosition === IconPosition.Left,
            [classes.disabledButton]: disabled,
          })}
          onClick={onClickIcon}
        >
          <img src={icon} alt={altIcon} />
        </Button>
      ) : (
        <img
          alt={altIcon}
          className={classNames(classes.icon, classes.imageIcon, {
            [classes.rightIcon]: iconPosition === IconPosition.Right,
            [classes.leftIcon]: iconPosition === IconPosition.Left,
          })}
          src={icon}
        />
      )}
    </div>
  );
};

Input.propTypes = {
  name: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
  allowNegativeValues: PropTypes.bool,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  altIcon: PropTypes.string,
  icon: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  inputClassname: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  minNumber: PropTypes.number,
  onClickIcon: PropTypes.func,
  onKeyPress: PropTypes.func,
  iconPosition: PropTypes.oneOf(Object.values(IconPosition)),
  validKeys: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
  invalidKeys: PropTypes.shape({
    [PropTypes.string]: PropTypes.bool,
  }),
};

Input.defaultProps = {
  type: Type.Text,
  register: () => {},
  allowNegativeValues: true,
  required: false,
  placeholder: '',
  altIcon: '',
  icon: null,
  onChange: () => {},
  onKeyPress: () => {},
  onClickIcon: null,
  error: false,
  disabled: false,
  size: Size.XS,
  className: '',
  containerClassName: '',
  labelClassName: '',
  inputClassname: '',
  readOnly: false,
  defaultValue: null,
  label: null,
  minNumber: null,
  iconPosition: IconPosition.Left,
  validKeys: null,
  invalidKeys: null,
};

export default Input;
