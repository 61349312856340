import changePasswordManuallyService from 'services/auth/changePasswordManually';

const changeUserPassword = async ({ user, logo }) => {
  if (user.password) {
    try {
      await changePasswordManuallyService(user.password);
    } catch (error) {
      let errorMessage = '';

      if (logo) {
        errorMessage = errorMessage.concat(
          'The logo was successfully updated. '
        );
      }

      if (Object.keys(user).length > 1) {
        errorMessage = errorMessage.concat(
          'The fields were correctly updated. '
        );
      }

      errorMessage = errorMessage.concat(
        'There was an error updating the password.'
      );

      throw errorMessage;
    }
  }
};

export default changeUserPassword;
