import React, { useCallback, useEffect, useRef, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Tabs from 'components/Common/Tabs';
import Tab from 'components/Common/Tab';
import Details from 'components/Pages/Campaign/Details';
import Spinner from 'components/Common/Spinner';
import Modal from 'components/Common/Modal';
import Summary from 'components/Pages/Campaign/Summary';
import IconButton from 'components/Common/IconButton';
import AdCopy from 'components/Pages/Campaign/AdCopy';
import PodcastSelection from 'components/Pages/Campaign/PodcastSelection';
import ExportCampaignModal from 'components/Pages/Campaign/ExportCampaignModal';

import {
  selectSendAdCopyState,
  selectFetchCampaignsPendingOffersState,
  selectCampaignsTabState,
  selectDeleteCampaignState,
  selectSendTalkingPointsState,
} from 'state/selectors/campaigns';
import {
  fetchAllCurrentCampaigns,
  fetchAllPendingCampaigns,
  fetchAllFinishedCampaigns,
  updateCampaignsTabAction,
  clearTalkingPointsState,
  clearCampaignState,
} from 'state/actions/campaigns';
import ModalType from 'enums/modal/modalTypes';
import Path from 'enums/path.enum';
import CampaignsTab from 'enums/pages/campaigns/campaignsTab.enum';
import createIcon from 'assets/icons/create.png';
import useCampaigns from 'hooks/useCampaigns';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import sortingOptions from 'utils/selectOptions/getSortingOptions';
import SearchCampaign from './SearchCampaign';
import classes from './Campaigns.module.scss';

const Campaigns = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [searchValue, setSearchValue] = useState('');
  const [showCampaignsPending, setShowCampaignsPending] = useState([]);
  const [showCampaignsCurrent, setShowCampaignsCurrent] = useState([]);
  const [showCampaignsFinished, setShowCampaignsFinished] = useState([]);
  const buttonRef = useRef(null);

  const {
    lastDocPendingCampaigns,
    loadingPendingCampaigns,
    pendingCampaigns,
    lastDocCurrentCampaigns,
    loadingCurrentCampaigns,
    currentCampaigns,
    loadingFinishedCampaigns,
    lastDocFinishedCampaigns,
    finishedCampaigns,
    modal,
    onOpenModalHandler,
    onCloseModalHandler,
    selectedSort,
    onChangeSelectedSorting,
    fetchMore,
    setFetchMore,
    loadingPendingOffers,
  } = useCampaigns();

  const { pendingOffers } = useSelector(
    selectFetchCampaignsPendingOffersState,
    shallowEqual
  );

  const { success: successSendingAdCopy } = useSelector(
    selectSendAdCopyState,
    shallowEqual
  );

  const {
    success: successEditingTalkingPoints,
    error: errorEditingTalkingPoints,
  } = useSelector(selectSendTalkingPointsState, shallowEqual);
  const { campaignsTab } = useSelector(selectCampaignsTabState, shallowEqual);

  const {
    success: successDeletingCampaign,
    error: errorDeletingCampaign,
  } = useSelector(selectDeleteCampaignState, shallowEqual);

  const successToastShownRef = useRef(false);
  const errorToastShownRef = useRef(false);

  useEffect(() => {
    if (successDeletingCampaign && !successToastShownRef.current) {
      dispatch(fetchAllPendingCampaigns(null, ''));
      setSearchValue('');
      toast.success('The campaign has been successfully deleted', {
        position: 'bottom-center',
      });
      successToastShownRef.current = true;
    } else {
      successToastShownRef.current = false;
    }

    dispatch(clearCampaignState());
  }, [successDeletingCampaign]);

  useEffect(() => {
    if (errorDeletingCampaign && !errorToastShownRef.current) {
      setSearchValue('');
      toast.error('An error occurred while deleting the campaign', {
        position: 'bottom-center',
      });
      errorToastShownRef.current = true;
    } else {
      errorToastShownRef.current = false;
    }

    dispatch(clearCampaignState());
  }, [errorDeletingCampaign]);

  useEffect(() => {
    if (campaignsTab === null) {
      dispatch(updateCampaignsTabAction(CampaignsTab.YourOffers));
    }
  }, []);

  useEffect(() => {
    if (successSendingAdCopy) {
      onCloseModalHandler();
      dispatch(fetchAllCurrentCampaigns(null, '', [], sortingOptions[2]));
    }
  }, [successSendingAdCopy]);

  useEffect(() => {
    setShowCampaignsCurrent([]);
    if (successEditingTalkingPoints) {
      dispatch(fetchAllCurrentCampaigns(null, '', [], sortingOptions[2]));
      onCloseModalHandler();
      if (!successToastShownRef.current) {
        toast.success('The talking points were edited successfully!', {
          position: 'bottom-center',
        });
        successToastShownRef.current = true;
      } else {
        successToastShownRef.current = false;
      }

      dispatch(clearTalkingPointsState());
    }
  }, [successEditingTalkingPoints]);

  useEffect(() => {
    if (errorEditingTalkingPoints) {
      onCloseModalHandler();
      dispatch(fetchAllCurrentCampaigns(null, '', [], sortingOptions[2]));
      if (!successToastShownRef.current) {
        toast.error('An error occurred while editing the talking points', {
          position: 'bottom-center',
        });
        successToastShownRef.current = true;
      } else {
        successToastShownRef.current = false;
      }

      dispatch(clearTalkingPointsState());
    }
  }, [errorEditingTalkingPoints]);

  const onClickMoreDetailsHandler = useCallback((campaign) => {
    onOpenModalHandler(ModalType.CAMPAIGN_SUMMARY, {
      campaign,
    });
  }, []);

  const onClickAdCopyHandler = useCallback(
    ({ campaign, offer, finished, addToAll }) => {
      onOpenModalHandler(ModalType.AD_COPY, {
        campaign,
        offer,
        finished,
        addToAll,
      });
    },
    []
  );

  const onClickExportCampaignHandler = (campaign) => {
    onOpenModalHandler(ModalType.EXPORT_CAMPAIGN, {
      campaign,
    });
  };

  const onClickRejectedOfferHandler = useCallback((campaign) => {
    history.push(`${Path.Podcasts}/${campaign.uid}`);
  }, []);

  const getCampaignOffersToExport = (campaign) => {
    if (!campaign) return [];

    if (!campaign.isPaid) return [...(pendingOffers?.[campaign.uid] || [])];

    return campaign.offers;
  };

  const handleChangeSelectSort = (sort) => {
    setSearchValue('');
    onChangeSelectedSorting(sort);
  };

  useEffect(() => {
    buttonRef.current = null;
    if (campaignsTab === CampaignsTab.YourOffers) {
      dispatch(fetchAllPendingCampaigns(null, '', [], selectedSort));
    }
    if (campaignsTab === CampaignsTab.CurrentCampaigns) {
      dispatch(fetchAllCurrentCampaigns(null, '', [], selectedSort));
    }
    if (campaignsTab === CampaignsTab.FinishedCampaigns) {
      dispatch(fetchAllFinishedCampaigns(null, '', [], selectedSort));
    }
  }, [selectedSort]);

  useEffect(() => {
    buttonRef.current = null;
    if (searchValue.length > 0) {
      if (campaignsTab === CampaignsTab.YourOffers) {
        dispatch(
          fetchAllPendingCampaigns(null, searchValue, [], sortingOptions[2])
        );
      }
      if (campaignsTab === CampaignsTab.CurrentCampaigns) {
        dispatch(
          fetchAllCurrentCampaigns(null, searchValue, [], sortingOptions[2])
        );
      }
      if (campaignsTab === CampaignsTab.FinishedCampaigns) {
        dispatch(
          fetchAllFinishedCampaigns(null, searchValue, [], sortingOptions[3])
        );
      }
    } else if (campaignsTab === CampaignsTab.FinishedCampaigns) {
      onChangeSelectedSorting(sortingOptions[3]);
    } else {
      onChangeSelectedSorting(sortingOptions[2]);
    }
  }, [searchValue]);

  const handleSearch = ({ target }) => {
    onChangeSelectedSorting(sortingOptions[0]);
    setSearchValue(target.value);
  };

  useEffect(() => {
    if (fetchMore) {
      if (campaignsTab === CampaignsTab.YourOffers) {
        dispatch(
          fetchAllPendingCampaigns(
            lastDocPendingCampaigns,
            '',
            pendingCampaigns,
            selectedSort
          )
        );
      }
      if (campaignsTab === CampaignsTab.CurrentCampaigns) {
        dispatch(
          fetchAllCurrentCampaigns(
            lastDocCurrentCampaigns,
            '',
            currentCampaigns,
            selectedSort
          )
        );
      }
      if (campaignsTab === CampaignsTab.FinishedCampaigns) {
        dispatch(
          fetchAllFinishedCampaigns(
            lastDocFinishedCampaigns,
            '',
            finishedCampaigns,
            selectedSort
          )
        );
      }
    }
  }, [fetchMore]);

  useEffect(() => {
    buttonRef.current = null;
    setSearchValue('');
    if (campaignsTab === CampaignsTab.YourOffers) {
      onChangeSelectedSorting(sortingOptions[2]);
      dispatch(fetchAllPendingCampaigns(null, '', [], sortingOptions[2]));
    }
    if (campaignsTab === CampaignsTab.CurrentCampaigns) {
      onChangeSelectedSorting(sortingOptions[2]);
      dispatch(fetchAllCurrentCampaigns(null, '', [], sortingOptions[2]));
    }
    if (campaignsTab === CampaignsTab.FinishedCampaigns) {
      onChangeSelectedSorting(sortingOptions[3]);
      dispatch(fetchAllFinishedCampaigns(null, '', [], sortingOptions[3]));
    }
  }, [campaignsTab]);

  useEffect(() => {
    if (!loadingPendingCampaigns && campaignsTab === CampaignsTab.YourOffers) {
      setShowCampaignsPending(pendingCampaigns);
      setFetchMore(false);
    }
  }, [loadingPendingCampaigns, pendingCampaigns]);

  useEffect(() => {
    if (
      !loadingCurrentCampaigns &&
      campaignsTab === CampaignsTab.CurrentCampaigns
    ) {
      setShowCampaignsCurrent(currentCampaigns);
      setFetchMore(false);
    }
  }, [loadingCurrentCampaigns, currentCampaigns]);

  useEffect(() => {
    if (
      !loadingFinishedCampaigns &&
      campaignsTab === CampaignsTab.FinishedCampaigns
    ) {
      setShowCampaignsFinished(finishedCampaigns);
      setFetchMore(false);
    }
  }, [loadingFinishedCampaigns, finishedCampaigns]);

  const fetchMoreCampaigns = () => {
    setFetchMore(true);
    buttonRef?.current?.scrollIntoView({ block: 'end', behavior: 'smooth' });
  };

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.CAMPAIGN_SUMMARY}
        onClose={onCloseModalHandler}
        title="Campaign details"
        className={classes.campaignSummaryModal}
      >
        <Summary campaign={modal.campaign} title="Campaign details" />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.AD_COPY}
        onClose={onCloseModalHandler}
        className={classes.adCopyModal}
      >
        <AdCopy
          campaign={modal.campaign}
          offer={modal.offer}
          finishedCampaign={modal.finished}
          addToAll={modal.addToAll}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.EXPORT_CAMPAIGN}
        onClose={onCloseModalHandler}
        className={classes.exportCampaignModal}
      >
        <ExportCampaignModal
          campaign={modal.campaign}
          offers={getCampaignOffersToExport(modal.campaign)}
          onClose={onCloseModalHandler}
        />
      </Modal>
      <div className={classes.container}>
        <IconButton
          onClick={() => history.push(Path.NewCampaign)}
          className={classes.newCampaign}
        >
          <img src={createIcon} alt="create" />
          <Body size={BodySize.XS} className={classes.newCampaignText}>
            New campaign
          </Body>
        </IconButton>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Campaigns
          </Body>
          <Tabs
            initialActiveTab={
              campaignsTab !== CampaignsTab.YourOffers ? campaignsTab : null
            }
            className={classes.tabs}
          >
            <Tab tab={CampaignsTab.YourOffers} key={CampaignsTab.YourOffers}>
              <div className={classes.campaigns}>
                <SearchCampaign
                  handleSearch={handleSearch}
                  searchValue={searchValue}
                  showFilter
                  selectedSort={selectedSort}
                  handleSelect={handleChangeSelectSort}
                />
                {loadingPendingCampaigns && !fetchMore ? (
                  <Spinner className={classes.spinner} />
                ) : (
                  <>
                    {showCampaignsPending.map((campaign) => (
                      <PodcastSelection
                        campaign={campaign}
                        pendingOffers={pendingOffers?.[campaign.uid]}
                        onClickMoreDetails={() =>
                          onClickMoreDetailsHandler(campaign)
                        }
                        onClickExportCampaign={() =>
                          onClickExportCampaignHandler(campaign)
                        }
                      />
                    ))}
                    {!loadingPendingCampaigns && pendingCampaigns.length === 0 && (
                      <Body
                        letterCase={BodyLetterCase.Uppercase}
                        size={BodySize.XS}
                        className={classes.noCampaigns}
                      >
                        {searchValue.length > 0 &&
                          'There are no campaigns under that name'}
                        {searchValue.length === 0 &&
                          'There are no pending campaigns'}
                      </Body>
                    )}
                  </>
                )}
                {!loadingPendingOffers &&
                  lastDocPendingCampaigns &&
                  pendingCampaigns.length > 9 && (
                    <div className={classes.containerButton} ref={buttonRef}>
                      <Button
                        className={classes.button}
                        loading={loadingPendingCampaigns}
                        kind={ButtonKind.Primary}
                        size={ButtonSize.S}
                        onClick={fetchMoreCampaigns}
                      >
                        Load more
                      </Button>
                    </div>
                  )}
              </div>
            </Tab>
            <Tab
              tab={CampaignsTab.CurrentCampaigns}
              key={CampaignsTab.CurrentCampaigns}
            >
              <div className={classes.campaigns}>
                <SearchCampaign
                  handleSearch={handleSearch}
                  searchValue={searchValue}
                  showFilter
                  selectedSort={selectedSort}
                  handleSelect={handleChangeSelectSort}
                />
                {loadingCurrentCampaigns && !fetchMore ? (
                  <Spinner className={classes.spinner} />
                ) : (
                  <>
                    {showCampaignsCurrent.map((campaign) => (
                      <Details
                        key={campaign.uid}
                        campaign={campaign}
                        onClickMoreDetails={() =>
                          onClickMoreDetailsHandler(campaign)
                        }
                        onClickRejectedOffer={() =>
                          onClickRejectedOfferHandler(campaign)
                        }
                        onClickAdCopy={(offer, addToAll) =>
                          onClickAdCopyHandler({
                            offer,
                            campaign,
                            finished: false,
                            addToAll,
                          })
                        }
                        onClickExportCampaign={() =>
                          onClickExportCampaignHandler(campaign)
                        }
                      />
                    ))}
                    {!loadingCurrentCampaigns && currentCampaigns.length === 0 && (
                      <Body
                        letterCase={BodyLetterCase.Uppercase}
                        size={BodySize.XS}
                        className={classes.noCampaigns}
                      >
                        {searchValue.length > 0 &&
                          'There are no campaigns under that name'}
                        {searchValue.length === 0 &&
                          'There are no current campaigns'}
                      </Body>
                    )}
                  </>
                )}
                {lastDocCurrentCampaigns && showCampaignsCurrent.length > 9 && (
                  <div className={classes.containerButton} ref={buttonRef}>
                    <Button
                      className={classes.button}
                      loading={loadingCurrentCampaigns}
                      kind={ButtonKind.Primary}
                      size={ButtonSize.S}
                      onClick={fetchMoreCampaigns}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </Tab>
            <Tab
              tab={CampaignsTab.FinishedCampaigns}
              key={CampaignsTab.FinishedCampaigns}
            >
              <div className={classes.campaigns}>
                <SearchCampaign
                  handleSearch={handleSearch}
                  searchValue={searchValue}
                  showFilter
                  selectedSort={selectedSort}
                  handleSelect={handleChangeSelectSort}
                />
                {loadingFinishedCampaigns && !fetchMore ? (
                  <Spinner className={classes.spinner} />
                ) : (
                  <>
                    {showCampaignsFinished.map((campaign) => (
                      <div key={Math.random()}>
                        <Details
                          key={campaign.uid}
                          campaign={campaign}
                          finishedCampaign
                          onClickMoreDetails={() =>
                            onClickMoreDetailsHandler(campaign)
                          }
                          onClickAdCopy={(offer) =>
                            onClickAdCopyHandler({
                              offer,
                              campaign,
                              finished: true,
                            })
                          }
                          onClickExportCampaign={() =>
                            onClickExportCampaignHandler(campaign)
                          }
                        />
                      </div>
                    ))}
                    {!loadingFinishedCampaigns &&
                      finishedCampaigns.length === 0 && (
                        <Body
                          letterCase={BodyLetterCase.Uppercase}
                          size={BodySize.XS}
                          className={classes.noCampaigns}
                        >
                          {searchValue.length > 0 &&
                            'There are no campaigns under that name'}
                          {searchValue.length === 0 &&
                            'There are no finished campaigns'}
                        </Body>
                      )}
                  </>
                )}
                {lastDocFinishedCampaigns && showCampaignsFinished.length > 9 && (
                  <div className={classes.containerButton} ref={buttonRef}>
                    <Button
                      className={classes.button}
                      loading={loadingFinishedCampaigns}
                      kind={ButtonKind.Primary}
                      size={ButtonSize.S}
                      onClick={fetchMoreCampaigns}
                    >
                      Load more
                    </Button>
                  </div>
                )}
              </div>
            </Tab>
          </Tabs>
        </div>
      </div>
    </>
  );
};

export default Campaigns;
