import { functions } from 'firebase';
import { createUserWithEmailAndPassword, getAuth } from 'firebase/auth';
import { httpsCallable } from 'firebase/functions';

const refreshUser = async (auth) => {
  const user = auth.currentUser;

  await user?.reload();
  await user?.getIdToken(true);
};

const signUp = async (data) => {
  try {
    const auth = getAuth();
    await createUserWithEmailAndPassword(auth, data.email, data.password);

    const user = auth.currentUser;
    const assignRole = httpsCallable(functions, 'brands-assignRole');

    await assignRole({ brandId: user.uid });

    await refreshUser(auth);

    return user.uid;
  } catch (error) {
    throw new Error(error);
  }
};

export default signUp;
