import { createSelector } from '@reduxjs/toolkit';

const selectGeneralsState = (state) => state.generals;

export const selectFetchPodcastersCountriesIsoCodes = createSelector(
  selectGeneralsState,
  ({
    fetchPodcastersCountriesIsoCodesLoading,
    fetchPodcastersCountriesIsoCodesSuccess,
    fetchPodcastersCountriesIsoCodesError,
    podcastersIsoCodes,
  }) => ({
    loading: fetchPodcastersCountriesIsoCodesLoading,
    success: fetchPodcastersCountriesIsoCodesSuccess,
    error: fetchPodcastersCountriesIsoCodesError,
    isoCodes: podcastersIsoCodes,
  })
);

export const selectFetchDefaultValues = createSelector(
  selectGeneralsState,
  ({
    fetchDefaultValuesLoading,
    fetchDefaultValuesSuccess,
    fetchDefaultValuesError,
    defaultValues,
  }) => ({
    loading: fetchDefaultValuesLoading,
    success: fetchDefaultValuesSuccess,
    error: fetchDefaultValuesError,
    defaultValues,
  })
);

export const selectFetchHostingProviders = createSelector(
  selectGeneralsState,
  ({
    fetchHostingProvidersLoading,
    fetchHostingProvidersSuccess,
    fetchHostingProvidersError,
    hostingProviders,
  }) => ({
    loading: fetchHostingProvidersLoading,
    success: fetchHostingProvidersSuccess,
    error: fetchHostingProvidersError,
    hostingProviders,
  })
);
