import dayjs from 'dayjs';

import { GENDER_VARIATION, PERCENTAGE_CONVERTION } from 'constants/campaign';

const getPodchaserFilters = ({ allPodcasts, campaign }) => {
  const filteredPodcasts = allPodcasts.filter((podcast) => {
    let ageFilter = true;
    let ethnicityFilter = true;
    let incomeFilter = true;
    let genderFilter = true;
    let categoriesFilter = true;
    let ratingFilter = true;

    if (campaign.age && podcast.podcastData?.demographics?.medianAge) {
      const currentYear = dayjs().year();
      const endAge = currentYear - campaign.age.start;
      const startAge = currentYear - campaign.age.end;

      ageFilter =
        podcast.podcastData?.demographics?.medianAge >= startAge &&
        podcast.podcastData?.demographics?.medianAge <= endAge;
    }

    if (campaign.ethnicity && podcast.podcastData?.ethnicities) {
      const podcastEthnicity = podcast.podcastData?.ethnicities?.find(
        (ethnicity) =>
          ethnicity.percentage ===
          Math.max(...podcast.podcastData?.ethnicities.map((e) => e.percentage))
      );

      ethnicityFilter = podcast.podcastData?.ethnicities?.some(
        (ethnicity) =>
          ethnicity.title === campaign.ethnicity &&
          ethnicity.title === podcastEthnicity.title
      );
    }

    if (campaign.income && podcast.podcastData?.demographics?.medianIncome) {
      incomeFilter =
        podcast.podcastData?.demographics?.medianIncome >
          campaign.income.start &&
        podcast.podcastData?.demographics?.medianIncome < campaign.income.end;
    }

    if (
      campaign.gender.male !== 50 &&
      podcast.podcastData?.demographics?.genderBias
    ) {
      genderFilter =
        Math.round(
          podcast.podcastData?.demographics?.genderBias * PERCENTAGE_CONVERTION
        ) <
          campaign.gender.male + GENDER_VARIATION &&
        Math.round(
          podcast.podcastData?.demographics?.genderBias * PERCENTAGE_CONVERTION
        ) >
          campaign.gender.male - GENDER_VARIATION;
    }

    if (campaign.categories.length > 0 && podcast.podcastData?.categories) {
      categoriesFilter = podcast.podcastData?.categories?.some((category) =>
        campaign.categories.includes(category.slug)
      );
    }

    if (campaign.minRating && podcast.podcastData?.ratingAverage) {
      ratingFilter = podcast.podcastData?.ratingAverage >= campaign.minRating;
    }

    return (
      ageFilter &&
      ethnicityFilter &&
      incomeFilter &&
      genderFilter &&
      categoriesFilter &&
      ratingFilter
    );
  });

  return filteredPodcasts;
};

export default getPodchaserFilters;
