/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import LoginLayout from 'components/Layouts/LoginLayout';
import RestrictedRoute from '../RestrictedRoute';

const LoginRoute = (props) => (
  <LoginLayout>
    <RestrictedRoute {...props} />
  </LoginLayout>
);

export default LoginRoute;
