import { useCallback, useEffect, useMemo } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import classNames from 'classnames';

import {
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import {
  clearEstimationsFiltersState,
  fetchNewCampaignEstimations,
} from 'state/actions/campaigns';
import { getCampaignGenderAgeAndIncomeInputs } from 'utils/inputs/createCampaignInputs';
import useCancellableTimeout from 'hooks/useCancellableTimeout';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import classes from './GenderAgeAndIncomeForm.module.scss';

const GenderAgeAndIncomeForm = () => {
  const dispatch = useDispatch();
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const { inTimeout, createTimeout } = useCancellableTimeout();

  const onSubmitHandler = (data) => {
    const female = data.gender ?? 50;

    const parsedData = {
      ...data,
      gender: { male: 100 - female, female },
    };

    onSubmit(parsedData);
    goForward();
  };

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const { estimationsSummary, loading: estimatingPodcasters } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const defaultData = formsData[currentStep] ?? campaign;

  const {
    gender: persistedGender,
    age: persistedAge,
    income: persistedIncome,
  } = defaultData || {};

  const calculateCampaignEstimations = useCallback(
    (args) => {
      createTimeout(() => {
        dispatch(
          fetchNewCampaignEstimations({ filters: args, localFilter: true })
        );
      }, 1500);
    },
    [dispatch, createTimeout]
  );

  const onChangeDo = useCallback(
    (data, extraData) => {
      const { name } = extraData;

      if (name === 'gender') {
        const gender = { male: 100 - data, female: data };

        calculateCampaignEstimations({ gender });
      }

      if (name === 'age') {
        calculateCampaignEstimations({ age: data });
      }

      if (name === 'income') {
        calculateCampaignEstimations({ income: data });
      }
    },
    [calculateCampaignEstimations]
  );

  const campaignGenderAgeAndIncomeFields = useMemo(
    () =>
      getCampaignGenderAgeAndIncomeInputs({
        defaultValues: defaultData,
        onChangeDo,
      }),
    [defaultData, onChangeDo]
  );

  const onGoBackHandler = () => {
    if (
      !persistedGender?.female &&
      !persistedAge?.length &&
      !persistedIncome?.length
    ) {
      dispatch(
        clearEstimationsFiltersState({ filters: ['gender', 'income', 'age'] })
      );
    }

    goBack();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const isLoading = inTimeout || estimatingPodcasters;

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Choose your audience. You can skip this step if you wish.
        </Body>
        <div className={classes.fields}>
          {campaignGenderAgeAndIncomeFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>
      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        estimatedImpressions={estimatedImpressions}
        potentialPodcasts={potentialPodcasts}
        loading={inTimeout}
      />
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={onGoBackHandler}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default GenderAgeAndIncomeForm;
