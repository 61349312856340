import {
  query,
  collectionGroup,
  where,
  getDocs,
  getDoc,
  doc,
  Timestamp,
} from 'firebase/firestore';

import { firestore } from 'firebase';
import groupBy from 'utils/functions/groupBy';
import calculateOfferAmount from 'utils/functions/calculateOfferAmount';
import Collections from 'enums/firebase/collections.enum';
import { MAXIMUM_FIREBASE_IN } from 'constants/firebase';

const fetchCampaignsPendingOffers = async ({ campaigns, defaultValues }) => {
  try {
    const campaignsId = campaigns.map((campaign) => campaign.uid);
    const campaignIdToPercentages = {};
    const offset = MAXIMUM_FIREBASE_IN;
    const slices = [];

    campaigns.forEach((campaign) => {
      campaignIdToPercentages[campaign.uid] = campaign.percentages;
    });

    for (let index = 0; index < campaignsId.length; index += offset) {
      slices.push(campaignsId.slice(index, index + offset));
    }

    const promises = slices.flatMap((dataSets) => {
      const pendingOffersQuery = query(
        collectionGroup(firestore, Collections.Offers),
        where('campaignId', 'in', dataSets),
        where('status', '==', 'waiting-brand-approval')
      );
      return getDocs(pendingOffersQuery);
    });

    const snapshots = await Promise.all(promises);

    const pendingOffersSnapshots = snapshots.flatMap(
      (snapshot) => snapshot.docs
    );

    const podcasterDataPromises = pendingOffersSnapshots.map((podcasterDoc) => {
      const data = podcasterDoc.data();

      return getDoc(doc(firestore, Collections.PodcastUsers, data.podcasterId));
    });

    const podcasterDataSnapshots = await Promise.all(podcasterDataPromises);

    const podcastersData = {};

    podcasterDataSnapshots.forEach((snapshot) => {
      podcastersData[snapshot.id] = snapshot.data();
    });

    const pendingOffersData = pendingOffersSnapshots.map((offerDoc) => {
      const data = offerDoc.data();

      const { podcastData, email, releaseDays } = podcastersData[
        data.podcasterId
      ];

      const { publishingDates } = data;

      const newPublishingDates =
        publishingDates?.length > 0
          ? publishingDates.map((date) =>
              new Timestamp(date.seconds, date.nanoseconds).toDate()
            )
          : [];

      const { webUrl, iTunesId } = podcastData;

      const percentages =
        campaignIdToPercentages[data.campaignId] ?? defaultValues.percentages;

      const calculatedAmount = calculateOfferAmount(
        percentages.podcaster,
        data.amount
      );

      return {
        ...data,
        releaseDays: releaseDays ?? [],
        publishingDates: newPublishingDates,
        podcasterEmail: email,
        webUrl: webUrl ?? null,
        iTunesId: iTunesId ?? null,
        amount: calculatedAmount,
        id: offerDoc.id,
      };
    });

    const groupedPendingOffers = groupBy(pendingOffersData, 'campaignId');

    return groupedPendingOffers;
  } catch (error) {
    throw Error(error);
  }
};

export default fetchCampaignsPendingOffers;
