import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, {
  Size as BodySize,
  Weight as BodyWeight,
} from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import adTypeInputs from 'utils/inputs/addTypeInputs';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';
import offerStatus from 'enums/campaign/offerStatus.enum';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalTypes';
import Modal from 'components/Common/Modal';
import NotesToTheBrand from 'components/Pages/Campaign/NotesToTheBrand';
import SendSample from 'components/Pages/Campaign/SendSample';
import plural from 'utils/commons/plural';
import PodcastData from 'components/Pages/Podcast/PodcastData';
import classes from './Expanded.module.scss';
import EditTalkingPointsAll from '../EditTalkingPointsAllModal';

const Expanded = ({
  campaign,
  offer,
  index,
  onClickRejectedOffer,
  onClickAdCopy,
  noAdCopys,
  finishedCampaign,
}) => {
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onClickNotesToTheBrandHandler = useCallback((modalOffer) => {
    onOpenModalHandler(ModalType.NOTES_TO_THE_BRAND, { offer: modalOffer });
  }, []);

  const onClickSendSampleHandler = useCallback(() => {
    onOpenModalHandler(ModalType.SEND_SAMPLE, { offer });
  }, [offer]);

  const onClickTalkingPointsHandler = useCallback(() => {
    onOpenModalHandler(ModalType.EDIT_TALKING_POINTS, { offer });
  }, [offer]);

  const onClickMoreInfoHandler = useCallback(
    (podcast) => {
      onOpenModalHandler(ModalType.PODCAST_DATA, {
        podcast,
      });
    },
    [offer]
  );

  return (
    <>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_DATA}
        onClose={onCloseModalHandler}
      >
        <PodcastData
          addButton={false}
          podcast={modal.podcast}
          onClose={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.NOTES_TO_THE_BRAND}
        onClose={onCloseModalHandler}
        className={classes.notesToTheBrandModal}
      >
        <NotesToTheBrand offer={modal.offer} />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.SEND_SAMPLE}
        onClose={onCloseModalHandler}
        className={classes.sendSampleModal}
      >
        <SendSample offer={modal.offer} />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.EDIT_TALKING_POINTS}
        onClose={onCloseModalHandler}
        className={classes.talkingPointsAllModal}
      >
        <EditTalkingPointsAll
          campaign={campaign}
          offer={modal.offer}
          finishedCampaign={modal.finished}
        />
      </Modal>
      <div
        className={classNames(classes.container, {
          [classes.hasBorder]: index > 0,
        })}
      >
        <div className={classes.left}>
          <img
            src={offer.podcastImageUrl}
            alt="logo"
            className={classes.image}
          />
          <div className={classes.info}>
            <Body
              size={BodySize.XS}
              className={classNames(classes.bold, classes.title)}
            >
              {offer.podcastName}
            </Body>
            <div className={classes.budgetContainer}>
              <Body size={BodySize.XS} className={classes.episode}>
                {offer.episodes}&nbsp;{plural('episode', offer.episodes > 1)}
              </Body>
              <div className={classes.impressions}>
                <Body
                  size={BodySize.XS}
                  weight={BodyWeight.Bold}
                  className={classes.body}
                >
                  {formatNumberWithComas(offer.impressions)}
                </Body>
                <Body size={BodySize.XS} className={classes.body}>
                  Impressions
                </Body>
              </div>
              <Body size={BodySize.XS} className={classes.budget}>
                ${formatNumberWithComas(offer.amount)}
              </Body>
            </div>
            <div className={classes.adTypes}>
              {offer.adTypes?.map((adType) => {
                const adTypeValue = adTypeInputs.find(
                  (ad) => ad.type === adType
                );
                return (
                  <div
                    className={classes.selectedAdType}
                    key={adTypeValue.label}
                  >
                    <div className={classes.selected} />
                    <Body size={BodySize.XXS} className={classes.bold}>
                      {adTypeValue.label}
                    </Body>
                  </div>
                );
              })}
            </div>
          </div>
        </div>
        <div className={classes.right}>
          <Button
            kind={ButtonKind.Secondary}
            className={classes.actionButton}
            size={ButtonSize.S}
            onClick={() => onClickMoreInfoHandler(offer)}
          >
            MORE INFO
          </Button>
          {!finishedCampaign && noAdCopys && (
            <Button
              kind={ButtonKind.Secondary}
              className={classNames(
                classes.actionButton,
                classes.editTalkingPointsButton
              )}
              size={ButtonSize.S}
              onClick={onClickTalkingPointsHandler}
            >
              Edit talking points
            </Button>
          )}
          {!finishedCampaign && (
            <Button
              kind={ButtonKind.Secondary}
              className={classes.actionButton}
              size={ButtonSize.S}
              onClick={onClickSendSampleHandler}
            >
              Send sample
            </Button>
          )}
          {finishedCampaign ? (
            <Button
              kind={ButtonKind.Secondary}
              className={classes.actionButton}
              size={ButtonSize.S}
              onClick={onClickAdCopy}
            >
              View copy
            </Button>
          ) : (
            offer.status === offerStatus.accepted &&
            !noAdCopys && (
              <Button
                kind={ButtonKind.Secondary}
                className={classes.actionButton}
                size={ButtonSize.S}
                onClick={onClickAdCopy}
              >
                {offer.adCopy ? 'Edit copy' : 'Send copy'}
              </Button>
            )
          )}

          {offer.coverLetter && !offer.isPaid && (
            <Button
              type="submit"
              onClick={() => onClickNotesToTheBrandHandler(offer)}
              size={ButtonSize.S}
              kind={ButtonKind.Secondary}
              className={classes.notesToTheBrandButton}
            >
              See notes
            </Button>
          )}
        </div>
      </div>
      {offer.status === offerStatus.rejected && !finishedCampaign && (
        <div className={classes.rejectedContainer}>
          <Body size={BodySize.XS}>
            This podcast has rejected your offer. If you desire you can choose a
            new one.
          </Body>
          <Button
            kind={ButtonKind.Secondary}
            className={classes.newPodcastButton}
            size={ButtonSize.S}
            onClick={onClickRejectedOffer}
          >
            Add new podcast
          </Button>
        </div>
      )}
    </>
  );
};

Expanded.propTypes = {
  offer: PropTypes.shape({
    podcastImageUrl: PropTypes.string,
    image: PropTypes.string,
    podcastName: PropTypes.string,
    amount: PropTypes.number,
    adTypes: PropTypes.arrayOf(PropTypes.string),
    status: PropTypes.string,
    adCopy: PropTypes.shape({
      copy: PropTypes.string,
      notes: PropTypes.string,
    }),
    coverLetter: PropTypes.string,
    episodes: PropTypes.number,
    impressions: PropTypes.number,
    isPaid: PropTypes.bool,
    podcastId: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
  onClickRejectedOffer: PropTypes.func,
  onClickAdCopy: PropTypes.func,
  noAdCopys: PropTypes.bool,
  finishedCampaign: PropTypes.bool.isRequired,
  campaign: PropTypes.shape({
    name: PropTypes.string.isRequired,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        podcastImageUrl: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }),
};

Expanded.defaultProps = {
  onClickRejectedOffer: null,
  onClickAdCopy: null,
  noAdCopys: false,
  campaign: {},
};
export default Expanded;
