const ageOptions = [
  { label: '12-17 years old', value: '12 - 17' },
  { label: '18-34 years old', value: '18 - 34' },
  { label: '35-54 years old', value: '35 - 54' },
  { label: '55+ years old', value: '55+' },
];

const reducedAgeOptions = [
  { ...ageOptions[0], label: '12 - 17' },
  { ...ageOptions[1], label: '18 - 34' },
  { ...ageOptions[2], label: '35 - 54' },
  { ...ageOptions[3], label: '55+' },
];

export { reducedAgeOptions, ageOptions };
