import React, { useEffect, useState } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { selectFetchCampaignsPodcastsDataState } from 'state/selectors/campaigns';
import Button from 'components/Common/Button';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Badge from 'components/Common/Badge';
import Link from 'components/Typography/Link';
import WebIcon from 'assets/icons/website.png';
import AppleIcon from 'assets/icons/apple-podcast.png';
import Spinner from 'components/Common/Spinner';
import getCampaignFieldsData from 'utils/podcast/podcastData';
import { selectFetchHostingProviders } from 'state/selectors/generals';
import classes from './PodcastData.module.scss';

const PodcastData = ({
  podcast,
  onSelect,
  selected,
  onClose,
  addButton = false,
}) => {
  const { podcastsData, loading } = useSelector(
    selectFetchCampaignsPodcastsDataState,
    shallowEqual
  );

  const { hostingProviders } = useSelector(
    selectFetchHostingProviders,
    shallowEqual
  );

  const [data, setData] = useState(null);

  useEffect(() => {
    const podcastId = podcast?.podcastId;
    if (podcastId) {
      setData(podcastsData?.[podcastId]);
    } else {
      setData(null);
    }
  }, [podcast, podcastsData]);

  const onClickHandler = () => {
    onSelect(podcast);
    onClose();
  };

  const podcastDetailsInfo = getCampaignFieldsData(data, hostingProviders);

  return data && !loading ? (
    <div className={classes.container}>
      <div className={classes.content}>
        <div className={classes.leftColumn}>
          <div>
            <img src={data?.imageUrl} alt="logo" className={classes.image} />
          </div>
          <div className={classes.media}>
            {data?.webUrl && (
              <Link
                to={{ pathname: data?.webUrl }}
                underline={false}
                target="_blank"
              >
                <img src={WebIcon} alt="web" className={classes.mediaIcon} />
              </Link>
            )}
            {data?.iTunesId && (
              <Link
                to={{
                  pathname: `https://podcasts.apple.com/us/podcast/id${data?.iTunesId}`,
                }}
                underline={false}
                target="_blank"
              >
                <img
                  src={AppleIcon}
                  alt="apple"
                  className={classes.mediaIcon}
                />
              </Link>
            )}
          </div>
          <Body size={BodySize.XS} className={classes.description}>
            {data?.description || ''}
          </Body>
          {addButton && (
            <div className={classes.buttonContainer}>
              <Button onClick={onClickHandler} className={classes.button}>
                {selected ? 'ADDED' : 'ADD'}
              </Button>
            </div>
          )}
        </div>
        <div className={classes.rightColumn}>
          <Body size={BodySize.XS} className={classes.title}>
            {podcast?.podcastName || ''}
          </Body>
          <div className={classes.badge}>
            {Object.entries(data?.categories || {}).map(([id, name]) => (
              <Badge className={classes.categories} text={name} key={id} />
            ))}
          </div>
          <div className={classes.information}>
            {podcastDetailsInfo.map(
              ({ label, Icon, component: Component, showComponent = true }) =>
                showComponent && (
                  <div className={classes.item}>
                    <div>
                      <img src={Icon} alt={label} className={classes.icon} />
                    </div>
                    <div>
                      <Body size={BodySize.XS} className={classes.itemTitle}>
                        {label}
                      </Body>
                      <Component className={classes.informationText} />
                    </div>
                  </div>
                )
            )}
          </div>
        </div>
      </div>
    </div>
  ) : (
    <Spinner className={classes.spinnerContainer} />
  );
};

PodcastData.propTypes = {
  podcast: PropTypes.shape({
    podcastId: PropTypes.string,
    podcastName: PropTypes.string,
  }),
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  addButton: PropTypes.bool,
};

PodcastData.defaultProps = {
  podcast: null,
  addButton: false,
};

export default PodcastData;
