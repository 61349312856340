import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const fetchSelectedUsers = async ({ selectedIds }) => {
  try {
    const userRef = collection(firestore, Collections.PodcastUsers);
    const selectedUsersQuery = query(
      userRef,
      where('podcastId', 'in', selectedIds)
    );
    const selectedUsersSapshot = await getDocs(selectedUsersQuery);

    const selectedUsers = [];
    selectedUsersSapshot.forEach((doc) => {
      const user = doc.data();
      if (user.podcastData) {
        selectedUsers.push(user);
      }
    });

    return selectedUsers;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchSelectedUsers;
