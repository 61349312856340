import * as yup from 'yup';

import Validations from 'enums/schemas/validations.enum';
import Required from 'enums/schemas/required.enum';

const ResetPasswordValidationSchema = yup.object().shape({
  email: yup.string().email(Validations.InvalidEmail).required(Required.Email),
});

export default ResetPasswordValidationSchema;
