import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';

import { selectFetchUnfinishedCampaignState } from 'state/selectors/campaigns';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, { Type as ButtonType } from 'components/Common/Button';
import Form from 'components/Common/Form';
import { getCampaignNameInputs } from 'utils/inputs/createCampaignInputs';

import validationSchema from './CampaignNameForm.schema';
import classes from './CampaignNameForm.module.scss';

const CampaignNameForm = () => {
  const {
    onSubmit,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const defaultData = formsData[currentStep] ?? campaign;

  const campaignInputs = useMemo(
    () =>
      getCampaignNameInputs({
        defaultValues: defaultData,
      }),
    [defaultData]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Form
      className={classNames(commonClasses.content, commonClasses.form)}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      <div className={classes.fields}>
        {campaignInputs.map(
          ({
            component: Component,
            name,
            props: componentProps,
            defaultValue,
          }) => (
            <Component
              key={name}
              name={name}
              {...componentProps}
              defaultValue={defaultValue}
            />
          )
        )}
      </div>
      <Button
        type={ButtonType.Submit}
        className={classNames(commonClasses.submitButton, classes.submitButton)}
      >
        Next
      </Button>
    </Form>
  );
};

export default CampaignNameForm;
