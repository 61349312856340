import releasePeriodNames from 'enums/campaign/releasePeriodNames.enum';

const getPodcastData = ({ podcast }) => ({
  ...podcast,
  medianAge: Math.round(podcast.podcastData?.demographics?.medianAge) || null,
  ethnicity:
    podcast.podcastData?.ethnicities?.find(
      (ethnicity) =>
        ethnicity.percentage ===
        Math.max(...podcast.podcastData?.ethnicities.map((e) => e.percentage))
    )?.title || null,
  medianIncome: podcast.podcastData?.demographics?.medianIncome
    ? `$${podcast.podcastData?.demographics?.medianIncome}`
    : '-',
  daysBetweenEpisodes: podcast.podcastData?.daysBetweenEpisodes
    ? releasePeriodNames[podcast.podcastData?.daysBetweenEpisodes]
    : null,
  audienceEstimate: podcast.podcastData?.demographics?.audienceEstimate,
  gender:
    100 -
    (Math.round(podcast.podcastData?.demographics?.genderBias * 100) || 50),
  applePodcastUrl: `https://podcasts.apple.com/us/podcast/${podcast.podcastName
    ?.replace(/\s+/g, '-')
    .toLowerCase()}/id${podcast.applePodcastsId}`,
});

export default getPodcastData;
