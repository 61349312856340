import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';

const createCoupon = async ({ amount }) => {
  try {
    const generateDiscountCoupon = httpsCallable(
      functions,
      'campaigns-generateDiscountCoupon'
    );

    return await generateDiscountCoupon({amount});
  } catch (error) {
    throw new Error(error);
  }
};

export default createCoupon;