import Path from 'enums/path.enum';
import SideBarItems from 'enums/sideBar/items.enum';

const sideBarRoutes = [
  {
    title: SideBarItems.CAMPAIGNS,
    to: Path.Campaigns,
    pathname: Path.Campaigns,
  },
  {
    title: SideBarItems.SETTINGS,
    to: Path.Settings,
    pathname: Path.Settings,
  },
  {
    title: SideBarItems.LOGOUT,
    to: Path.Login,
    pathname: Path.Login,
  },
];

export default sideBarRoutes;
