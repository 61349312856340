import dayjs from 'dayjs';

import { CPM_RATE, ONE_MONTH } from 'constants/campaign';
import offerStatus from 'enums/campaign/offerStatus.enum';

const getCampaignOffers = ({ selectedPodcasts, campaign }) => {
  const campaignOffers = selectedPodcasts.map((podcast) => {
    const { podcastData } = podcast;
    const diffDays = dayjs(campaign.airingTo).diff(
      dayjs(campaign.airingFrom),
      'day'
    );

    const totalNumberOfEpisodes = Math.round(
      diffDays / podcastData.daysBetweenEpisodes
    );

    const episodesPerMonth = ONE_MONTH / podcastData.daysBetweenEpisodes;

    const numberOfImpressions = Math.round(
      (podcastData.demographics?.audienceEstimate / episodesPerMonth) *
        totalNumberOfEpisodes
    );

    const offerBudget = Math.round((numberOfImpressions / 1000) * CPM_RATE);

    return {
      podcastId: podcast.podcastId,
      adTypes: campaign.adTypes,
      title: podcast.podcastName,
      imageUrl: podcastData.imageUrl,
      episodes: totalNumberOfEpisodes,
      impressions: numberOfImpressions,
      budget: offerBudget,
      offerStatus: offerStatus.pending,
      isPaid: false,
      podcastPaymentStatus: offerStatus.pending,
      podcastEmail: podcast.email,
      podcastName: podcast.podcastName,
    };
  });

  return campaignOffers;
};

export default getCampaignOffers;
