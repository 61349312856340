import { useEffect, useState } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';

import {
  fetchCampaignsPendingOffers,
  fetchCampaignsPodcastsData,
} from 'state/actions/campaigns';
import {
  fetchDefaultValues,
  fetchHostingProviders,
} from 'state/actions/generals';
import {
  selectFetchAllCurrentCampaignsState,
  selectFetchAllFinishedCampaignsState,
  selectFetchAllPendingCampaignsState,
  selectFetchCampaignsPendingOffersState,
} from 'state/selectors/campaigns';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import { selectFetchDefaultValues } from 'state/selectors/generals';
import useModal from './useModal';

const useCampaigns = () => {
  const dispatch = useDispatch();
  const [selectedSort, setSelectedSort] = useState(null);
  const [fetchMore, setFetchMore] = useState(false);

  const {
    lastDoc: lastDocPendingCampaigns,
    campaigns: pendingCampaigns,
    loading: loadingPendingCampaigns,
  } = useSelector(selectFetchAllPendingCampaignsState, shallowEqual);

  const {
    lastDoc: lastDocCurrentCampaigns,
    campaigns: currentCampaigns,
    loading: loadingCurrentCampaigns,
  } = useSelector(selectFetchAllCurrentCampaignsState, shallowEqual);

  const {
    lastDoc: lastDocFinishedCampaigns,
    campaigns: finishedCampaigns,
    loading: loadingFinishedCampaigns,
  } = useSelector(selectFetchAllFinishedCampaignsState, shallowEqual);

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const { defaultValues } = useSelector(selectFetchDefaultValues, shallowEqual);

  const { loading: loadingPendingOffers, pendingOffers } = useSelector(
    selectFetchCampaignsPendingOffersState,
    shallowEqual
  );
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    if (user) {
      dispatch(fetchDefaultValues(user.networks[0]));
    }
  }, [user]);

  const onChangeSelectedSorting = (sort) => {
    setSelectedSort(sort);
  };

  useEffect(() => {
    if (pendingCampaigns && pendingCampaigns.length > 0 && defaultValues) {
      dispatch(
        fetchCampaignsPendingOffers({
          campaigns: pendingCampaigns,
          defaultValues,
        })
      );
      dispatch(fetchHostingProviders());
    }
  }, [pendingCampaigns, defaultValues]);

  useEffect(() => {
    if (currentCampaigns.length > 0 && defaultValues) {
      dispatch(
        fetchCampaignsPendingOffers({
          campaigns: currentCampaigns,
          defaultValues,
        })
      );
    }
  }, [currentCampaigns, defaultValues]);

  useEffect(() => {
    if (pendingOffers && Object.keys(pendingOffers).length > 0) {
      const podcastsIds = Object.values(pendingOffers).flatMap((offers) =>
        offers.map((offer) => offer.podcastId)
      );
      dispatch(fetchCampaignsPodcastsData({ podcastsIds }));
    }
  }, [pendingOffers]);

  return {
    lastDocPendingCampaigns,
    loadingPendingCampaigns,
    pendingCampaigns,
    lastDocCurrentCampaigns,
    loadingCurrentCampaigns,
    currentCampaigns,
    loadingFinishedCampaigns,
    lastDocFinishedCampaigns,
    finishedCampaigns,
    modal,
    onOpenModalHandler,
    onCloseModalHandler,
    selectedSort,
    onChangeSelectedSorting,
    fetchMore,
    setFetchMore,
    loadingPendingOffers,
  };
};

export default useCampaigns;
