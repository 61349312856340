import { doc, getFirestore, getDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchDefaultValues = async (network) => {
  try {
    const firestore = getFirestore();

    const ref = doc(firestore, Collections.DefaultValues, network);
    const defaultValues = await getDoc(ref);

    return defaultValues.data();
  } catch (error) {
    return error;
  }
};

export default fetchDefaultValues;
