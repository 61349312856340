import React from 'react';
import PropTypes from 'prop-types';
import BarLoader from 'react-spinners/BarLoader';

import classes from './Loader.module.scss';

export const Color = Object.freeze({
  Default: '#645aff',
});

const Loader = ({ color }) => (
  <div className={classes.loader}>
    <BarLoader color={color} width="100%" />
  </div>
);

Loader.propTypes = {
  color: PropTypes.oneOf(Object.values(Color)),
};

Loader.defaultProps = {
  color: Color.Default,
};

export default Loader;
