import classNames from 'classnames';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';

const getBudgets = ({ currentBudget, budgetInFavor, classes }) => [
  {
    title: 'Total Budget Used',
    tooltip: 'This is the actual value you will be charged.',
    value: `$${formatNumberWithComas(currentBudget)}`,
    className: classNames(classes.number, classes.negative),
  },
  {
    title: 'Your Balance',
    tooltip: 'This is the value shows your badget in favor',
    value: `$${formatNumberWithComas(budgetInFavor)}`,
    className: classNames(classes.number, classes.green, {
      [classes.negative]: budgetInFavor < 0,
    }),
  },
];

export default getBudgets;
