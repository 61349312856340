/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import classNames from 'classnames';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button, { Type as ButtonType } from 'components/Common/Button';
import UploadFile from 'components/Common/UploadFile';
import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Toast, { Type as ToastType } from 'components/Common/Toast';
import { clearEditUserState, editUser } from 'state/actions/auth';
import {
  selectAuthenticatedUserState,
  selectEditUserState,
} from 'state/selectors/auth';
import updateUserInputs from 'utils/inputs/updateUserInputs';
import validationSchemaEdit from './EditUserForm.schema';

import classes from './EditUserForm.module.scss';

const EditUserForm = () => {
  const dispatch = useDispatch();

  const {
    loading: loadingEdit,
    error: errorEdit,
    success: successEdit,
  } = useSelector(selectEditUserState, shallowEqual);

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const [userData, setUserData] = useState();

  useEffect(() => {
    setUserData({
      email: user.email,
      owner: user.owner,
      name: user.name,
      phone: user.phone,
      url: user.url,
      linkedInUrl: user.linkedInUrl,
      logo: user.logo,
    });
  }, [user]);

  const [logo, setLogo] = useState(user?.logo || null);
  const [logoError, setLogoError] = useState(null);

  useEffect(() => {
    if (successEdit) {
      dispatch(clearEditUserState());
    }
  }, [successEdit]);

  useEffect(() => {
    if (logoError) {
      setLogoError(null);
    }
  }, [logoError]);

  const onSubmitHandler = useCallback(
    (values) => {
      const editUserValues = {
        ...userData,
        ...values,
      };

      delete editUserValues.confirmPassword;

      let newLogo = null;
      if (user.logo !== logo) {
        newLogo = logo;
      }

      Object.keys(values).forEach((val) => {
        if (editUserValues[val] === user[val]) {
          delete editUserValues[val];
        }
      });

      dispatch(
        editUser({ newUser: editUserValues, newLogo, email: user.email })
      );
    },
    [logo]
  );

  const onChangeLogoHandler = useCallback((file) => {
    setLogo(file);
  }, []);

  return (
    <>
      {logoError && <Toast text={logoError} id="Logo error" />}
      {errorEdit && <Toast text={errorEdit} id="Edit user error" />}
      {successEdit && (
        <Toast
          text="The user has been successfully edited"
          id="Edit user success"
          type={ToastType.Success}
        />
      )}
      <Form
        validationMode={ValidationMode.OnSubmit}
        validationSchema={validationSchemaEdit}
        onSubmit={onSubmitHandler}
        className={classNames(classes.form, [classes.marginTop])}
      >
        {updateUserInputs.map(({ name, renderProps }) => (
          <FormControl
            key={name}
            name={name}
            className={classes.formControlSmall}
            defaultValue={user ? user[name] : ''}
            onChangeHandler={(value) => value}
            render={(props) => (
              <>
                <Input
                  {...props}
                  {...renderProps}
                  disabled={user && name === 'email'}
                />
              </>
            )}
          />
        ))}
        <div className={classes.logo}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.logoTitle}
          >
            Upload your company&apos;s logo
          </Body>
          <Body className={classes.logoDescription}>
            Your logo is gonna be shown <br /> to the podcaster when they <br />
            are looking at your offer.
          </Body>
          <Body size={BodySize.XS} className={classes.logoDescription}>
            Minimum height 100px.
          </Body>
          <div className={classes.uploadFile}>
            <UploadFile
              selectedFile={logo}
              setSelectedFile={onChangeLogoHandler}
            />
          </div>
        </div>
        <Button
          type={ButtonType.Submit}
          loading={loadingEdit}
          className={classNames([classes.buttonSmall])}
        >
          Update
        </Button>
      </Form>
    </>
  );
};

export default EditUserForm;
