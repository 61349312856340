/* eslint-disable react/jsx-props-no-spreading */
import React, { useMemo, useEffect } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { Redirect } from 'react-router-dom';

import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Link from 'components/Typography/Link';
import Toast from 'components/Common/Toast';
import SignUpForm from 'components/Pages/Forms/SignUpForm';
import { brandLogo } from 'assets/white-label';
import Path from 'enums/path.enum';
import { selectSignUpState } from 'state/selectors/auth';

import { clearAuthState } from 'state/actions/auth';
import classes from './SignUp.module.scss';

const SignUp = () => {
  const dispatch = useDispatch();

  const { success, error } = useSelector(selectSignUpState, shallowEqual);

  useEffect(
    () => () => {
      dispatch(clearAuthState());
    },
    []
  );

  const redirect = useMemo(
    () => success && <Redirect to={Path.ThanksForApplying} />,
    [success]
  );

  return (
    <>
      {redirect}
      {error && <Toast text={error} id="Sign up error" />}
      <div className={classes.container}>
        <div className={classes.logoContainer}>
          <img src={brandLogo} className={classes.logo} alt="Brand Logo" />
        </div>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Create an account
        </Body>
        <SignUpForm />
        <div className={classes.link}>
          <Link to={Path.Login} underline={false}>
            <Body
              color={BodyColor.Gray}
              size={BodySize.XXS}
              letterCase={BodyLetterCase.Uppercase}
              spacing={BodySpacing.S}
              className={classes.bold}
            >
              Already have an account?
            </Body>
          </Link>
        </div>
      </div>
    </>
  );
};

export default SignUp;
