import PropTypes from 'prop-types';
import classNames from 'classnames';

import Badge, { Kind as BadgeKind } from 'components/Common/Badge';

import classes from './MultipleBadgeList.module.scss';

const MultipleBadgeList = ({
  items,
  onChange,
  onChangeDo,
  name,
  value,
  className,
}) => {
  const onChangeHandler = (id) => {
    const indexToDelete = value.indexOf(id);
    let newValues = [...value];

    if (indexToDelete !== -1) {
      newValues = value.toSpliced(indexToDelete, 1);
    } else {
      newValues.push(id);
    }

    if (onChangeDo) {
      onChangeDo(newValues, { name });
    }

    onChange(newValues);
  };

  return (
    <div className={classNames(classes.categories, className)}>
      {items.map(({ label, value: itemValue }) => (
        <Badge
          key={itemValue}
          text={label}
          kind={
            value?.some((val) => val === itemValue)
              ? BadgeKind.Primary
              : BadgeKind.Secondary
          }
          className={classes.badge}
          onClick={() => onChangeHandler(itemValue)}
        />
      ))}
    </div>
  );
};

MultipleBadgeList.propTypes = {
  items: PropTypes.arrayOf(
    PropTypes.arrayOf(
      PropTypes.shape({
        title: PropTypes.string,
        slug: PropTypes.string,
      })
    )
  ).isRequired,
  onChange: PropTypes.func.isRequired,
  onChangeDo: PropTypes.func,
  name: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  className: PropTypes.string,
};

MultipleBadgeList.defaultProps = {
  className: '',
  onChangeDo: null,
  name: '',
};

export default MultipleBadgeList;
