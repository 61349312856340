import * as yup from 'yup';

import Validations from 'enums/schemas/validations.enum';
import Required from 'enums/schemas/required.enum';
import { MIN_PASSWORD_LENGTH } from 'constants/schemas';

const signUpValidationSchema = yup.object().shape({
  email: yup.string().email(Validations.InvalidEmail).required(Required.Email),
  password: yup
    .string()
    .required(Required.Password)
    .min(MIN_PASSWORD_LENGTH, Validations.MinLengthPassword),
  confirmPassword: yup
    .string()
    .oneOf([yup.ref('password'), null], 'Passwords must match')
    .required(Required.ConfirmPassword),
  name: yup.string().required(Required.Name),
  phone: yup.string().required(Required.Phone),
  brandName: yup.string().required(Required.BrandName),
  brandURL: yup.string().required(Required.BrandUrl),
  linkedin: yup.string().required(Required.Linkedin),
});

export default signUpValidationSchema;
