import React from 'react';
import PropTypes from 'prop-types';
import { Route } from 'react-router-dom';

const ExternalRedirect = ({ to, ...routeProps }) => (
  <Route
    {...routeProps}
    render={() => {
      window.location = to;
      return null;
    }}
  />
);

ExternalRedirect.propTypes = {
  to: PropTypes.string.isRequired,
};

export default ExternalRedirect;
