export const authErrors = {
  'auth/email-already-in-use': 'Invalid credentials. Please try again',
  'auth/email-already-exists': 'Invalid credentials. Please try again',
  'auth/invalid-email': 'Invalid email. Try again',
  'auth/wrong-password': 'Invalid credentials. Please try again',
  'auth/too-many-requests': 'Too many requests. Try again later.',
  'auth/invalid-password':
    'Invalid password. It must be a string with at least six characters.',
  'auth/invalid-credential': 'Invalid credentials. Please try again',
  'auth/user-not-found': 'Invalid credentials. Please try again',
  'auth/requires-recent-login':
    'Your last sign-in is not recent enough, please try to sign-in again',
};

export const storageError = {
  'storage/server-file-wrong-size': 'Try uploading again',
  'storage/unauthenticated':
    'User is unauthenticated, please authenticate and try again',
};

const beautifyError = (error) =>
  authErrors[error.code] ??
  authErrors[error.message] ??
  storageError[error.code] ??
  storageError[error.message] ??
  'Unknown error. Please, try again.';

export default beautifyError;
