import * as yup from 'yup';

import Required from 'enums/schemas/required.enum';

const CampaignAdPlacementFormSchema = yup.object().shape({
  adPlacements: yup
    .array()
    .min(1, Required.AdPlacement)
    .nullable()
    .required(Required.AdPlacement),
});

export default CampaignAdPlacementFormSchema;
