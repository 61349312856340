import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';

import verifyBrandRole from 'services/auth/verifyBrandRole';
import beautifyError from 'utils/beautifyError';

const login = async ({ email, password }) => {
  try {
    const auth = getAuth();
    const { user } = await signInWithEmailAndPassword(auth, email, password);

    const isBrand = await verifyBrandRole();

    if (isBrand) {
      return user.uid;
    }

    return null;
  } catch (error) {
    throw new Error(beautifyError(error));
  }
};

export default login;
