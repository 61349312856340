const ModalType = Object.freeze({
  PODCAST_DATA: 'podcast-data',
  DELETE_PODCAST: 'delete-podcast',
  CAMPAIGN_SUMMARY: 'campaign-summary',
  AD_COPY: 'ad-copy',
  NOTES_TO_THE_BRAND: 'notes-to-the-brand',
  SEND_SAMPLE: 'send-sample',
  SEE_CALENDAR: 'see-calendar',
  EXPORT_CAMPAIGN: 'export-campaign',
  EDIT_TALKING_POINTS_ALL: 'edit-talking-points-all',
  EDIT_TALKING_POINTS: 'edit-talking-points',
});

export default ModalType;
