import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';

const editUser = async (newUser, id) => {
  try {
    const userRef = doc(firestore, Collections.Brands, id);

    await updateDoc(userRef, newUser);
  } catch (error) {
    throw new Error(error);
  }
};

export default editUser;
