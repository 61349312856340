import React, { useCallback } from 'react';
import { useHistory } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Button from 'components/Common/Button';
import ossaLogo from 'assets/logos/ossa-logo.png';
import checkIcon from 'assets/icons/check-violet.svg';
import Path from 'enums/path.enum';

import classes from './NewPasswordConfirmation.module.scss';

const NewPasswordConfirmation = () => {
  const { push } = useHistory();

  const onClickNextHandler = useCallback(() => {
    push(Path.Login);
  }, [push]);

  return (
    <div className={classes.container}>
      <div className={classes.logo}>
        <img src={ossaLogo} alt="Ossa Logo" />
      </div>
      <div className={classes.text}>
        <img src={checkIcon} alt="check" className={classes.icon} />
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Password recovered
        </Body>
        <Body
          letterCase={BodyLetterCase.Lowercase}
          size={BodySize.XS}
          className={classes.subtext}
        >
          Now you can sign in with your new password.
        </Body>
        <Button
          isFullWidth
          onClick={onClickNextHandler}
          className={classes.button}
        >
          Next
        </Button>
      </div>
      <div />
    </div>
  );
};

export default NewPasswordConfirmation;
