import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import RcSlider from 'rc-slider';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import { brandName } from 'assets/white-label';
import Handle from 'components/Common/Slider/Handle';
import sliderStyles from 'utils/styles/sliderStyles';

import 'rc-slider/assets/index.css';
import './Slider.scss';

import classes from './Slider.module.scss';

const customHandleBudgetSplit = (props) => (
  <Handle {...props} handleLeftText={brandName} handleRightText="Podcaster" />
);

const defaultHandle = (props) => (
  <Handle {...props} handleLeftText="M" handleRightText="F" />
);

const Slider = ({
  value,
  onChange,
  onChangeDo,
  marks,
  min,
  max,
  name,
  startText,
  endText,
  disabled,
  label,
  className,
  defaultValue,
  budgetHandle,
}) => {
  const onInternalChangeHandler = (newValue) => {
    if (onChangeDo) {
      onChangeDo(newValue, { name });
    }
    onChange(newValue);
  };

  return (
    <div className={classNames(classes.container, className)}>
      {label && (
        <Body
          size={BodySize.S}
          className={classes.labelText}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <div className={classes.content}>
        {startText && (
          <Body
            size={BodySize.XS}
            className={classes.startText}
            color={BodyColor.Black}
          >
            {startText}
          </Body>
        )}
        <RcSlider
          min={min}
          max={max}
          value={value || defaultValue}
          onChange={onInternalChangeHandler}
          handle={budgetHandle ? customHandleBudgetSplit : defaultHandle}
          defaultValue={defaultValue}
          disabled={disabled}
          marks={marks}
          trackStyle={sliderStyles.trackStyle}
          handleStyle={sliderStyles.handleStyle}
          railStyle={sliderStyles.railStyle}
          dotStyle={sliderStyles.dotStyle}
          activeDotStyle={sliderStyles.activeDotStyle}
        />
        {endText && (
          <Body
            size={BodySize.XS}
            className={classes.endText}
            color={BodyColor.Black}
          >
            {endText}
          </Body>
        )}
      </div>
    </div>
  );
};

Slider.propTypes = {
  value: PropTypes.number,
  onChange: PropTypes.func.isRequired,
  // This is used to trigger a change in the parent component, not to override the current behavior
  onChangeDo: PropTypes.func,
  label: PropTypes.string,
  className: PropTypes.string,
  min: PropTypes.number,
  max: PropTypes.number,
  disabled: PropTypes.bool,
  name: PropTypes.string,
  marks: PropTypes.objectOf(PropTypes.string),
  startText: PropTypes.string,
  endText: PropTypes.string,
  defaultValue: PropTypes.number,
  budgetHandle: PropTypes.bool,
};

Slider.defaultProps = {
  value: null,
  label: '',
  className: '',
  name: '',
  min: 0,
  max: 100,
  disabled: false,
  onChangeDo: null,
  marks: {},
  startText: null,
  endText: null,
  defaultValue: null,
  budgetHandle: false,
};

export default Slider;
