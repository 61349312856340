import React from 'react';
import PropTypes from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Textarea from 'components/Common/Textarea';

import classes from './FormControlTextarea.module.scss';

const FormControlTextarea = ({
  name,
  label,
  labelClassName,
  defaultValue,
  className,
  ...rest
}) => {
  const { errors, control } = useFormContext();
  const error = errors[name];

  return (
    <div
      className={classNames(className, classes.container, {
        [classes.error]: !!error,
      })}
    >
      {label && (
        <Body
          size={BodySize.XS}
          className={labelClassName}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <div className={classNames(classes.textarea)}>
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={(field) => (
            <Textarea name={name} error={!!error} {...field} {...rest} />
          )}
        />
        {error && (
          <Body
            color={BodyColor.Red}
            size={BodySize.XXS}
            className={classes.errorText}
          >
            {error.message}
          </Body>
        )}
      </div>
    </div>
  );
};

FormControlTextarea.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  className: PropTypes.string,
  defaultValue: PropTypes.string,
};

FormControlTextarea.defaultProps = {
  label: null,
  className: '',
  labelClassName: '',
  defaultValue: '',
};

export default FormControlTextarea;
