import PropTypes from 'prop-types';

import { toast } from 'react-toastify';

export const Type = Object.freeze({
  Error: 'error',
  Success: 'success',
  Info: 'info',
});

const Toast = ({ text, id, type }) => {
  toast[type](text, {
    position: 'bottom-center',
    autoClose: 5000,
    hideProgressBar: false,
    closeOnClick: true,
    pauseOnHover: true,
    draggable: true,
    progress: undefined,
    toastId: id,
  });
  return null;
};

Toast.propTypes = {
  text: PropTypes.string.isRequired,
  id: PropTypes.string.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
};

Toast.defaultProps = {
  type: Type.Error,
};

export default Toast;
