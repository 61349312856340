import React from 'react';
import PropTypes from 'prop-types';

import classes from './Tab.module.scss';

const Tab = ({ children, tab }) => (
  <div label={tab} className={classes.container}>
    {children}
  </div>
);

Tab.propTypes = {
  children: PropTypes.node.isRequired,
  tab: PropTypes.string.isRequired,
};

export default Tab;
