/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { Redirect, useParams, useHistory } from 'react-router-dom';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import Podcast from 'components/Pages/Podcast/PodcastItem';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Spinner from 'components/Common/Spinner';
import {
  addOffers,
  clearCampaignState,
  fetchCampaignById,
} from 'state/actions/campaigns';
import {
  selectAddOffersState,
  selectFetchCampaignByIdState,
} from 'state/selectors/campaigns';
import {
  selectFetchEnabledUsersState,
  selectFetchSelectedUsersState,
} from 'state/selectors/users';
import {
  clearUsersState,
  fetchEnabledUsers,
  fetchSelectedUsers,
} from 'state/actions/users';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalTypes';
import Path from 'enums/path.enum';
import { PAGINATION_ITEMS, PODCASTS_TO_SHOW } from 'constants/campaign';
import getPodcastData from 'utils/functions/getPodcastData';
import getCampaignOffers from 'utils/functions/getCampaignOffers';
import manageLoadMorePodcasts from 'utils/functions/manageLoadMorePodcasts';

import classes from './SelectPodcast.module.scss';

const SelectPodcast = () => {
  const dispatch = useDispatch();
  const { campaignId } = useParams();
  const history = useHistory();

  const {
    error: addOffersError,
    success: successAddingOffers,
    loading: loadingAddOffers,
  } = useSelector(selectAddOffersState, shallowEqual);

  const {
    users,
    lastUser,
    loading: fetchUsersLoading,
    success: fetchUsersSuccess,
  } = useSelector(selectFetchEnabledUsersState, shallowEqual);

  const {
    selectedUsers,
    loading: fetchSelectedUsersLoading,
    success: fetchSelectedUsersSuccess,
  } = useSelector(selectFetchSelectedUsersState, shallowEqual);

  const {
    campaign,
    loading: loadingFetchingCampaign,
    success: successFetchingCampaign,
  } = useSelector(selectFetchCampaignByIdState, shallowEqual);

  const [podcastsToShow, setPodcastsToShow] = useState([]);
  const [remainingPodcasts, setRemainingPodcasts] = useState([]);
  const [selectedPodcasts, setSelectedPodcasts] = useState([]);

  const [submitError, setSubmitError] = useState(false);

  const [fetchMorePodcasts, setFetchMorePodcasts] = useState(false);
  const [noMorePodcasts, setNoMorePodcasts] = useState(false);

  const first = PAGINATION_ITEMS;
  const lastItemPagination = useMemo(() => lastUser, [lastUser]);

  const { onOpenModalHandler, onCloseModalHandler } = useModal();

  useEffect(() => {
    dispatch(fetchCampaignById({ campaignId }));

    return () => {
      dispatch(clearCampaignState());
      dispatch(clearUsersState());
    };
  }, []);

  useEffect(() => {
    if (successFetchingCampaign && !campaign) {
      history.push(Path.Campaigns);
    }
  }, [successFetchingCampaign, campaign]);

  const fetchMorePodcastUsers = useCallback(() => {
    setFetchMorePodcasts(true);
    dispatch(
      fetchEnabledUsers({
        lastItemPagination,
        first,
      })
    );
  }, [lastItemPagination]);

  useEffect(() => {
    if (campaign && !fetchMorePodcasts) {
      const selectedIds = campaign.offers.map((offer) => offer.podcastId);
      fetchMorePodcastUsers();
      if (selectedIds.length > 0) {
        dispatch(
          fetchSelectedUsers({
            selectedIds,
          })
        );
      }
    }
  }, [campaign]);

  useEffect(() => {
    if (
      fetchUsersSuccess &&
      (fetchSelectedUsersSuccess || campaign.offers.length === 0) &&
      fetchMorePodcasts
    ) {
      const newSelectedPodcasts = selectedUsers.filter((user) =>
        campaign.offers.some(
          (offer) => offer.podcastId === user.podcastId && !offer.isPaid
        )
      );

      setSelectedPodcasts(newSelectedPodcasts);
      setFetchMorePodcasts(false);
      if (users.length > 0) {
        const totalUsers = users.filter(
          (user) =>
            !campaign.offers.some((offer) => offer.podcastId === user.podcastId)
        );

        manageLoadMorePodcasts({
          selectedPodcasts: newSelectedPodcasts,
          remainingPodcasts: totalUsers,
          campaign,
          setPodcastsToShow,
          setRemainingPodcasts,
          fetchMorePodcastUsers,
        });
      } else {
        setNoMorePodcasts(true);

        const showPodcasts = [];

        if (remainingPodcasts.length > 0) {
          showPodcasts.push(...remainingPodcasts);
        }

        if (
          newSelectedPodcasts.length > 0 &&
          podcastsToShow.length === 0 &&
          remainingPodcasts.length === 0
        ) {
          showPodcasts.push(...newSelectedPodcasts);
        }

        setPodcastsToShow((prevState) => [...prevState, ...showPodcasts]);
      }
    }
  }, [
    users,
    selectedUsers,
    campaign,
    fetchSelectedUsersSuccess,
    fetchUsersSuccess,
    fetchMorePodcasts,
    podcastsToShow,
  ]);

  useEffect(() => {
    if (successAddingOffers) {
      history.push(`${Path.Summary}/${campaignId}`);
    }
  }, [successAddingOffers]);

  const onSelectPodcast = useCallback((selectedPodcast) => {
    setSubmitError(false);
    onCloseModalHandler();
    setSelectedPodcasts((prevState) => {
      const newState = [...prevState];
      const element = newState.find(
        (podcast) => podcast.podcastId === selectedPodcast.podcastId
      );
      if (element) {
        const deleteIndex = newState.indexOf(element);
        newState.splice(deleteIndex, 1);
      } else {
        newState.push(selectedPodcast);
      }
      return newState;
    });
  }, []);

  const onClickLearnMoreHandler = useCallback((podcast) => {
    const podcastData = getPodcastData({ podcast });

    onOpenModalHandler(ModalType.PODCAST_DATA, {
      podcast: podcastData,
    });
  }, []);

  const onLoadMorePodcastsHandler = useCallback(() => {
    if (remainingPodcasts.length < PODCASTS_TO_SHOW) {
      fetchMorePodcastUsers();
    } else {
      const newRemainingPodcasts = [...remainingPodcasts];
      const newFirst = newRemainingPodcasts.splice(0, PODCASTS_TO_SHOW);

      setRemainingPodcasts(newRemainingPodcasts);
      setPodcastsToShow((prevState) => [...prevState, ...newFirst]);
    }
  }, [remainingPodcasts]);

  const onSubmitHandler = useCallback(() => {
    if (selectedPodcasts.length > 0) {
      const campaignOffers = getCampaignOffers({
        selectedPodcasts,
        campaign,
      });

      dispatch(
        addOffers({
          campaignId: campaign.uid,
          offers: {
            offers: [
              ...campaign.offers.filter((offer) => offer.isPaid),
              ...campaignOffers,
            ],
          },
        })
      );
    } else {
      setSubmitError(true);
    }
  }, [selectedPodcasts, podcastsToShow, campaign]);

  const isLoading = useMemo(
    () =>
      fetchSelectedUsersLoading ||
      loadingFetchingCampaign ||
      (fetchUsersLoading && podcastsToShow.length === 0),
    [
      loadingFetchingCampaign,
      fetchSelectedUsersLoading,
      fetchUsersLoading,
      podcastsToShow,
    ]
  );

  const redirectNoCampaignId = useMemo(
    () => successFetchingCampaign && !campaign && <Redirect to={Path.Home} />,
    [campaignId]
  );

  return (
    <>
      {redirectNoCampaignId}
      {addOffersError && <Toast text={addOffersError} id="Add Offers Error" />}
      {submitError && (
        <Toast text="Please select at least one podcast" id="Submit Error" />
      )}
      <div className={classes.container}>
        <div className={classes.content}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Select podcasts for your campaign
          </Body>
          <Body
            size={BodySize.XS}
            spacing={BodySpacing.XS}
            className={classes.subtitle}
          >
            Choose which podcasts you would like to send an offer to for this
            campaign.
          </Body>
          {isLoading ? (
            <Spinner className={classes.spinner} />
          ) : (
            <>
              {podcastsToShow.length === 0 ? (
                <Body
                  size={BodySize.XS}
                  spacing={BodySpacing.S}
                  className={classes.noPodcasts}
                >
                  There are no podcasts that match the selected filters
                </Body>
              ) : (
                <>
                  <div className={classes.podcasts}>
                    {podcastsToShow.map((podcast, index) => {
                      const key = `podcast-${index}`;
                      return (
                        <Podcast
                          key={key}
                          podcast={podcast}
                          selected={selectedPodcasts.some(
                            (selected) =>
                              selected.podcastId === podcast.podcastId
                          )}
                          onSelect={() => onSelectPodcast(podcast)}
                          onClickLearnMore={() => {
                            onClickLearnMoreHandler(podcast);
                          }}
                        />
                      );
                    })}
                  </div>
                  <div className={classes.buttons}>
                    {noMorePodcasts ? (
                      <Body
                        size={BodySize.XS}
                        spacing={BodySpacing.S}
                        className={classes.seeMoreButton}
                      >
                        There are no more podcasts that match the selected
                        filters
                      </Body>
                    ) : (
                      <Button
                        loading={fetchUsersLoading}
                        className={classes.seeMoreButton}
                        onClick={onLoadMorePodcastsHandler}
                        kind={ButtonKind.Secondary}
                        size={ButtonSize.S}
                        upperCase={false}
                      >
                        See More Results
                      </Button>
                    )}
                    <Button
                      loading={loadingAddOffers}
                      className={classes.nextButton}
                      onClick={onSubmitHandler}
                      disabled={selectedPodcasts.length === 0}
                    >
                      Next
                    </Button>
                  </div>
                </>
              )}
            </>
          )}
        </div>
      </div>
    </>
  );
};

export default SelectPodcast;
