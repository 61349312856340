import config from './config.json';

const {
  brandLogo,
  brandName,
  poweredByLink,
  termsOfServiceLink,
  privacyPolicyLink,
  backgroundImageText,
  network,
} = config;

export {
  brandLogo,
  brandName,
  poweredByLink,
  termsOfServiceLink,
  privacyPolicyLink,
  backgroundImageText,
  network,
};
