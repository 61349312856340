import { createAction } from '@reduxjs/toolkit';

export const signUpInit = createAction('[Auth] Sign Up Init');
export const signUpSuccess = createAction('[Auth] Sign Up Success');
export const signUpFail = createAction('[Auth] Sign Up Fail');

export const loginInit = createAction('[Auth] Login Init');
export const loginSuccess = createAction('[Auth] Login Success');
export const loginFail = createAction('[Auth] Login Fail');

export const verifyBrandPrivilegesInit = createAction(
  '[Auth] Verify Brand Privileges Init'
);
export const verifyBrandPrivilegesSuccess = createAction(
  '[Auth] Verify Brand Privileges Success'
);
export const verifyBrandPrivilegesFail = createAction(
  '[Auth] Verify Brand Privileges Fail'
);

export const verifyAuthInit = createAction('[Auth] Verify Auth Init');
export const verifyAuthSuccess = createAction('[Auth] Verify Auth Success');
export const verifyAuthFail = createAction('[Auth] Verify Auth Fail');

export const logoutInit = createAction('[Auth] Logout Init');
export const logoutSuccess = createAction('[Auth] Logout Success');
export const logoutFail = createAction('[Auth] Logout Fail');

export const resetPasswordInit = createAction('[Auth] Reset Password Init');
export const resetPasswordSuccess = createAction(
  '[Auth] Reset Password Success'
);
export const resetPasswordFail = createAction('[Auth] Reset Password Fail');

export const newPasswordInit = createAction('[Auth] New Password Init');
export const newPasswordSuccess = createAction('[Auth] New Password Success');
export const newPasswordFail = createAction('[Auth] New Password Fail');

export const editUserInit = createAction('[Auth] Edit User Init');
export const editUserSuccess = createAction('[Auth] Edit User Success');
export const editUserFail = createAction('[Auth] Edit User Fail');

export const clearEditUser = createAction('[Auth] Clear Edit User');

export const clearAuth = createAction('[Auth] Clear Auth');
