const Collections = Object.freeze({
  Brands: 'brands',
  Campaigns: 'campaigns',
  Categories: 'categories',
  DefaultValues: 'defaultValues',
  General: 'general',
  NewOffers: 'newOffers',
  Offers: 'offers',
  Podcasts: 'podcasts',
  PodcastUsers: 'users',
  HostingProviders: 'hostingProviders',
});

export default Collections;
