import { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import classNames from 'classnames';
import dayjs from 'dayjs';

import { getCampaignBudgetInputs } from 'utils/inputs/createCampaignInputs';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Spinner from 'components/Common/Spinner';
import { selectFetchDefaultValues } from 'state/selectors/generals';
import { fetchDefaultValues } from 'state/actions/generals';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import { selectAuthenticatedUserState } from 'state/selectors/auth';

import Form from 'components/Common/Form';

import validationSchema from './CampaignBudgetForm.schema';
import classes from './CampaignBudgetForm.module.scss';

const CampaignBudgetForm = () => {
  const { onSubmit, goBack, goForward, formsData } = useMultiStepFormContext();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);
  const dispatch = useDispatch();

  useEffect(() => {
    if (user) dispatch(fetchDefaultValues(user.networks[0]));
  }, [user]);

  const [profitSplit, setProfitSplit] = useState(50);

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const {
    success: selectFetchDefaultValuesSuccess,
    defaultValues,
  } = useSelector(selectFetchDefaultValues, shallowEqual);

  const { airingFrom, airingTo } = formsData[1];

  const onChangeModifier = (currentValue, prevValue) => {
    if (currentValue.target.value < 0) {
      return prevValue;
    }

    return currentValue;
  };

  const [isDynamicTimeAd, setIsDynamicTimeAd] = useState(false);

  useEffect(() => {
    if (formsData[2]?.adFormats === 'dynamicAdTime') {
      setIsDynamicTimeAd(true);
    }
  }, [formsData]);

  const onChangeProfitSplit = useCallback(
    (newValue) => {
      setProfitSplit(newValue);
    },
    [profitSplit]
  );

  const campaignBudgetFields = useMemo(
    () =>
      getCampaignBudgetInputs({
        defaultValues,
        onChangeModifier,
        isDynamicTimeAd,
        onChange: onChangeProfitSplit,
      }),
    [defaultValues]
  );

  const max = useMemo(() => {
    const airingToDay = dayjs(airingTo);

    const daysDiff = airingToDay.diff(airingFrom, 'day');

    const missingDayjsDay = 1;

    return daysDiff + missingDayjsDay;
  }, [airingFrom, airingTo]);

  const processedValidationSchema = useMemo(
    () => validationSchema({ max, isDynamicTimeAd }),
    [max, isDynamicTimeAd]
  );

  return selectFetchDefaultValuesSuccess ? (
    <Form
      className={classNames(commonClasses.content, commonClasses.form)}
      validationSchema={processedValidationSchema}
      onSubmit={onSubmitHandler}
    >
      <div className={classes.fields}>
        {campaignBudgetFields.map(
          ({ component: Component, name, props: componentProps }) => (
            <Component key={name} name={name} {...componentProps} />
          )
        )}
      </div>
      <div className={commonClasses.buttonsContainer}>
        <Button
          kind={ButtonKind.Tertiary}
          className={commonClasses.goBackButton}
          onClick={goBack}
        >
          Back
        </Button>
        <Button className={commonClasses.submitButton} type={ButtonType.Submit}>
          Next
        </Button>
      </div>
    </Form>
  ) : (
    <div className={classes.spinnerContainer}>
      <Spinner className={classes.spinner} />
    </div>
  );
};

export default CampaignBudgetForm;
