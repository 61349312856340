import { useState, useMemo, useCallback, useEffect } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useHistory } from 'react-router-dom';

import plural from 'utils/commons/plural';
import adPlacements from 'utils/Campaign/adPlacements';
import adFormats from 'utils/Campaign/adFormats';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';
import { selectAddOffersState } from 'state/selectors/campaigns';
import ViewIcon from 'assets/icons/view.png';
import ExportIcon from 'assets/icons/export.svg';
import CancelIcon from 'assets/icons/cancel.svg';
import { addOffers } from 'state/actions/campaigns';
import Path from 'enums/path.enum';
import sitbackIcon from 'assets/icons/sitback.svg';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalTypes';

import Body, {
  Size as BodySize,
  Spacing as BodySpacing,
  LetterCase as BodyLetterCase,
  Weight as BodyWeight,
} from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';
import Button from 'components/Common/Button';
import Modal from 'components/Common/Modal';
import NotesToTheBrand from 'components/Pages/Campaign/NotesToTheBrand';
import PodcastData from 'components/Pages/Podcast/PodcastData';
import PodcastCalendarModal from 'components/Pages/Podcast/PodcastCalendarModal';
import DeleteCampaignModal from '../DeleteCampaignModal';

import classes from './PodcastSelection.module.scss';

const PodcastSelection = ({
  campaign,
  pendingOffers,
  onClickMoreDetails,
  onClickExportCampaign,
}) => {
  const dispatch = useDispatch();
  const history = useHistory();
  const [offersAdded, setOffersAdded] = useState(
    campaign.offers.filter((offer) => !offer.isPaid) || []
  );
  const [isSubmitted, setOnSubmit] = useState(false);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const onClickNotesToTheBrandHandler = useCallback(
    (modalOffer) => {
      onOpenModalHandler(ModalType.NOTES_TO_THE_BRAND, {
        offer: modalOffer,
        selected: offersAdded.find((offer) => offer.id === modalOffer.id),
      });
    },
    [offersAdded]
  );

  const { loading, success, error } = useSelector(
    selectAddOffersState,
    shallowEqual
  );

  const onSubmitHandler = () => {
    const offersToAdd = offersAdded.map((offer) => ({
      ...offer,
      isPaid: false,
    }));

    dispatch(
      addOffers({
        campaignId: campaign.uid,
        offers: {
          offers: [...offersToAdd],
        },
      })
    );

    setOnSubmit(true);
  };

  useEffect(() => {
    if (isSubmitted && !loading && (success || error)) {
      setOnSubmit(false);
      history.push(`${Path.Summary}/${campaign.uid}`);
    }
  }, [isSubmitted, loading, error, success, campaign, campaign.uid]);

  const onAddOfferHandler = (offerToAdd) => {
    if (offersAdded.find((offer) => offer.id === offerToAdd.id)) {
      setOffersAdded(offersAdded.filter((offer) => offer.id !== offerToAdd.id));
    } else {
      setOffersAdded([...offersAdded, offerToAdd]);
    }
  };

  const onClickMoreInfoHandler = (podcast) => {
    onOpenModalHandler(ModalType.PODCAST_DATA, {
      podcast,
      selected: offersAdded.find((offer) => offer.id === podcast.id),
    });
  };

  const onClickSeeCalendarHandler = (podcast) => {
    onOpenModalHandler(ModalType.SEE_CALENDAR, {
      podcast,
    });
  };

  const onDeleteCampaignHandler = () => {
    onOpenModalHandler(ModalType.DELETE_CAMPAIGN, {
      campaign,
    });
  };

  const impressions = useMemo(
    () => offersAdded?.reduce((acc, offer) => acc + offer.impressions, 0),
    [offersAdded]
  );

  useEffect(() => {
    setOffersAdded(campaign.offers.filter((offer) => !offer.isPaid));
  }, [pendingOffers]);

  return (
    <>
      <div className={classes.container}>
        <div className={classes.headerContent}>
          {campaign.adFormats.length > 0 && (
            <div className={classes.adFormatContainer}>
              <Body size={BodySize.S} className={classes.title}>
                {
                  adFormats.find(
                    (addFormat) => campaign.adFormats[0] === addFormat.value
                  )?.label
                }
              </Body>
            </div>
          )}
          <div className={classes.contentRow}>
            <div className={classes.containerLeftColumn}>
              <div className={classes.containerImage}>
                {campaign.image && (
                  <img
                    src={campaign.image}
                    alt="Campaign_Image"
                    className={classes.image}
                  />
                )}
              </div>
              <div className={classes.containerText}>
                <Body size={BodySize.S} className={classes.title}>
                  {campaign.name || 'Campaign title'}
                </Body>
                <div className={classes.campaignDetails}>
                  <div className={classes.details}>
                    <Body size={BodySize.XS} className={classes.text}>
                      Budget
                    </Body>
                    <Body
                      size={BodySize.XS}
                      className={classNames(classes.budgetAmount, classes.text)}
                    >
                      {`$${formatNumberWithComas(campaign.budget)}` ||
                        'Campaign budget'}
                    </Body>
                  </div>
                  <div className={classes.details}>
                    <Body size={BodySize.XS} className={classes.text}>
                      Airing between
                    </Body>
                    <Body
                      size={BodySize.XS}
                      className={classNames(classes.budgetAmount, classes.text)}
                    >
                      {dayjs(campaign.airingFrom).format('MMMM DD, YYYY')} to{' '}
                      {dayjs(campaign.airingTo).format('MMMM DD, YYYY')}
                    </Body>
                  </div>
                </div>
              </div>
            </div>
            <div className={classes.campaignActions}>
              <IconButton
                className={classes.seeMore}
                onClick={onDeleteCampaignHandler}
              >
                <img src={CancelIcon} alt="cancel" className={classes.icon} />
                <Body className={classes.seeMore} size={BodySize.XXS}>
                  Cancel Campaign
                </Body>
              </IconButton>
              <IconButton
                className={classes.seeMore}
                onClick={onClickMoreDetails}
              >
                <img src={ViewIcon} alt="view" className={classes.icon} />
                <Body size={BodySize.XXS} className={classes.seeMore}>
                  More details
                </Body>
              </IconButton>
              <IconButton
                className={classes.seeMore}
                onClick={onClickExportCampaign}
              >
                <img src={ExportIcon} alt="view" className={classes.icon} />
                <Body size={BodySize.XXS} className={classes.seeMore}>
                  Export campaign
                </Body>
              </IconButton>
            </div>
          </div>
          {pendingOffers?.length > 0 ? (
            <div className={classes.statistics}>
              <div className={classes.estimateContainer}>
                <Body size={BodySize.L} className={classes.value}>
                  {offersAdded.length || 0}
                </Body>
                <Body size={BodySize.XS} className={classes.label}>
                  PODCASTS ADDED
                </Body>
              </div>
              <div className={classes.estimateContainer}>
                <Body size={BodySize.L} className={classes.value}>
                  {formatNumberWithComas(impressions) || 0}
                </Body>
                <Body size={BodySize.XS} className={classes.label}>
                  TOTAL IMPRESSIONS
                </Body>
              </div>
              <Button
                className={classes.payButton}
                onClick={onSubmitHandler}
                disabled={!offersAdded.length}
              >
                NEXT
              </Button>
            </div>
          ) : null}
        </div>
        {pendingOffers?.length > 0 ? (
          <div className={classes.offerDetailsContainer}>
            {pendingOffers?.map((offer, index) => {
              const isAdded = offersAdded.some(
                (addedOffer) => addedOffer.id === offer.id
              );
              const isLast = index === pendingOffers.length - 1;

              return (
                <div
                  key={`${index + 1}PO-${offer.id}`}
                  className={classNames(classes.offerDetails, {
                    [classes.bottomBorder]: !isLast,
                  })}
                >
                  <div className={classes.logoContainer}>
                    <img
                      src={offer.podcastImageUrl}
                      alt="logo"
                      className={classes.logo}
                    />
                  </div>
                  <div className={classes.offerContent}>
                    <Body size={BodySize.XS} className={classes.body}>
                      {offer.podcastName}
                    </Body>
                    <div className={classes.budgetContainer}>
                      <Body size={BodySize.XS} className={classes.episode}>
                        {offer.episodes}&nbsp;
                        {plural('episode', offer.episodes > 1)}
                      </Body>
                      <div className={classes.impressions}>
                        <Body
                          size={BodySize.XS}
                          weight={BodyWeight.Bold}
                          className={classes.body}
                        >
                          {formatNumberWithComas(offer.impressions)}
                        </Body>
                        <Body size={BodySize.XS} className={classes.body}>
                          Impressions
                        </Body>
                      </div>
                      <Body size={BodySize.XS} className={classes.budget}>
                        ${formatNumberWithComas(offer.amount)}
                      </Body>
                    </div>
                    <div className={classes.adsConfigurationContainer}>
                      <div>
                        <span className={classes.adsTitle}>Ad Placements</span>
                        <div className={classes.adPlacements}>
                          {offer.adPlacements?.map((podcastAdPlacement) => {
                            const adPlacement = adPlacements.find(
                              (placement) =>
                                podcastAdPlacement === placement.value
                            );

                            return (
                              <div
                                className={classes.selectedAdType}
                                key={adPlacement.value}
                              >
                                <div className={classes.selected} />
                                <Body
                                  size={BodySize.XS}
                                  className={classes.bold}
                                >
                                  {adPlacement.label}
                                </Body>
                              </div>
                            );
                          })}
                        </div>
                      </div>
                    </div>
                  </div>
                  <div className={classes.actions}>
                    <Button
                      kind="secondary"
                      size="small"
                      className={classes.button}
                      onClick={() => onClickMoreInfoHandler(offer)}
                    >
                      MORE INFO
                    </Button>
                    {offer.coverLetter && (
                      <Button
                        kind="secondary"
                        size="small"
                        className={classes.button}
                        onClick={() => onClickNotesToTheBrandHandler(offer)}
                      >
                        SEE NOTES
                      </Button>
                    )}
                    {!!offer?.publishingDates?.length && (
                      <Button
                        kind="secondary"
                        size="small"
                        className={classes.button}
                        onClick={() => onClickSeeCalendarHandler(offer)}
                      >
                        SEE CALENDAR
                      </Button>
                    )}
                    <Button
                      kind="primary"
                      size="small"
                      onClick={() => onAddOfferHandler(offer)}
                      className={classNames(classes.button, classes.addButton, {
                        [classes.added]: isAdded,
                      })}
                    >
                      <span>{isAdded ? 'ADDED' : 'ADD'}</span>
                    </Button>
                  </div>
                </div>
              );
            })}
          </div>
        ) : (
          <div className={classes.noOffers}>
            <div className={classes.noContent}>
              <img
                src={sitbackIcon}
                alt="success"
                className={classes.sitBack}
              />
              <Body
                letterCase={BodyLetterCase.Uppercase}
                spacing={BodySpacing.M}
                className={classes.title}
              >
                Campaign submitted
              </Body>
              <Body
                letterCase={BodyLetterCase.Lowercase}
                size={BodySize.XS}
                className={classes.subtext}
              >
                Sit back while we connect you with the right podcasters.
              </Body>
              <Body
                letterCase={BodyLetterCase.Lowercase}
                size={BodySize.XS}
                className={classes.subtext}
              >
                We&apos;re gonna send you an email when it&apos;s ready.
              </Body>
            </div>
          </div>
        )}
      </div>
      <Modal
        isOpen={modal.type === ModalType.NOTES_TO_THE_BRAND}
        onClose={onCloseModalHandler}
        className={classes.notesToTheBrandModal}
      >
        <NotesToTheBrand
          offer={modal.offer}
          selected={modal.selected}
          onSelect={onAddOfferHandler}
          onClose={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.PODCAST_DATA}
        onClose={onCloseModalHandler}
        className={classes.notesToTheBrandModal}
      >
        <PodcastData
          addButton
          podcast={modal.podcast}
          onSelect={onAddOfferHandler}
          selected={modal.selected}
          onClose={onCloseModalHandler}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.SEE_CALENDAR}
        onClose={onCloseModalHandler}
        className={classNames(
          classes.podcastCalendarModal,
          classes.podcastCalendarContainer
        )}
      >
        <PodcastCalendarModal
          podcast={modal.podcast}
          airingTo={campaign.airingTo}
          airingFrom={campaign.airingFrom}
        />
      </Modal>
      <Modal
        isOpen={modal.type === ModalType.DELETE_CAMPAIGN}
        onClose={onCloseModalHandler}
        className={classes.deleteCampaignModal}
      >
        <DeleteCampaignModal
          campaign={campaign}
          onClose={onCloseModalHandler}
        />
      </Modal>
    </>
  );
};

PodcastSelection.propTypes = {
  campaign: PropTypes.shape({
    adFormats: PropTypes.oneOfType([PropTypes.string]),
    uid: PropTypes.string,
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }).isRequired,
  pendingOffers: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      podcastImageUrl: PropTypes.string,
      title: PropTypes.string,
      budget: PropTypes.number,
      adTypes: PropTypes.arrayOf(PropTypes.string),
      offerStatus: PropTypes.string,
    })
  ).isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
  onClickExportCampaign: PropTypes.func.isRequired,
};

export default PodcastSelection;
