const Required = Object.freeze({
  Email: 'Email is required',
  Password: 'Password is required',
  ConfirmPassword: 'Please confirm your password',
  Name: 'Name is required',
  Phone: 'Phone is required',
  BrandName: 'Brand name is required',
  BrandUrl: 'Brand URL is required',
  Linkedin: 'Linkedin is required',
  StartDate: 'Start date is required',
  EndDate: 'End date is required',
  Budget: 'Budget is required',
  Description: 'Description is required',
  Copy: 'Ad copy text is required',
  Categories: 'At Least One Category is Required',
  AdPlacement: 'At Least One defined Ad Placement is Required',
  TalkingPoints: 'Talking Points are required',
});

export default Required;
