/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button, { Type as ButtonType } from 'components/Common/Button';
import { login } from 'state/actions/auth';
import { selectLoginState } from 'state/selectors/auth';
import loginInputs from 'utils/inputs/loginInputs';

import validationSchema from './LoginForm.schema';
import classes from './LoginForm.module.scss';

const LoginForm = () => {
  const dispatch = useDispatch();

  const { loading } = useSelector(selectLoginState, shallowEqual);

  const onSubmitHandler = useCallback((values) => {
    dispatch(login(values));
  }, []);

  return (
    <Form
      validationMode={ValidationMode.OnChange}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
      className={classes.form}
    >
      {loginInputs.map(({ name, renderProps }) => (
        <FormControl
          key={name}
          name={name}
          render={(props) => <Input {...props} {...renderProps} />}
        />
      ))}
      <Button type={ButtonType.Submit} isFullWidth loading={loading}>
        Sign in
      </Button>
    </Form>
  );
};

export default LoginForm;
