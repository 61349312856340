import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { useLocation } from 'react-router-dom';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button, { Type as ButtonType } from 'components/Common/Button';
import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import { newPassword } from 'state/actions/auth';
import { selectNewPasswordState } from 'state/selectors/auth';
import ossaLogo from 'assets/logos/ossa-logo.png';
import newPasswordInputs from 'utils/inputs/newPasswordInputs';

import validationSchema from './NewPassword.schema';
import classes from './NewPassword.module.scss';

const NewPassword = () => {
  const dispatch = useDispatch();
  const { search } = useLocation();

  const [code, setCode] = useState(null);

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(search);

    const { oobCode } = Object.fromEntries(urlSearchParams.entries());

    setCode(oobCode);
  }, []);

  const { loading, error } = useSelector(selectNewPasswordState, shallowEqual);

  const onSubmitHandler = useCallback(
    ({ password }) => {
      dispatch(newPassword(code, password));
    },
    [code]
  );

  return (
    <>
      {error && <Toast text={error} id="New password error" />}
      <div className={classes.container}>
        <div className={classes.logo}>
          <img src={ossaLogo} alt="Ossa Logo" />
        </div>
        <div>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Password Recovery
          </Body>
          <Form
            validationMode={ValidationMode.OnChange}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
          >
            {newPasswordInputs.map(({ name, renderProps }) => (
              <FormControl
                key={name}
                name={name}
                render={(props) => <Input {...props} {...renderProps} />}
              />
            ))}
            <Button type={ButtonType.Submit} isFullWidth loading={loading}>
              Reset
            </Button>
          </Form>
        </div>
      </div>
    </>
  );
};

export default NewPassword;
