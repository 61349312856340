import React, { useMemo, useCallback, useEffect, useState } from 'react';
import { Redirect, Route, Switch } from 'react-router-dom';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';

import LoginRoute from 'components/Navigation/LoginRoute';
import AuthenticatedRoute from 'components/Navigation/AuthenticatedRoute';
import Loader from 'components/Common/Loader';
import ExternalRedirect from 'components/Navigation/ExternalRedirect';
import { selectFetchExistingCampaignsState } from 'state/selectors/campaigns';
import {
  selectAuthenticatedUserState,
  selectVerifyAuthState,
} from 'state/selectors/auth';
import {
  fetchUnfinishedCampaign,
  fetchExistingCampaigns,
} from 'state/actions/campaigns';
import { selectCreateCheckoutSessionState } from 'state/selectors/stripe';
import loginRoutes from 'utils/routes/loginRoutes';
import authenticatedRoutes from 'utils/routes/authenticatedRoutes';
import Path from 'enums/path.enum';
import { HAS_CAMPAIGNS } from 'constants/campaign';

const Router = () => {
  const dispatch = useDispatch();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const { loading: loadingVerifyAuth, error: verifyAuthError } = useSelector(
    selectVerifyAuthState,
    shallowEqual
  );

  const {
    campaigns,
    success: successFetchingExistingCampaigns,
    loading: loadingFetchingExistingCampaigns,
    error: errorFetchingExistingCampaigns,
  } = useSelector(selectFetchExistingCampaignsState, shallowEqual);

  const { session } = useSelector(
    selectCreateCheckoutSessionState,
    shallowEqual
  );

  const [fetchedCampaigns, setFetchedCampaigns] = useState(false);

  const isAuthenticated = useMemo(() => !!user, [user]);

  const userVerified = useMemo(
    () => !!user && !user.waitingForConfirmation && user.enabled,
    [user, user?.waitingForConfirmation, user?.enabled]
  );

  const hasExistingCampaigns = useMemo(
    () => successFetchingExistingCampaigns && campaigns.length > 0,
    [successFetchingExistingCampaigns, campaigns]
  );

  const isLoading = useMemo(
    () =>
      loadingVerifyAuth ||
      (!user && !verifyAuthError) ||
      loadingFetchingExistingCampaigns ||
      (user &&
        !errorFetchingExistingCampaigns &&
        !successFetchingExistingCampaigns),
    [
      loadingVerifyAuth,
      user,
      verifyAuthError,
      loadingFetchingExistingCampaigns,
      errorFetchingExistingCampaigns,
      successFetchingExistingCampaigns,
    ]
  );

  useEffect(() => {
    if (user && !fetchedCampaigns) {
      dispatch(fetchUnfinishedCampaign());
      dispatch(fetchExistingCampaigns());
      setFetchedCampaigns(true);
    }
  }, [user, fetchedCampaigns]);

  const getCondition = useCallback(
    (condition) => {
      if (condition === HAS_CAMPAIGNS) {
        return hasExistingCampaigns;
      }
      return true;
    },
    [hasExistingCampaigns]
  );

  return isLoading ? (
    <Loader />
  ) : (
    <Switch>
      {authenticatedRoutes.map(({ component, path, condition, redirectTo }) => (
        <AuthenticatedRoute
          key={path}
          component={component}
          path={path}
          condition={
            condition ? getCondition(condition) && userVerified : userVerified
          }
          redirectTo={redirectTo || Path.ThanksForApplying}
          exact
        />
      ))}
      {loginRoutes.map(({ component, path, hasCondition }) => (
        <LoginRoute
          key={path}
          component={component}
          condition={hasCondition ? !isAuthenticated : true}
          redirectTo={Path.Home}
          path={path}
          exact
        />
      ))}
      <ExternalRedirect exact path={Path.Checkout} to={session?.url || '#'} />
      <Route exact path={Path.Home}>
        <Redirect to={Path.Campaigns} />
      </Route>
      <Route path="*">
        <Redirect to={Path.Home} />
      </Route>
    </Switch>
  );
};

export default Router;
