import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPodcastersCountriesIsoCodesInit,
  fetchPodcastersCountriesIsoCodesSuccess,
  fetchPodcastersCountriesIsoCodesFail,
  fetchDefaultValuesSuccess,
  fetchDefaultValuesInit,
  fetchDefaultValuesFail,
  fetchHostingProvidersInit,
  fetchHostingProvidersSuccess,
  fetchHostingProvidersFail,
} from 'state/actionCreators/generals';

export const initialState = {
  fetchPodcastersCountriesIsoCodesLoading: false,
  fetchPodcastersCountriesIsoCodesSuccess: false,
  fetchPodcastersCountriesIsoCodesError: null,
  podcastersIsoCodes: [],
  fetchDefaultValuesLoading: false,
  fetchDefaultValuesSuccess: false,
  fetchDefaultValuesError: null,
  fetchHostingProvidersLoading: false,
  fetchHostingProvidersSuccess: false,
  fetchHostingProvidersError: null,
  defaultValues: {},
  hostingProviders: [],
};

const generalsReducer = createReducer(initialState, {
  [fetchPodcastersCountriesIsoCodesInit]: (state) => {
    state.fetchPodcastersCountriesIsoCodesLoading = true;
    state.fetchPodcastersCountriesIsoCodesSuccess = false;
    state.fetchPodcastersCountriesIsoCodesError = null;
    state.podcastersIsoCodes = [];
  },
  [fetchPodcastersCountriesIsoCodesSuccess]: (state, { payload }) => {
    const { podcastersIsoCodes } = payload;

    state.fetchPodcastersCountriesIsoCodesLoading = false;
    state.fetchPodcastersCountriesIsoCodesSuccess = true;
    state.fetchPodcastersCountriesIsoCodesError = null;
    state.podcastersIsoCodes = podcastersIsoCodes;
  },
  [fetchPodcastersCountriesIsoCodesFail]: (state, { payload }) => {
    const { error } = payload;

    state.fetchPodcastersCountriesIsoCodesLoading = false;
    state.fetchPodcastersCountriesIsoCodesSuccess = false;
    state.fetchPodcastersCountriesIsoCodesError = error;
  },
  [fetchDefaultValuesInit]: (state) => {
    state.fetchDefaultValuesLoading = true;
    state.fetchDefaultValuesSuccess = false;
    state.fetchDefaultValuesError = null;
  },
  [fetchDefaultValuesSuccess]: (state, { payload }) => {
    const { defaultValues } = payload;

    state.fetchDefaultValuesLoading = false;
    state.fetchDefaultValuesSuccess = true;
    state.fetchDefaultValuesError = null;
    state.defaultValues = defaultValues;
  },
  [fetchDefaultValuesFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchDefaultValuesLoading = false;
    state.fetchDefaultValuesSuccess = false;
    state.fetchDefaultValuesError = error;
  },

  [fetchHostingProvidersInit]: (state) => {
    state.fetchHostingProvidersLoading = true;
    state.fetchHostingProvidersSuccess = false;
    state.fetchHostingProvidersError = null;
  },
  [fetchHostingProvidersSuccess]: (state, { payload }) => {
    const { hostingProviders } = payload;
    state.fetchHostingProvidersLoading = false;
    state.fetchHostingProvidersSuccess = true;
    state.fetchHostingProvidersError = null;
    state.hostingProviders = hostingProviders;
  },
  [fetchHostingProvidersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchHostingProvidersLoading = false;
    state.fetchHostingProvidersSuccess = false;
    state.fetchHostingProvidersError = error;
  },
});

export default generalsReducer;
