import { createAction } from '@reduxjs/toolkit';

export const createCheckoutSessionInit = createAction(
  '[Stripe] Create Checkout Session Init'
);
export const createCheckoutSessionSuccess = createAction(
  '[Stripe] Create Checkout Session Success'
);
export const createCheckoutSessionFail = createAction(
  '[Stripe] Create Checkout Session Fail'
);

export const saveCheckoutSessionInit = createAction(
  '[Stripe] Save Checkout Session Init'
);
export const saveCheckoutSessionSuccess = createAction(
  '[Stripe] Save Checkout Session Success'
);
export const saveCheckoutSessionFail = createAction(
  '[Stripe] Save Checkout Session Fail'
);

export const fetchCheckoutSessionInit = createAction(
  '[Stripe] Fetch Checkout Session Init'
);
export const fetchCheckoutSessionSuccess = createAction(
  '[Stripe] Fetch Checkout Session Success'
);
export const fetchCheckoutSessionFail = createAction(
  '[Stripe] Fetch Checkout Session Fail'
);

export const clearStripe = createAction('[Stripe] Clear Stripe');
