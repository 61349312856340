const CAMEL_CASE_BOUNDARY = /(?=[A-Z])/;
const FIRST_LETTER_IN_WORD = /(^\w{1})|(\s+\w{1})/g;

const removeCamelCaseAndJoin = (stringArray) => {
  if (!stringArray) return '';

  return stringArray
    .map((string) =>
      string
        .split(CAMEL_CASE_BOUNDARY)
        .join(' ')
        .replace(FIRST_LETTER_IN_WORD, (letter) => letter.toUpperCase())
    )
    .join(', ');
};

export default removeCamelCaseAndJoin;
