import { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import formatNumberWithSuffix from 'utils/functions/formatNumberWithSuffix';
import generateRandomInteger from 'utils/functions/generateRandomInteger';
import classes from './EstimatedImpressions.module.scss';

const getPercentageValue = (value, percentage) => value * (percentage / 100);

const DEFAULT_RANDOM_LIMIT = 1000;

const EstimatedImpressions = ({
  className,
  loading,
  potentialPodcasts,
  estimatedImpressions,
  titleClassName,
  textClassName,
  separatorClassName,
  onlyEstimations = false,
}) => {
  const [intervalId, setIntervalId] = useState(null);

  const [
    potentialPodcastsPlaceholder,
    setPotentialPodcastsPlaceholder,
  ] = useState(0);

  const [
    estimatedImpressionsPlaceholder,
    setEstimatedImpressionsPlaceholder,
  ] = useState(0);

  useEffect(() => {
    if (!loading && intervalId) {
      clearInterval(intervalId);
      setIntervalId(null);
    }

    if (onlyEstimations && loading && !intervalId) {
      const minEstimatedImpressions = getPercentageValue(
        estimatedImpressions || DEFAULT_RANDOM_LIMIT,
        50
      );

      const maxEstimatedImpressions =
        estimatedImpressions || DEFAULT_RANDOM_LIMIT;

      const newIntervalId = setInterval(() => {
        setEstimatedImpressionsPlaceholder(
          generateRandomInteger(
            minEstimatedImpressions,
            maxEstimatedImpressions
          )
        );
      }, 100);

      setIntervalId(newIntervalId);
    }
    if (!onlyEstimations && loading && !intervalId) {
      const minPotentialPodcasts = getPercentageValue(
        potentialPodcasts || DEFAULT_RANDOM_LIMIT,
        50
      );

      const minEstimatedImpressions = getPercentageValue(
        estimatedImpressions || DEFAULT_RANDOM_LIMIT,
        50
      );

      const maxPotentialPodcasts = potentialPodcasts || DEFAULT_RANDOM_LIMIT;

      const maxEstimatedImpressions =
        estimatedImpressions || DEFAULT_RANDOM_LIMIT;

      const newIntervalId = setInterval(() => {
        setPotentialPodcastsPlaceholder(
          generateRandomInteger(minPotentialPodcasts, maxPotentialPodcasts)
        );

        setEstimatedImpressionsPlaceholder(
          generateRandomInteger(
            minEstimatedImpressions,
            maxEstimatedImpressions
          )
        );
      }, 100);

      setIntervalId(newIntervalId);
    }
  }, [
    loading,
    potentialPodcasts,
    estimatedImpressions,
    intervalId,
    onlyEstimations,
  ]);

  const potentialPodcastsValue = useMemo(
    () =>
      loading
        ? formatNumberWithSuffix(potentialPodcastsPlaceholder)
        : formatNumberWithSuffix(potentialPodcasts),
    [loading, potentialPodcastsPlaceholder, potentialPodcasts]
  );

  const estimatedImpressionsValue = useMemo(
    () =>
      loading
        ? formatNumberWithSuffix(estimatedImpressionsPlaceholder)
        : formatNumberWithSuffix(estimatedImpressions),
    [loading, estimatedImpressionsPlaceholder, estimatedImpressions]
  );

  return (
    <div className={classNames(classes.container, className)}>
      {!onlyEstimations && (
        <>
          <div className={classes.data}>
            <span className={classNames(classes.title, titleClassName)}>
              {potentialPodcastsValue}
            </span>
            <span className={classNames(classes.text, textClassName)}>
              potential podcasts
            </span>
          </div>
          <span className={classNames(classes.separator, separatorClassName)}>
            with
          </span>
        </>
      )}
      <div className={classes.data}>
        <span className={classNames(classes.title, titleClassName)}>
          {estimatedImpressionsValue}
        </span>
        <span className={classNames(classes.text, textClassName)}>
          estimated impressions
        </span>
      </div>
    </div>
  );
};

EstimatedImpressions.propTypes = {
  className: PropTypes.string,
  loading: PropTypes.bool,
  estimatedImpressions: PropTypes.string,
  potentialPodcasts: PropTypes.number,
  titleClassName: PropTypes.string,
  textClassName: PropTypes.string,
  separatorClassName: PropTypes.string,
  onlyEstimations: PropTypes.bool,
};

EstimatedImpressions.defaultProps = {
  className: '',
  titleClassName: '',
  loading: false,
  estimatedImpressions: 0,
  potentialPodcasts: 0,
  textClassName: '',
  onlyEstimations: false,
  separatorClassName: '',
};

export default EstimatedImpressions;
