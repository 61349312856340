import React, { useEffect, useState } from 'react';
import { useParams, useHistory } from 'react-router-dom';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import Spinner from 'components/Common/Spinner';

import {
  fetchCampaignById,
  finishedCampaign,
  updateCampaignsTabAction,
} from 'state/actions/campaigns';
import {
  selectFetchCampaignByIdState,
  selectFinishedCampaignState,
} from 'state/selectors/campaigns';
import { clearStripeState, fetchCheckoutSession } from 'state/actions/stripe';
import {
  selectCreateCheckoutSessionState,
  selectFetchCheckoutSessionState,
} from 'state/selectors/stripe';
import Path from 'enums/path.enum';
import StripeSessionStates from 'enums/stripe/stripeSessionStates.enum';
import CampaignsTab from 'enums/pages/campaigns/campaignsTab.enum';

import classes from './SubmittedCampaign.module.scss';

const SubmittedCampaign = () => {
  const { campaignId } = useParams();
  const dispatch = useDispatch();
  const history = useHistory();

  const [campaignUpdatedStatus, setCampaignUpdatedStatus] = useState(false);

  const { success: successCreatingCheckout } = useSelector(
    selectCreateCheckoutSessionState,
    shallowEqual
  );

  const { campaign, success: successFetchingCampaign } = useSelector(
    selectFetchCampaignByIdState,
    shallowEqual
  );

  const { success: successFinishedCampaign } = useSelector(
    selectFinishedCampaignState,
    shallowEqual
  );

  const { session } = useSelector(
    selectFetchCheckoutSessionState,
    shallowEqual
  );

  const [clearedState, setClearedState] = useState(false);

  useEffect(() => {
    dispatch(fetchCampaignById({ campaignId }));
  }, []);

  useEffect(() => {
    if (successFetchingCampaign && !clearedState) {
      if (!campaign || (!campaign.session && !successCreatingCheckout)) {
        history.push(Path.Campaigns);
      } else if (!successCreatingCheckout) {
        dispatch(fetchCheckoutSession({ session: campaign.session }));
      }
    }
  }, [
    successFetchingCampaign,
    campaign,
    successCreatingCheckout,
    clearedState,
  ]);

  useEffect(() => {
    if (session) {
      if (session.payment_status === StripeSessionStates.Unpaid) {
        history.push(Path.Campaigns);
      } else {
        dispatch(finishedCampaign({ campaignId }));
        dispatch(clearStripeState());
        dispatch(updateCampaignsTabAction(CampaignsTab.CurrentCampaigns));
        setClearedState(true);
        setCampaignUpdatedStatus(true);
      }
    }
  }, [session]);

  useEffect(() => {
    if (campaignUpdatedStatus && successFinishedCampaign) {
      setCampaignUpdatedStatus(false);
      history.push(Path.Campaigns);
    }
  }, [campaignUpdatedStatus, successFinishedCampaign]);

  useEffect(() => {
    if (successCreatingCheckout) {
      dispatch(finishedCampaign({ campaignId }));
      dispatch(clearStripeState());
      dispatch(updateCampaignsTabAction(CampaignsTab.CurrentCampaigns));
      setClearedState(true);
      setCampaignUpdatedStatus(true);
    }
  }, [successCreatingCheckout]);

  return (
    <div className={classes.container}>
      <Spinner className={classes.spinner} />
    </div>
  );
};

export default SubmittedCampaign;
