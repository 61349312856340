const campaignAdTypes = Object.freeze({
  preRoll: 'preRoll',
  midRoll: 'midRoll',
  postRoll: 'postRoll',
  backCatalog: 'backCatalog',
  bakedIn: 'bakedIn',
  dynamicAd: 'dynamicAd',
});

export default campaignAdTypes;
