import { getAuth, updatePassword } from 'firebase/auth';

const changePasswordManually = async (newPassword) => {
  try {
    const auth = getAuth();

    const user = auth.currentUser;
    await updatePassword(user, newPassword);
  } catch (error) {
    throw new Error(error);
  }
};

export default changePasswordManually;
