import { doc, getDoc, writeBatch } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';
import offerStatus from 'enums/campaign/offerStatus.enum';
import { STATUS_IN_PROGRESS } from 'utils/Campaign/status';

const finishedCampaign = async ({ brandId, campaignId }) => {
  try {
    const brandRef = doc(firestore, Collections.Brands, brandId);

    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaignId
    );

    const campaignPromise = getDoc(campaignRef);
    const brandPromise = getDoc(brandRef);

    const [campaign, brand] = await Promise.all([
      campaignPromise,
      brandPromise,
    ]);

    const campaignData = campaign.data();
    const brandData = brand.data();

    let totalPayment = 0;

    campaignData.offers.forEach((offer) => {
      if (!offer.isPaid) {
        totalPayment += Number(offer.amount);
      }
    });

    const batch = writeBatch(firestore);

    const finishedStatus = [
      offerStatus.accepted,
      offerStatus.pending,
      offerStatus.waitingBrandApproval,
    ];

    const updatedCampaignOffers = campaignData.offers
      .filter((offer) => finishedStatus.includes(offer.status))
      .map((offer) => ({
        ...offer,
        status: offerStatus.accepted,
        isPaid: true,
      }));

    batch.update(campaignRef, {
      finishedCreatingCampaign: true,
      isPaid: true,
      offers: updatedCampaignOffers,
      session: null,
      status: STATUS_IN_PROGRESS,
    });

    if (brandData.budgetInFavor > 0) {
      const newBudgetInFavor = Math.max(
        Number((brandData.budgetInFavor - totalPayment).toFixed(2)),
        0
      );

      batch.update(brandRef, { budgetInFavor: newBudgetInFavor });
    }

    await batch.commit();
  } catch (error) {
    throw new Error(error);
  }
};

export default finishedCampaign;
