import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './Checkbox.module.scss';

const Checkbox = ({
  label,
  selected,
  setSelected,
  className,
  labelClassName,
}) => (
  <div className={classNames(classes.container, className)}>
    <button
      type="button"
      onClick={setSelected}
      className={classes.button}
      aria-label={label}
    >
      <div className={classes.checkbox}>
        {selected && (
          <div className={classes.selected} data-testid="selected" />
        )}
      </div>
    </button>
    {label && (
      <Body
        size={BodySize.XXS}
        className={classNames(labelClassName, classes.label)}
      >
        {label}
      </Body>
    )}
  </div>
);

Checkbox.propTypes = {
  label: PropTypes.string,
  selected: PropTypes.bool.isRequired,
  setSelected: PropTypes.func.isRequired,
  className: PropTypes.string,
  labelClassName: PropTypes.string,
};

Checkbox.defaultProps = {
  label: null,
  className: '',
  labelClassName: '',
};

export default Checkbox;
