import React from 'react';
import { Controller, useFormContext } from 'react-hook-form';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';

import classes from './FormControl.module.scss';

const FormControl = ({
  name,
  validations,
  render,
  className,
  defaultValue,
  onChange: onChangeHandler,
  ...props
}) => {
  const { control, errors } = useFormContext({});

  const error = errors?.[name];

  return (
    <div
      className={classNames(classes.formControl, className, {
        [classes.formControlWithError]: error,
      })}
    >
      <Controller
        name={name}
        control={control}
        {...(defaultValue && { defaultValue })}
        render={({ onChange, value, ...rest }) => {
          const customChangeHandler = (newValue) => {
            if (onChangeHandler) {
              return onChange(onChangeHandler(newValue, value));
            }

            return onChange(newValue);
          };

          return (
            <>
              {render({
                name,
                error: !!error?.message,
                ...props,
                onChange: customChangeHandler,
                value,
                ...rest,
              })}
            </>
          );
        }}
      />
      {error && (
        <Body
          color={BodyColor.Red}
          size={BodySize.XXS}
          className={classes.error}
        >
          {error.message}
        </Body>
      )}
    </div>
  );
};

FormControl.propTypes = {
  name: PropTypes.string.isRequired,
  // eslint-disable-next-line react/forbid-prop-types
  render: PropTypes.any.isRequired,
  validations: PropTypes.shape({}),
  className: PropTypes.string,
  onChange: PropTypes.func,
  // eslint-disable-next-line react/forbid-prop-types
  defaultValue: PropTypes.any,
};

FormControl.defaultProps = {
  validations: {},
  className: '',
  onChange: null,
  defaultValue: null,
};

export default FormControl;
