import { configureStore } from '@reduxjs/toolkit';

import rootReducer from 'state/reducers';
import { verifyAuth } from './actions/auth';

const store = configureStore({
  reducer: rootReducer,
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({ serializableCheck: false }),
});

if (process.env.NODE_ENV === 'development' && module.hot) {
  module.hot.accept('state/reducers', () => {
    // eslint-disable-next-line global-require
    const newRootReducer = require('state/reducers').default;
    store.replaceReducer(newRootReducer);
  });
}

store.dispatch(verifyAuth());

export default store;
