import createCheckoutSessionService from 'services/stripe/createCheckoutSession';
import createCouponService from 'services/stripe/createCoupon';
import saveCheckoutSessionService from 'services/stripe/saveCheckoutSession';
import fetchCheckoutSessionService from 'services/stripe/fetchCheckoutSession';

import {
  clearStripe,
  createCheckoutSessionFail,
  createCheckoutSessionInit,
  createCheckoutSessionSuccess,
  fetchCheckoutSessionFail,
  fetchCheckoutSessionInit,
  fetchCheckoutSessionSuccess,
  saveCheckoutSessionFail,
  saveCheckoutSessionInit,
  saveCheckoutSessionSuccess,
} from 'state/actionCreators/stripe';

export const createCheckoutSession = ({ offers }) => async (
  dispatch,
  getState
) => {
  dispatch(createCheckoutSessionInit());

  try {
    const { user } = getState().auth;
    const { campaign } = getState().campaigns;

    let offersBudget = 0;

    offers.forEach((offer) => {
      offersBudget += Number(offer.budget);
    });

    offersBudget = Number(offersBudget.toFixed(2));

    let totalDescount = 0;

    if (user.budgetInFavor > 0) {
      if (user.budgetInFavor >= offersBudget) {
        totalDescount = offersBudget;
      } else {
        totalDescount = user.budgetInFavor;
      }
    }

    let coupon = null;

    if (totalDescount > 0 && totalDescount !== offersBudget) {
      coupon = await createCouponService({
        amount: totalDescount,
      });
    }

    if (totalDescount !== offersBudget) {
      const session = await createCheckoutSessionService({
        brandId: user.uid,
        campaignId: campaign.uid,
        couponId: coupon?.data,
      });

      return dispatch(createCheckoutSessionSuccess({ session }));
    }
    return dispatch(createCheckoutSessionSuccess({ session: null }));
  } catch (error) {
    return dispatch(createCheckoutSessionFail({ error: error.message }));
  }
};

export const saveCheckoutSession = () => async (dispatch, getState) => {
  dispatch(saveCheckoutSessionInit());

  try {
    const { user } = getState().auth;
    const { campaign } = getState().campaigns;
    const { session } = getState().stripe;

    await saveCheckoutSessionService({
      brandId: user.uid,
      campaignId: campaign.uid,
      session,
    });

    return dispatch(saveCheckoutSessionSuccess());
  } catch (error) {
    return dispatch(saveCheckoutSessionFail({ error: error.message }));
  }
};

export const fetchCheckoutSession = ({ session }) => async (dispatch) => {
  dispatch(fetchCheckoutSessionInit());

  try {
    const sessionData = await fetchCheckoutSessionService({
      session,
    });

    return dispatch(fetchCheckoutSessionSuccess({ session: sessionData }));
  } catch (error) {
    return dispatch(fetchCheckoutSessionFail({ error: error.message }));
  }
};

export const clearStripeState = () => clearStripe();
