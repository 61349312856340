import { CSVLink } from 'react-csv';
import PropTypes from 'prop-types';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';

import classes from './ExportCsvButton.module.scss';

const ExportCsvButton = ({ headers, data, title, filename }) => (
  <Button kind={ButtonKind.Secondary} size={ButtonSize.S}>
    <CSVLink
      data={data}
      headers={headers}
      filename={filename}
      className={classes.link}
    >
      {title}
    </CSVLink>
  </Button>
);

ExportCsvButton.propTypes = {
  headers: PropTypes.arrayOf(PropTypes.any).isRequired,
  data: PropTypes.arrayOf(PropTypes.any).isRequired,
  title: PropTypes.string.isRequired,
  filename: PropTypes.string.isRequired,
};

export default ExportCsvButton;
