import { doc, getDoc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const fetchCampaignById = async ({ brandId, campaignId }) => {
  try {
    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaignId
    );

    const firestoreCampaign = await getDoc(campaignRef);

    if (!firestoreCampaign.exists()) {
      return null;
    }

    return { ...firestoreCampaign.data(), uid: campaignId };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchCampaignById;
