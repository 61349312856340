import React from 'react';
import Input from 'components/Common/Input';
import PropTypes from 'prop-types';
import SelectInput from 'components/Common/Select';
import sortingOptions from 'utils/selectOptions/getSortingOptions';
import classes from './SearchCampaign.module.scss';

const SearchCampaign = ({
  searchValue,
  handleSearch,
  handleSelect,
  selectedSort,
  setRefInput,
  showFilter,
}) => (
  <div className={classes.container}>
    <Input
      name="Search campaign"
      type="text"
      placeholder="Search campaign"
      value={searchValue}
      onChange={handleSearch}
      className={classes.searchInput}
      register={(ref) => setRefInput(ref)}
    />
    {showFilter && (
      <SelectInput
        name="Select Sort Campaign"
        options={sortingOptions}
        onChange={handleSelect}
        value={selectedSort}
        className={classes.sort}
      />
    )}
  </div>
);

SearchCampaign.propTypes = {
  searchValue: PropTypes.string,
  handleSearch: PropTypes.func,
  handleSelect: PropTypes.func,
  selectedSort: PropTypes.shape({}),
  setRefInput: PropTypes.func,
  showFilter: PropTypes.bool.isRequired,
};

SearchCampaign.defaultProps = {
  searchValue: '',
  selectedSort: {},
  handleSearch: () => {},
  handleSelect: () => {},
  setRefInput: () => {},
};

export default SearchCampaign;
