import campaignAdTypes from 'enums/campaign/campaignAdTypes.enum';

const getCampaignSummaryData = ({ campaign }) => {
  const podcastsAdTypes = {};
  const podcastsBudgets = {};
  const podcastsImpressions = {};
  const podcastsEpisodes = {};

  campaign.offers.forEach((offer) => {
    if (!offer.isPaid) {
      const { podcastId } = offer;

      podcastsAdTypes[podcastId] = {
        preRoll: campaign.adPlacements.includes(campaignAdTypes.preRoll),
        midRoll: campaign.adPlacements.includes(campaignAdTypes.midRoll),
        postRoll: campaign.adPlacements.includes(campaignAdTypes.postRoll),
        backCatalog: campaign.adPlacements.includes(
          campaignAdTypes.backCatalog
        ),
        bakedIn: campaign.adPlacements.includes(campaignAdTypes.bakedIn),
        dynamicAd: campaign.adPlacements.includes(campaignAdTypes.dynamicAd),
      };

      podcastsEpisodes[podcastId] = offer.episodes;
      podcastsBudgets[podcastId] = Number(offer.amount);
      podcastsImpressions[podcastId] = offer.impressions;
    }
  });

  let allBadgets = 0;
  Object.values(podcastsBudgets).forEach((value) => {
    const budget = Number(value);

    if (!Number.isNaN(budget) && budget > 0) {
      allBadgets += budget;
    }
  });

  allBadgets = Number(allBadgets.toFixed(2));

  return {
    podcastsAdTypes,
    podcastsBudgets,
    podcastsImpressions,
    podcastsEpisodes,
    allBadgets,
  };
};

export default getCampaignSummaryData;
