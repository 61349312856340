import React from 'react';
import RcSlider, { SliderTooltip } from 'rc-slider';
import PropTypes from 'prop-types';

const { Handle: SliderHandle } = RcSlider;

const Handle = ({
  value,
  dragging,
  handleLeftText,
  handleRightText,
  index,
  ...restProps
}) => (
  <SliderTooltip
    prefixCls="rc-slider-tooltip"
    overlay={`${100 - value}% ${handleLeftText}, ${value}% ${handleRightText}`}
    visible={dragging}
    placement="top"
    key={index}
  >
    <SliderHandle value={value} {...restProps} />
  </SliderTooltip>
);

Handle.propTypes = {
  value: PropTypes.number.isRequired,
  dragging: PropTypes.bool.isRequired,
  index: PropTypes.number.isRequired,
  handleRightText: PropTypes.string.isRequired,
  handleLeftText: PropTypes.string.isRequired,
};

export default Handle;
