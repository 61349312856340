import fetchAllPodcastsService from 'services/podcasts/fetchAllPodcasts';
import fetchPodcastsEstimationsIdService from 'services/podcasts/fetchPodcastsEstimations';
import {
  fetchPodcastsInit,
  fetchPodcastsSuccess,
  fetchPodcastsFail,
  fetchPodcastsEstimationsInit,
  fetchPodcastsEstimationsSuccess,
  fetchPodcastsEstimationsFail,
  clearEstimationsPodcasts,
} from 'state/actionCreators/podcasts';

export const fetchPodcasts = () => async (dispatch) => {
  dispatch(fetchPodcastsInit());

  try {
    const podcasts = await fetchAllPodcastsService();

    return dispatch(fetchPodcastsSuccess({ podcasts }));
  } catch (error) {
    return dispatch(fetchPodcastsFail({ error: error.message }));
  }
};

export const fetchPodcastsEstimations = (args = {}) => async (dispatch) => {
  dispatch(fetchPodcastsEstimationsInit());

  const { podcasts: podcastsIds } = args;

  try {
    const estimationsPodcasts = await fetchPodcastsEstimationsIdService(
      podcastsIds
    );

    return dispatch(fetchPodcastsEstimationsSuccess({ estimationsPodcasts }));
  } catch (error) {
    return dispatch(fetchPodcastsEstimationsFail({ error: error.message }));
  }
};

export const clearEstimationsPodcastsState = () => (dispatch) => {
  dispatch(clearEstimationsPodcasts());
};
