import Application from 'pages/Application';
import Login from 'pages/Login';
import SignUp from 'pages/SignUp';
import ResetPassword from 'pages/Password/ResetPassword';
import NewPassword from 'pages/Password/NewPassword';
import Path from 'enums/path.enum';

const loginRoutes = [
  {
    component: Login,
    hasCondition: true,
    path: Path.Login,
  },
  {
    component: SignUp,
    hasCondition: true,
    path: Path.SignUp,
  },
  {
    component: Application,
    hasCondition: false,
    path: Path.ThanksForApplying,
  },
  {
    component: ResetPassword,
    hasCondition: true,
    path: Path.ResetPassword,
  },
  {
    component: NewPassword,
    hasCondition: true,
    path: Path.NewPassword,
  },
];

export default loginRoutes;
