import React, { useCallback } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
} from 'components/Typography/Body';
import Link from 'components/Typography/Link';
import SideBarItems from 'enums/sideBar/items.enum';

import { logout } from 'state/actions/auth';
import classNames from 'classnames';
import classes from './Item.module.scss';

const Item = ({ title, to, selected }) => {
  const dispatch = useDispatch();

  const onClickLogoutHandler = useCallback((event) => {
    if (title === SideBarItems.LOGOUT) {
      event.preventDefault();
      dispatch(logout());
    }
  }, []);

  return (
    <Link to={to} onClick={onClickLogoutHandler} underline={false}>
      <div className={classes.item}>
        {selected && <div className={classes.selected} />}
        <Body
          size={BodySize.S}
          color={BodyColor.Black}
          className={classNames({ [classes.body]: selected })}
        >
          {title}
        </Body>
      </div>
    </Link>
  );
};

Item.propTypes = {
  title: PropTypes.string.isRequired,
  to: PropTypes.string.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default Item;
