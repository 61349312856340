import { createSelector } from '@reduxjs/toolkit';

const selectCampaignState = (state) => state.campaigns;

export const selectCreateCampaignState = createSelector(
  selectCampaignState,
  ({ createCampaignLoading, createCampaignSuccess, createCampaignError }) => ({
    loading: createCampaignLoading,
    success: createCampaignSuccess,
    error: createCampaignError,
  })
);

export const selectDeleteCampaignState = createSelector(
  selectCampaignState,
  ({ deleteCampaignLoading, deleteCampaignSuccess, deleteCampaignError }) => ({
    loading: deleteCampaignLoading,
    success: deleteCampaignSuccess,
    error: deleteCampaignError,
  })
);

export const selectEditCampaignState = createSelector(
  selectCampaignState,
  ({ editCampaignLoading, editCampaignSuccess, editCampaignError }) => ({
    loading: editCampaignLoading,
    success: editCampaignSuccess,
    error: editCampaignError,
  })
);

export const selectFetchUnfinishedCampaignState = createSelector(
  selectCampaignState,
  ({
    fetchUnfinishedCampaignLoading,
    fetchUnfinishedCampaignSuccess,
    fetchUnfinishedCampaignError,
    unfinishedCampaign,
  }) => ({
    loading: fetchUnfinishedCampaignLoading,
    success: fetchUnfinishedCampaignSuccess,
    error: fetchUnfinishedCampaignError,
    campaign: unfinishedCampaign,
  })
);

export const selectDownloadCampaignOffersState = createSelector(
  selectCampaignState,
  ({
    downloadCampaignOffersLoading,
    downloadCampaignOffersSuccess,
    downloadCampaignOffersError,
  }) => ({
    loading: downloadCampaignOffersLoading,
    success: downloadCampaignOffersSuccess,
    error: downloadCampaignOffersError,
  })
);

export const selectAddOffersState = createSelector(
  selectCampaignState,
  ({ addOffersLoading, addOffersSuccess, addOffersError }) => ({
    loading: addOffersLoading,
    success: addOffersSuccess,
    error: addOffersError,
  })
);

export const selectFinishedCampaignState = createSelector(
  selectCampaignState,
  ({
    finishedCampaignLoading,
    finishedCampaignSuccess,
    finishedCampaignError,
  }) => ({
    loading: finishedCampaignLoading,
    success: finishedCampaignSuccess,
    error: finishedCampaignError,
  })
);

export const selectFetchAllPendingCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchAllPendingCampaignsLoading,
    fetchAllPendingCampaignsSuccess,
    fetchAllPendingCampaignsError,
    pendingCampaigns,
    lastDocPendingCampaigns,
  }) => ({
    loading: fetchAllPendingCampaignsLoading,
    success: fetchAllPendingCampaignsSuccess,
    error: fetchAllPendingCampaignsError,
    campaigns: pendingCampaigns,
    lastDoc: lastDocPendingCampaigns,
  })
);

export const selectFetchAllCurrentCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchAllCurrentCampaignsLoading,
    fetchAllCurrentCampaignsSuccess,
    fetchAllCurrentCampaignsError,
    currentCampaigns,
    lastDocCurrentCampaigns,
  }) => ({
    loading: fetchAllCurrentCampaignsLoading,
    success: fetchAllCurrentCampaignsSuccess,
    error: fetchAllCurrentCampaignsError,
    campaigns: currentCampaigns,
    lastDoc: lastDocCurrentCampaigns,
  })
);

export const selectFetchAllFinishedCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchAllFinishedCampaignsLoading,
    fetchAllFinishedCampaignsSuccess,
    fetchAllFinishedCampaignsError,
    finishedCampaigns,
    lastDocFinishedCampaigns,
  }) => ({
    loading: fetchAllFinishedCampaignsLoading,
    success: fetchAllFinishedCampaignsSuccess,
    error: fetchAllFinishedCampaignsError,
    campaigns: finishedCampaigns,
    lastDoc: lastDocFinishedCampaigns,
  })
);

export const selectFetchExistingCampaignsState = createSelector(
  selectCampaignState,
  ({
    fetchExistingCampaignsLoading,
    fetchExistingCampaignsSuccess,
    fetchExistingCampaignsError,
    existingCampaigns,
  }) => ({
    loading: fetchExistingCampaignsLoading,
    success: fetchExistingCampaignsSuccess,
    error: fetchExistingCampaignsError,
    campaigns: existingCampaigns,
  })
);

export const selectFetchCampaignByIdState = createSelector(
  selectCampaignState,
  ({
    fetchCampaignByIdLoading,
    fetchCampaignByIdSuccess,
    fetchCampaignByIdError,
    campaign,
  }) => ({
    loading: fetchCampaignByIdLoading,
    success: fetchCampaignByIdSuccess,
    error: fetchCampaignByIdError,
    campaign,
  })
);

export const selectFetchCampaignsPendingOffersState = createSelector(
  selectCampaignState,
  ({
    fetchCampaignsPendingOffersLoading,
    fetchCampaignsPendingOffersSuccess,
    fetchCampaignsPendingOffersError,
    pendingOffers,
  }) => ({
    loading: fetchCampaignsPendingOffersLoading,
    success: fetchCampaignsPendingOffersSuccess,
    error: fetchCampaignsPendingOffersError,
    pendingOffers,
  })
);

export const selectSendAdCopyState = createSelector(
  selectCampaignState,
  ({ sendAdCopyLoading, sendAdCopySuccess, sendAdCopyError }) => ({
    loading: sendAdCopyLoading,
    success: sendAdCopySuccess,
    error: sendAdCopyError,
  })
);

export const selectSendTalkingPointsState = createSelector(
  selectCampaignState,
  ({
    editTalkingPointsLoading,
    editTalkingPointsSuccess,
    editTalkingPointsError,
  }) => ({
    loading: editTalkingPointsLoading,
    success: editTalkingPointsSuccess,
    error: editTalkingPointsError,
  })
);

export const selectClearTalkingPointsState = createSelector(
  selectCampaignState,
  ({ clearTalkingPointsLoading, clearTalkingPointsSuccess }) => ({
    loading: clearTalkingPointsLoading,
    success: clearTalkingPointsSuccess,
  })
);

export const selectFetchCampaignCategoriesState = createSelector(
  selectCampaignState,
  ({
    fetchCampaignCategoriesLoading,
    fetchCampaignCategoriesSuccess,
    fetchCampaignCategoriesError,
    categories,
  }) => ({
    loading: fetchCampaignCategoriesLoading,
    success: fetchCampaignCategoriesSuccess,
    error: fetchCampaignCategoriesError,
    categories,
  })
);

export const selectFetchCampaignsPodcastsDataState = createSelector(
  selectCampaignState,
  ({
    fetchCampaignsPodcastsDataLoading,
    fetchCampaignsPodcastsDataSuccess,
    fetchCampaignsPodcastsDataError,
    podcastsData,
  }) => ({
    loading: fetchCampaignsPodcastsDataLoading,
    success: fetchCampaignsPodcastsDataSuccess,
    error: fetchCampaignsPodcastsDataError,
    podcastsData,
  })
);

export const selectFetchEstimationsInCampaignState = createSelector(
  selectCampaignState,
  ({
    fetchEstimationsInCampaignLoading,
    fetchEstimationsInCampaignSuccess,
    fetchEstimationsInCampaignError,
    estimationsSummary,
  }) => ({
    loading: fetchEstimationsInCampaignLoading,
    success: fetchEstimationsInCampaignSuccess,
    error: fetchEstimationsInCampaignError,
    estimationsSummary,
  })
);

export const selectCampaignsTabState = createSelector(
  selectCampaignState,
  ({ campaignsTab }) => ({ campaignsTab })
);
