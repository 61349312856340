import GenderIcon from 'assets/icons/gender.png';
import AgeIcon from 'assets/icons/age.png';
import EthnicityIcon from 'assets/icons/diversity.png';
import IncomeIcon from 'assets/icons/income.png';
import AudienceIcon from 'assets/icons/audience.png';
import AdTypesIcon from 'assets/icons/ad-types.png';
import MaritalStatusIcon from 'assets/icons/marital-status.png';
import CountryIcon from 'assets/icons/country.png';
import EpisodesPerMonthIcon from 'assets/icons/release-period.png';
import HostingIcon from 'assets/icons/hosting.png';
import HostingCapabilitiesIcon from 'assets/icons/hosting-capabilities.png';
import React from 'react';
import { getName } from 'country-list';
import Body, { Size as BodySize } from 'components/Typography/Body';
import incomeOptions from 'utils/Campaign/incomeOptions';
import { ageOptions } from 'utils/Campaign/ageOptions';
import ethnicityOptions from 'utils/Campaign/ethnicityOptions';
import maritalStatusOptions from 'utils/Campaign/maritalStatusOptions';
import adFormats from 'utils/Campaign/adFormats';
import adTypeInputs from 'utils/inputs/addTypeInputs';

const getCampaignFieldsData = (podcast, hostingProviders) => [
  {
    label: 'Gender',
    Icon: GenderIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        Male: {podcast.audienceInformation.gender?.male || '0'} % <br />
        Female: {podcast.audienceInformation.gender?.female || '0'} %
      </Body>
    ),
  },

  {
    label: 'Monthly Download',
    Icon: AudienceIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.episodesPerMonth}
      </Body>
    ),
  },

  {
    label: 'Age Ranges',
    Icon: AgeIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.audienceInformation.ageRanges?.map((ageValue) => (
          <Body {...props} size={BodySize.XS}>
            {ageOptions.find((ageOption) => ageValue === ageOption.value)
              ?.label || ''}
          </Body>
        ))}
      </Body>
    ),
  },
  {
    label: 'Ad Formats',
    Icon: AdTypesIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast.adFormats?.map((adFormat) => (
          <Body {...props} size={BodySize.XS}>
            {adFormats.find((adFormatType) => adFormat === adFormatType.value)
              ?.label || ''}
          </Body>
        ))}
      </Body>
    ),
  },

  {
    label: 'Ethnicities',
    Icon: EthnicityIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.audienceInformation.ethnicities?.map((ethnicityValue) => (
          <Body {...props} size={BodySize.XS}>
            {ethnicityOptions.find(
              (ethnicityOption) => ethnicityValue === ethnicityOption.value
            )?.label || ''}
          </Body>
        ))}
      </Body>
    ),
  },
  {
    label: 'Ad Types',
    Icon: AdTypesIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.adPlacements?.map((adType) => (
          <Body {...props} size={BodySize.XS}>
            {adTypeInputs.find((adTypeOption) => adType === adTypeOption.type)
              ?.label || ''}
          </Body>
        ))}
      </Body>
    ),
  },

  {
    label: 'Marital Status',
    Icon: MaritalStatusIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.audienceInformation.maritalStatuses?.length
          ? podcast.audienceInformation.maritalStatuses.map((maritalStatus) => (
              <Body {...props} size={BodySize.XS}>
                {maritalStatusOptions.find(
                  (option) => maritalStatus === option.value
                )?.label || ''}
              </Body>
            ))
          : '-'}
      </Body>
    ),
  },
  {
    label: 'Episodes Per Month',
    Icon: EpisodesPerMonthIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast.episodesPerMonth}
      </Body>
    ),
  },

  {
    label: 'Income',
    Icon: IncomeIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast?.audienceInformation.incomeRanges?.length
          ? podcast.audienceInformation.incomeRanges.map((income) => (
              <Body {...props} size={BodySize.XS}>
                {incomeOptions.find(
                  (incomeOption) => income === incomeOption.value
                )?.label || ''}
              </Body>
            ))
          : '-'}
      </Body>
    ),
  },

  {
    label: 'Average Monthly Audience',
    Icon: AudienceIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast.audienceEstimate || '0'}
      </Body>
    ),
  },
  {
    label: 'Hosting',
    Icon: HostingIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {podcast.hostingProvider}
      </Body>
    ),
  },
  {
    label: 'Country',
    Icon: CountryIcon,
    component: (props) => (
      <Body {...props} size={BodySize.XS}>
        {getName(podcast?.country) || ''}
      </Body>
    ),
  },
  {
    label: 'Hosting Capabilities',
    showComponent:
      hostingProviders
        .find((elem) => elem.name === podcast?.hostingProvider)
        ?.capabilities.filter(({ enabled }) => enabled).length > 0,
    Icon: HostingCapabilitiesIcon,
    component: (props) =>
      hostingProviders
        .find((elem) => elem.name === podcast?.hostingProvider)
        ?.capabilities.filter(({ enabled }) => enabled)
        .map(
          ({ name, enabled, observations }) =>
            enabled && (
              <Body {...props} size={BodySize.XS}>
                {observations.length > 0 ? `${name}:${observations}` : name}
              </Body>
            )
        ),
  },
];

export default getCampaignFieldsData;
