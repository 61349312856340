import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { getCampaigAdFormatsInputs } from 'utils/inputs/createCampaignInputs';
import { selectFetchUnfinishedCampaignState } from 'state/selectors/campaigns';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import classes from './CampaignAdFormatsForm.module.scss';

const CampaignAdFormatsForm = () => {
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const [adFormat, setAdFormat] = useState([]);

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const defaultData = formsData[currentStep] ?? campaign;

  const onChange = (data) => {
    setAdFormat(data);
  };

  const campaignAdFormatsFields = useMemo(
    () =>
      getCampaigAdFormatsInputs({
        defaultValues: defaultData,
        onChangeDo: onChange,
      }),
    [defaultData, onChange]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Now, choose the ad format.
          <br />
        </Body>
        <div className={classes.fields}>
          {campaignAdFormatsFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={adFormat.length !== 1}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignAdFormatsForm;
