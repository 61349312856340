import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import NewPassword from 'components/Pages/Password/NewPassword';
import NewPasswordConfirmation from 'components/Pages/Password/NewPasswordConfirmation';
import { selectNewPasswordState } from 'state/selectors/auth';

const NewPasswordPage = () => {
  const { success } = useSelector(selectNewPasswordState, shallowEqual);

  return (
    <>
      {!success && <NewPassword />}
      {success && <NewPasswordConfirmation />}
    </>
  );
};

export default NewPasswordPage;
