import React from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import IconButton from 'components/Common/IconButton';
import checkIcon from 'assets/icons/check-white.svg';

import classes from './PodcastItem.module.scss';

const PodcastItem = ({ podcast, selected, onSelect, onClickLearnMore }) => (
  <div className={classes.container}>
    <IconButton onClick={onSelect} className={classes.iconButton}>
      {selected && <div className={classes.overlay} data-testid="overlay" />}
      <div className={classes.content}>
        {selected && (
          <img
            src={checkIcon}
            alt="selected"
            className={classes.selectedIcon}
          />
        )}
        <div className={classes.image}>
          <img
            src={podcast.podcastData?.imageUrl}
            alt={podcast.podcastName}
            className={classes.logo}
          />
        </div>
        <Body size={BodySize.XS} className={classes.title}>
          {podcast.podcastName || 'Podcast'}
        </Body>
        <Body size={BodySize.XS} className={classes.subtitle}>
          {podcast.podcastData?.description || 'The podcast has no description'}
        </Body>
      </div>
    </IconButton>
    <Button
      className={classes.learnMoreButton}
      kind={ButtonKind.Secondary}
      size={ButtonSize.S}
      onClick={onClickLearnMore}
    >
      Learn more
    </Button>
  </div>
);

PodcastItem.propTypes = {
  podcast: PropTypes.shape({
    podcastId: PropTypes.string,
    podcastName: PropTypes.string,
    podcastData: PropTypes.shape({
      description: PropTypes.string,
      imageUrl: PropTypes.string,
    }),
  }).isRequired,
  onClickLearnMore: PropTypes.func.isRequired,
  onSelect: PropTypes.func.isRequired,
  selected: PropTypes.bool.isRequired,
};

export default PodcastItem;
