import React from 'react';
import { useSelector, shallowEqual } from 'react-redux';

import ResetPassword from 'components/Pages/Password/ResetPassword';
import { selectResetPasswordState } from 'state/selectors/auth';
import ResetPasswordConfirmation from 'components/Pages/Password/ResetPasswordConfirmation';

const ResetPasswordPage = () => {
  const { success } = useSelector(selectResetPasswordState, shallowEqual);

  return (
    <>
      {!success && <ResetPassword />}
      {success && <ResetPasswordConfirmation />}
    </>
  );
};

export default ResetPasswordPage;
