import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './History.module.scss';

const History = ({ podcast, index }) => (
  <div
    className={classNames(classes.container, {
      [classes.hasBorder]: index > 0,
    })}
  >
    <img src={podcast.imageUrl} alt="logo" className={classes.image} />
    <div className={classes.info}>
      <Body size={BodySize.XS} className={classNames(classes.title)}>
        {podcast.title}
      </Body>
      <div className={classes.campaign}>
        <Body size={BodySize.XS} className={classes.text}>
          Campaigns Showed
        </Body>
        <Body size={BodySize.S} className={classNames(classes.campaignName)}>
          {podcast.campaigns}
        </Body>
      </div>
    </div>
  </div>
);

History.propTypes = {
  podcast: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    campaigns: PropTypes.string,
  }).isRequired,
  index: PropTypes.number.isRequired,
};

export default History;
