import { HAS_CAMPAIGNS } from 'constants/campaign';
import Path from 'enums/path.enum';
import Campaigns from 'pages/Campaigns';
import CampaignSummary from 'pages/NewCampaign/CampaignSummary';
import CreateCampaign from 'pages/NewCampaign/CreateCampaign';
import SelectPodcast from 'pages/NewCampaign/SelectPodcast';
import SubmittedCampaign from 'pages/NewCampaign/SubmittedCampaign';
import Settings from 'pages/Settings';

const authenticatedRoutes = [
  {
    component: CreateCampaign,
    path: Path.NewCampaign,
  },
  {
    component: Campaigns,
    path: Path.Campaigns,
    condition: HAS_CAMPAIGNS,
    redirectTo: Path.NewCampaign,
  },
  {
    component: SelectPodcast,
    path: Path.CampaignPodcasts,
  },
  {
    component: CampaignSummary,
    path: Path.CampaignSummary,
  },
  {
    component: SubmittedCampaign,
    path: Path.SubmittedCampaign,
  },
  {
    component: Settings,
    path: Path.Settings,
  },
];

export default authenticatedRoutes;
