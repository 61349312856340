import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy,
} from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const fetchEnabledUsers = async ({ lastItemPagination, first }) => {
  try {
    const userRef = collection(firestore, Collections.PodcastUsers);
    const enabledUsersQuery = query(
      userRef,
      where('enabled', '==', true),
      orderBy('createdAt'),
      limit(first),
      startAfter(lastItemPagination)
    );
    const enabledUsersSnapshot = await getDocs(enabledUsersQuery);

    let lastUser = null;

    const enabledUsers = [];
    enabledUsersSnapshot.forEach((doc) => {
      const user = doc.data();
      if (user.podcastId && user.podcastData) {
        enabledUsers.push(user);
        lastUser = doc;
      }
    });

    return {
      enabledUsers,
      lastUser,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchEnabledUsers;
