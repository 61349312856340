import React from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Tooltip.module.scss';

const Tooltip = ({ component: Component, children, className }) => (
  <div className={classes.container}>
    <div className={classes.component}>
      <Component />
    </div>
    <div className={classNames(classes.tooltip, className)}>{children}</div>
  </div>
);

Tooltip.propTypes = {
  children: PropTypes.node.isRequired,
  component: PropTypes.func.isRequired,
  className: PropTypes.string,
};

Tooltip.defaultProps = {
  className: '',
};

export default Tooltip;
