const formatNumberWithSuffix = (number) => {
  if (number > 999 && number < 1000000) {
    return `${Math.sign(number) * (Math.abs(number) / 1000).toFixed(1)}K`; // convert to K for number from > 1000 < 1 million
  }

  if (number >= 1000000) {
    return `${Math.sign(number) * (Math.abs(number) / 1000000).toFixed(1)}M`; // convert to M for number from > 1 million
  }

  return number; // if value < 1000, nothing to do
};

export default formatNumberWithSuffix;
