import { query, where, getDocs, collection } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';
import { MAXIMUM_FIREBASE_IN } from 'constants/firebase';

const fetchCampaignsPodcastsData = async (podcastsIds) => {
  const offset = MAXIMUM_FIREBASE_IN;
  const slices = [];

  for (let index = 0; index <= podcastsIds.length; index += offset) {
    slices.push(podcastsIds.slice(index, index + offset));
  }

  const promises = [];

  slices.forEach((dataSets) => {
    if (dataSets.length) {
      const podcastsQuery = query(
        collection(firestore, Collections.PodcastUsers),
        where('podcastData.podcastId', 'in', dataSets)
      );

      promises.push(getDocs(podcastsQuery));
    }
  });

  const snapshots = await Promise.all(promises);

  const podcastsSnapshot = snapshots.flatMap((snapshot) => snapshot.docs);

  const podcastsData = podcastsSnapshot.map((doc) => doc.data());

  const returnPodcastsData = {};

  podcastsData.forEach((podcast) => {
    const ethnicities =
      podcast?.podcastData?.demographics?.ethnicities?.map(
        (ethnicity) => ethnicity?.title
      ) || [];

    const podcastToReturn = {
      ...podcast,
      ...podcast?.podcastData,
      ...podcast?.podcastData?.demographics,
      ethnicities: ethnicities.toString(),
      mailingAddress: podcast?.mailingAddress,
    };

    returnPodcastsData[podcast?.podcastData?.podcastId] = podcastToReturn;
  });

  return returnPodcastsData;
};

export default fetchCampaignsPodcastsData;
