import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Weight as BodyWeight,
} from 'components/Typography/Body';
import DatePicker from 'components/Common/DatePicker';

import classes from './PodcastCalendarModal.module.scss';
import './PodcastCalendarModal.scss';

const PodcastCalendarModal = ({ podcast, airingFrom, airingTo }) => {
  const { podcastName, podcastImageUrl, publishingDates, releaseDays } =
    podcast || {};

  const isLastReleaseDay = (index) => index === releaseDays.length - 2;

  const isNotLastReleaseDay = (index) => index < releaseDays.length - 2;

  return (
    <div className={classes.descriptionContainer}>
      <Body
        size={BodySize.XS}
        weight={BodyWeight.Bold}
        className={classes.podcastName}
      >
        {podcastName}
      </Body>
      <img src={podcastImageUrl} alt="logo" className={classes.image} />
      <div className={classes.modalInformation}>
        <Body size={BodySize.S} className={classes.modalInformationTitle}>
          PUBLISHING DATES
        </Body>
        {releaseDays?.length > 0 && (
          <Body size={BodySize.XS} className={classes.modalInformationSubtitle}>
            This podcast said they usually publish on&nbsp;
            {releaseDays.map((date, index) => (
              <span>
                <span className={classes.releaseDays}>{date}</span>
                {isNotLastReleaseDay(index) ? ', ' : ''}
                {isLastReleaseDay(index) ? ' and ' : ''}
              </span>
            ))}
            .
          </Body>
        )}
        <Body size={BodySize.XS} className={classes.modalInformationSubtitle}>
          Check the calendar below to see the specific dates the campaign will
          be published.
        </Body>
      </div>
      <div className="releaseDatesContainer">
        <DatePicker
          inline
          highlightDates={publishingDates}
          openToDate={publishingDates?.length ? publishingDates[0] : new Date()}
          formatWeekDay={(nameOfDay) => nameOfDay.substr(0, 3)}
          minDate={airingFrom}
          maxDate={airingTo}
        />
      </div>
    </div>
  );
};

export default PodcastCalendarModal;

PodcastCalendarModal.propTypes = {
  podcast: PropTypes.shape({
    podcastId: PropTypes.string,
    podcastName: PropTypes.string,
    podcastImageUrl: PropTypes.string,
    releaseDays: PropTypes.arrayOf(PropTypes.string),
  }),
  airingTo: PropTypes.instanceOf(Date),
  airingFrom: PropTypes.instanceOf(Date),
};

PodcastCalendarModal.defaultProps = {
  podcast: null,
  airingTo: null,
  airingFrom: null,
};
