import { collection, query, where, getDocs } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';

const fetchUnfinishedCampaign = async ({ brandId }) => {
  try {
    const campaignRef = collection(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns
    );
    const unfinishedCampaignQuery = query(
      campaignRef,
      where('finishedCreatingCampaign', '==', false)
    );
    const unfinishedCampaignSapshot = await getDocs(unfinishedCampaignQuery);

    let unfinishedCampaign = null;
    unfinishedCampaignSapshot.forEach((doc) => {
      unfinishedCampaign = { ...doc.data(), uid: doc.id };
    });

    return unfinishedCampaign;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchUnfinishedCampaign;
