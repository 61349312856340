import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPodcastsInit,
  fetchPodcastsSuccess,
  fetchPodcastsFail,
  fetchPodcastsEstimationsInit,
  fetchPodcastsEstimationsSuccess,
  fetchPodcastsEstimationsFail,
  clearEstimationsPodcasts,
} from 'state/actionCreators/podcasts';

export const initialState = {
  fetchPodcastsLoading: false,
  fetchPodcastsSuccess: false,
  fetchPodcastsError: null,
  podcasts: [],
  fetchEstimationPodcastsLoading: false,
  fetchEstimationPodcastsSuccess: false,
  fetchEstimationPodcastsError: null,
  estimationsPodcasts: null,
};

const podcastsReducer = createReducer(initialState, {
  [fetchPodcastsInit]: (state) => {
    state.fetchPodcastsLoading = true;
    state.fetchPodcastsSuccess = false;
    state.fetchPodcastsError = null;
    state.podcasts = [];
  },
  [fetchPodcastsSuccess]: (state, { payload }) => {
    const { podcasts } = payload;
    state.fetchPodcastsLoading = false;
    state.fetchPodcastsSuccess = true;
    state.fetchPodcastsError = null;
    state.podcasts = podcasts;
  },
  [fetchPodcastsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPodcastsLoading = false;
    state.fetchPodcastsSuccess = false;
    state.fetchPodcastsError = error;
    state.podcasts = [];
  },

  [fetchPodcastsEstimationsInit]: (state) => {
    state.fetchEstimationPodcastsLoading = true;
    state.fetchEstimationPodcastsSuccess = false;
    state.fetchEstimationPodcastsError = null;
    state.estimationsPodcasts = null;
  },
  [fetchPodcastsEstimationsSuccess]: (state, { payload }) => {
    const { estimationsPodcasts } = payload;
    state.fetchEstimationPodcastsLoading = false;
    state.fetchEstimationPodcastsSuccess = true;
    state.fetchEstimationPodcastsError = null;
    state.estimationsPodcasts = estimationsPodcasts;
  },
  [fetchPodcastsEstimationsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchEstimationPodcastsLoading = false;
    state.fetchEstimationPodcastsSuccess = false;
    state.fetchEstimationPodcastsError = error;
    state.estimationsPodcasts = null;
  },
  [clearEstimationsPodcasts]: (state) => {
    state.fetchEstimationPodcastsLoading = false;
    state.fetchEstimationPodcastsSuccess = false;
    state.fetchEstimationPodcastsError = null;
    state.estimationsPodcasts = null;
  },
});

export default podcastsReducer;
