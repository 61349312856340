import { useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Weight as BodyWeight,
} from 'components/Typography/Body';
import ExportCsvButton from 'components/Common/ExportCsvButton';
import { campaignHeaders } from 'utils/Campaign/csvExportHeaders';
import removeCamelCaseAndJoin from 'utils/functions/removeCamelCaseAndJoin';
import sentenceToSnakeCase from 'utils/functions/sentenceToSnakeCase';
import mapJsonEntries from 'utils/functions/mapJsonEntries';

import Button, {
  Kind as ButtonKind,
  Size as ButtonSize,
} from 'components/Common/Button';
import Toast, { Type as ToastType } from 'components/Common/Toast';

import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import {
  clearDownloadCampaignOffersState,
  downloadCampaignOffers,
} from 'state/actions/campaigns';
import { selectDownloadCampaignOffersState } from 'state/selectors/campaigns';
import classes from './ExportCampaignModal.module.scss';

const ExportCampaignModal = ({ campaign, offers, onClose }) => {
  const dispatch = useDispatch();
  const [campaignData, setCampaignData] = useState([]);
  const { success, error } = useSelector(
    selectDownloadCampaignOffersState,
    shallowEqual
  );

  useEffect(() => {
    if (campaign)
      setCampaignData([
        {
          ...campaign,
          offersLength: offers.length,
          categories: mapJsonEntries(campaign.categories),
          countries: removeCamelCaseAndJoin(campaign.countries),
          age: removeCamelCaseAndJoin(campaign.age),
          adFormats: removeCamelCaseAndJoin(campaign.adFormats),
          adPlacements: removeCamelCaseAndJoin(campaign.adPlacements),
          gender: mapJsonEntries(campaign.gender),
          percentages: mapJsonEntries(campaign.percentages),
        },
      ]);
  }, [campaign]);

  const instructionText =
    offers?.length > 0
      ? 'You can download the campaign and/or offers data as a .CSV file.'
      : 'You can download the campaign data as a .CSV file.';

  const onDownloadOffersHandler = () => {
    dispatch(downloadCampaignOffers(campaign.uid));
    onClose();
  };

  useEffect(() => {
    if (success || error) {
      dispatch(clearDownloadCampaignOffersState());
    }
  }, [success, error]);

  return (
    <>
      {success && (
        <Toast
          text="We are preparing the CSV file and will send it to your email shortly."
          id="Preparing CSV file"
          type={ToastType.Success}
        />
      )}
      {error && (
        <Toast
          text="There was an error preparing the CSV file. Please try again later."
          id="Error preparing CSV file"
          type={ToastType.Error}
        />
      )}
      <Body size={BodySize.M} weight={BodyWeight.Bold}>
        Export Campaign Information
      </Body>
      <Body size={BodySize.S} className={classes.subtext}>
        {instructionText}
      </Body>
      <div className={classes.buttonList}>
        {campaign && (
          <ExportCsvButton
            headers={campaignHeaders}
            data={campaignData}
            title="Download Campaign Data"
            filename={`${sentenceToSnakeCase(campaign.name)}-data.csv`}
          />
        )}
        {offers?.length > 0 && (
          <Button
            kind={ButtonKind.Secondary}
            size={ButtonSize.S}
            onClick={onDownloadOffersHandler}
          >
            Download Campaign Offers Data
          </Button>
        )}
      </div>
    </>
  );
};

ExportCampaignModal.propTypes = {
  campaign: PropTypes.shape({
    uid: PropTypes.string,
    name: PropTypes.string.isRequired,
    categories: PropTypes.shape({}),
    countries: PropTypes.arrayOf(PropTypes.string).isRequired,
    age: PropTypes.arrayOf(PropTypes.string).isRequired,
    adFormats: PropTypes.arrayOf(PropTypes.string).isRequired,
    adPlacements: PropTypes.arrayOf(PropTypes.string).isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    gender: PropTypes.shape({}),
    percentages: PropTypes.shape({}),
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }),
  onClose: PropTypes.func.isRequired,
  offers: PropTypes.arrayOf(
    PropTypes.shape({
      imageUrl: PropTypes.string,
      podcastImageUrl: PropTypes.string,
      title: PropTypes.string,
      budget: PropTypes.number,
      adTypes: PropTypes.arrayOf(PropTypes.string),
      offerStatus: PropTypes.string,
    })
  ).isRequired,
};
ExportCampaignModal.defaultProps = {
  campaign: null,
};
export default ExportCampaignModal;
