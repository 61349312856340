const splitArray = (array, offset) => {
  const arrayContainers = [];

  if (array.length <= offset) {
    return [array];
  }

  const numberOfContainers = Math.ceil(array.length / offset);

  for (let i = 0; i < numberOfContainers; i += 1) {
    const container = array.slice(offset * i, offset * (i + 1));
    arrayContainers.push(container);
  }

  return arrayContainers;
};

export default splitArray;
