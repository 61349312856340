import { firestore } from 'firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const editTalkingPoints = async ({
  brandId,
  campaign,
  podcastId,
  talkingPoints,
  addToAll,
}) => {
  try {
    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaign.uid
    );
    const newOffers = campaign.offers.map((offer) => {
      if (addToAll || offer.podcastId === podcastId) {
        return { ...offer, talkingPoints };
      }
      return offer;
    });

    await updateDoc(campaignRef, {
      offers: newOffers,
      talkingPoints: addToAll ? talkingPoints : campaign.talkingPoints,
    });
  } catch (error) {
    throw new Error(error);
  }
};

export default editTalkingPoints;
