import * as yup from 'yup';

import extractTextFromHTML from 'utils/schemas/extractTextFromHTML';
import Required from 'enums/schemas/required.enum';

const CampaignDescriptionFormSchema = yup.object().shape({
  description: yup
    .string()
    .nullable()
    .required(Required.Description)
    .test({
      name: 'is-valid-campaign-description',
      test: (value) => extractTextFromHTML(value).trim() !== '',
      message: Required.Description,
    }),
});

export default CampaignDescriptionFormSchema;
