const sliderStyles = {
  trackStyle: { backgroundColor: '#ebebeb', height: 14, borderRadius: 100 },
  handleStyle: {
    borderColor: '#645aff',
    height: 20,
    width: 20,
    backgroundColor: '#645aff',
    marginTop: -3,
  },
  railStyle: { backgroundColor: '#ebebeb', height: 14, borderRadius: 100 },
  dotStyle: {
    height: 20,
    bottom: -8,
    width: 1,
    borderRadius: 0,
    backgroundColor: '#645aff',
    borderColor: '#645aff',
    border: 0,
  },
  activeDotStyle: {
    height: 20,
    width: 1,
    borderRadius: 0,
    backgroundColor: '#645aff',
    borderColor: '#645aff',
  },
};

export default sliderStyles;
