import editUserService from 'services/auth/editUser';

const editUserInformation = async ({ user, logo, userId }) => {
  if (Object.keys(user).length > 0) {
    try {
      const newUserData = { ...user };

      if (logo) {
        newUserData.logo = logo;
      }
      delete newUserData.password;

      await editUserService(newUserData, userId);
    } catch (error) {
      let errorMessage = '';

      if (logo) {
        errorMessage = errorMessage.concat(
          'The logo was successfully updated. '
        );
      }

      errorMessage = errorMessage.concat(
        'There was an error updating the user fields'
      );

      if (user.password) {
        errorMessage = errorMessage.concat(' and the password.');
      }

      throw errorMessage;
    }
  }
};

export default editUserInformation;
