import uploadLogoService from 'services/auth/uploadLogo';

const uploadNewLogo = async ({ logo, email }) => {
  let logoPath = '';
  if (logo) {
    logoPath = await uploadLogoService({ logo, email });
  }
  return logoPath;
};

export default uploadNewLogo;
