import { createReducer } from '@reduxjs/toolkit';

import {
  createCampaignInit,
  createCampaignSuccess,
  createCampaignFail,
  editCampaignInit,
  editCampaignSuccess,
  editCampaignFail,
  fetchUnfinishedCampaignInit,
  fetchUnfinishedCampaignSuccess,
  fetchUnfinishedCampaignFail,
  downloadCampaignOffersInit,
  downloadCampaignOffersSuccess,
  downloadCampaignOffersFail,
  clearDownloadCampaignOffers,
  addOffersInit,
  addOffersSuccess,
  addOffersFail,
  finishedCampaignInit,
  finishedCampaignSuccess,
  finishedCampaignFail,
  fetchAllPendingCampaignsInit,
  fetchAllPendingCampaignsSuccess,
  fetchAllPendingCampaignsFail,
  fetchAllCurrentCampaignsInit,
  fetchAllCurrentCampaignsSuccess,
  fetchAllCurrentCampaignsFail,
  fetchAllFinishedCampaignsInit,
  fetchAllFinishedCampaignsSuccess,
  fetchAllFinishedCampaignsFail,
  fetchExistingCampaignsInit,
  fetchExistingCampaignsSuccess,
  fetchExistingCampaignsFail,
  fetchCampaignByIdInit,
  fetchCampaignByIdSuccess,
  fetchCampaignByIdFail,
  sendAdCopyInit,
  sendAdCopySuccess,
  sendAdCopyFail,
  editTalkingPointsInit,
  editTalkingPointsSuccess,
  editTalkingPointsFail,
  clearCampaign,
  fetchCampaignCategoriesInit,
  fetchCampaignCategoriesSuccess,
  fetchCampaignCategoriesFail,
  fetchCampaignsPendingOffersInit,
  fetchCampaignsPendingOffersSuccess,
  fetchCampaignsPendingOffersFail,
  fetchCampaignsPodcastsDataInit,
  fetchCampaignsPodcastsDataSuccess,
  fetchCampaignsPodcastsDataFail,
  updateCampaignsTab,
  fetchNewCampaignEstimationsInit,
  fetchNewCampaignEstimationsSuccess,
  fetchNewCampaignEstimationsFail,
  clearEstimationsFilters,
  deleteCampaignInit,
  deleteCampaignSuccess,
  deleteCampaignFail,
  clearTalkingPoints,
} from 'state/actionCreators/campaigns';

export const initialState = {
  createCampaignLoading: false,
  createCampaignSuccess: false,
  createCampaignError: null,
  deleteCampaignLoading: false,
  deleteCampaignSuccess: false,
  deleteCampaignError: null,
  editCampaignLoading: false,
  editCampaignSuccess: false,
  editCampaignError: null,
  fetchUnfinishedCampaignLoading: false,
  fetchUnfinishedCampaignSuccess: false,
  fetchUnfinishedCampaignError: null,
  downloadCampaignOffersLoading: false,
  downloadCampaignOffersSuccess: false,
  downloadCampaignOffersError: null,
  addOffersLoading: false,
  addOffersSuccess: false,
  addOffersError: null,
  finishedCampaignLoading: false,
  finishedCampaignSuccess: false,
  finishedCampaignError: null,
  fetchAllPendingCampaignsLoading: false,
  fetchAllPendingCampaignsSuccess: false,
  fetchAllPendingCampaignsFail: null,
  fetchAllCurrentCampaignsLoading: false,
  fetchAllCurrentCampaignsSuccess: false,
  fetchAllCurrentCampaignsError: null,
  fetchAllFinishedCampaignsLoading: false,
  fetchAllFinishedCampaignsSuccess: false,
  fetchAllFinishedCampaignsError: null,
  fetchExistingCampaignsLoading: false,
  fetchExistingCampaignsSuccess: false,
  fetchExistingCampaignsError: null,
  fetchCampaignByIdLoading: false,
  fetchCampaignByIdSuccess: false,
  fetchCampaignByIdError: null,
  fetchCampaignsPodcastsDataLoading: false,
  fetchCampaignsPodcastsDataSuccess: false,
  fetchCampaignsPodcastsDataError: null,
  sendAdCopyLoading: false,
  sendAdCopySuccess: false,
  sendAdCopyError: null,
  editTalkingPointsLoading: false,
  editTalkingPointsSuccess: false,
  editTalkingPointsError: null,
  fetchCampaignCategoriesLoading: false,
  fetchCampaignCategoriesSuccess: false,
  fetchCampaignCategoriesError: null,
  fetchEstimationsInCampaignsLoading: false,
  fetchEstimationsInCampaignsSuccess: false,
  fetchEstimationsInCampaignsError: null,
  fetchCampaignsPendingOffersLoading: true,
  estimationsSummary: {
    potentialPodcasts: null,
    estimatedImpressions: null,
    allLocalPodcasts: null,
    podcasts: null,
    filters: {
      categories: null,
      gender: null,
      age: null,
      income: null,
      ethnicity: null,
      maritalStatus: null,
      countries: null,
      adPlacements: null,
    },
  },
  campaign: null,
  pendingOffers: null,
  unfinishedCampaign: null,
  podcastsData: null,
  pendingCampaigns: [],
  currentCampaigns: [],
  finishedCampaigns: [],
  lastDocPendingCampaigns: null,
  lastDocCurrentCampaigns: null,
  lastDocFinishedCampaigns: null,
  existingCampaigns: [],
  categories: [],
  campaignsTab: null,
};

const campaignsReducer = createReducer(initialState, {
  [createCampaignInit]: (state) => {
    state.createCampaignLoading = true;
    state.createCampaignSuccess = false;
    state.createCampaignError = null;
  },
  [createCampaignSuccess]: (state, { payload }) => {
    const { campaign } = payload;
    state.createCampaignLoading = false;
    state.createCampaignSuccess = true;
    state.createCampaignError = null;
    state.unfinishedCampaign = campaign;
  },
  [createCampaignFail]: (state, { payload }) => {
    const { error } = payload;
    state.createCampaignLoading = false;
    state.createCampaignSuccess = false;
    state.createCampaignError = error;
  },
  [deleteCampaignInit]: (state) => {
    state.deleteCampaignLoading = true;
    state.deleteCampaignSuccess = false;
    state.deleteCampaignError = null;
  },
  [deleteCampaignSuccess]: (state, { payload }) => {
    const { campaignId } = payload;
    state.deleteCampaignLoading = false;
    state.deleteCampaignSuccess = true;
    state.pendingCampaigns = state.pendingCampaigns.filter(
      (campaign) => campaign.uid !== campaignId
    );
    state.deleteCampaignError = null;
  },
  [deleteCampaignFail]: (state, { payload }) => {
    const { error } = payload;
    state.deleteCampaignLoading = false;
    state.deleteCampaignSuccess = false;
    state.deleteCampaignError = error;
  },
  [editCampaignInit]: (state) => {
    state.editCampaignLoading = true;
    state.editCampaignSuccess = false;
    state.editCampaignError = null;
  },
  [editCampaignSuccess]: (state) => {
    state.editCampaignLoading = false;
    state.editCampaignSuccess = true;
    state.editCampaignError = null;
  },
  [editCampaignFail]: (state, { payload }) => {
    const { error } = payload;
    state.editCampaignLoading = false;
    state.editCampaignSuccess = false;
    state.editCampaignError = error;
  },
  [fetchUnfinishedCampaignInit]: (state) => {
    state.fetchUnfinishedCampaignLoading = true;
    state.fetchUnfinishedCampaignSuccess = false;
    state.fetchUnfinishedCampaignError = null;
    state.addOffersSuccess = false;
  },
  [fetchUnfinishedCampaignSuccess]: (state, { payload }) => {
    const { campaign } = payload;
    state.fetchUnfinishedCampaignLoading = false;
    state.fetchUnfinishedCampaignSuccess = true;
    state.fetchUnfinishedCampaignError = null;
    state.unfinishedCampaign = campaign;
  },
  [fetchUnfinishedCampaignFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchUnfinishedCampaignLoading = false;
    state.fetchUnfinishedCampaignSuccess = false;
    state.fetchUnfinishedCampaignError = error;
  },
  [downloadCampaignOffersInit]: (state) => {
    state.downloadCampaignOffersLoading = true;
    state.downloadCampaignOffersSuccess = false;
    state.downloadCampaignOffersError = null;
  },
  [downloadCampaignOffersSuccess]: (state) => {
    state.downloadCampaignOffersLoading = false;
    state.downloadCampaignOffersSuccess = true;
    state.downloadCampaignOffersError = null;
  },
  [downloadCampaignOffersFail]: (state, { payload }) => {
    const { error } = payload;
    state.downloadCampaignOffersLoading = false;
    state.downloadCampaignOffersSuccess = false;
    state.downloadCampaignOffersError = error;
  },
  [clearDownloadCampaignOffers]: (state) => {
    state.downloadCampaignOffersLoading = false;
    state.downloadCampaignOffersSuccess = false;
    state.downloadCampaignOffersError = null;
  },
  [addOffersInit]: (state) => {
    state.addOffersLoading = true;
    state.addOffersSuccess = false;
    state.addOffersError = null;
  },
  [addOffersSuccess]: (state) => {
    state.addOffersLoading = false;
    state.addOffersSuccess = true;
    state.addOffersError = null;
    state.fetchCampaignByIdSuccess = false;
  },
  [addOffersFail]: (state, { payload }) => {
    const { error } = payload;
    state.addOffersLoading = false;
    state.addOffersSuccess = false;
    state.addOffersError = error;
  },
  [finishedCampaignInit]: (state) => {
    state.finishedCampaignLoading = true;
    state.finishedCampaignSuccess = false;
    state.finishedCampaignError = null;
  },
  [finishedCampaignSuccess]: (state) => {
    state.finishedCampaignLoading = false;
    state.finishedCampaignSuccess = true;
    state.finishedCampaignError = null;
  },
  [finishedCampaignFail]: (state, { payload }) => {
    const { error } = payload;
    state.finishedCampaignLoading = false;
    state.finishedCampaignSuccess = false;
    state.finishedCampaignError = error;
  },

  [fetchAllPendingCampaignsInit]: (state) => {
    state.fetchAllPendingCampaignsLoading = true;
    state.fetchAllPendingCampaignsSuccess = false;
    state.fetchAllPendingCampaignsError = null;
    state.pendingCampaigns = [];
    state.lastDocPendingCampaigns = null;
  },

  [fetchAllPendingCampaignsSuccess]: (state, { payload }) => {
    const { campaigns, lastDoc } = payload;
    state.fetchAllPendingCampaignsLoading = false;
    state.fetchAllPendingCampaignsSuccess = true;
    state.fetchAllPendingCampaignsError = null;
    state.sendAdCopySuccess = false;
    state.pendingCampaigns = campaigns;
    state.lastDocPendingCampaigns = lastDoc;
  },
  [fetchAllPendingCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchAllPendingCampaignsLoading = false;
    state.fetchAllPendingCampaignsSuccess = false;
    state.fetchAllPendingCampaignsError = error;
  },

  [fetchAllCurrentCampaignsInit]: (state) => {
    state.fetchAllCurrentCampaignsLoading = true;
    state.fetchAllCurrentCampaignsSuccess = false;
    state.fetchAllCurrentCampaignsError = null;
    state.currentCampaigns = [];
    state.lastDocCurrentCampaigns = null;
  },
  [fetchAllCurrentCampaignsSuccess]: (state, { payload }) => {
    const { campaigns, lastDoc } = payload;
    state.fetchAllCurrentCampaignsLoading = false;
    state.fetchAllCurrentCampaignsSuccess = true;
    state.fetchAllCurrentCampaignsError = null;
    state.sendAdCopySuccess = false;
    state.currentCampaigns = campaigns;
    state.lastDocCurrentCampaigns = lastDoc;
  },
  [fetchAllCurrentCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchAllCurrentCampaignsLoading = false;
    state.fetchAllCurrentCampaignsSuccess = false;
    state.fetchAllCurrentCampaignsError = error;
  },
  [fetchAllFinishedCampaignsInit]: (state) => {
    state.fetchAllFinishedCampaignsLoading = true;
    state.fetchAllFinishedCampaignsSuccess = false;
    state.fetchAllFinishedCampaignsError = null;
    state.finishedCampaigns = [];
    state.lastDocFinishedCampaigns = null;
  },
  [fetchAllFinishedCampaignsSuccess]: (state, { payload }) => {
    const { campaigns, lastDoc } = payload;
    state.fetchAllFinishedCampaignsLoading = false;
    state.fetchAllFinishedCampaignsSuccess = true;
    state.fetchAllFinishedCampaignsError = null;
    state.finishedCampaigns = campaigns;
    state.lastDocFinishedCampaigns = lastDoc;
  },
  [fetchAllFinishedCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchAllFinishedCampaignsLoading = false;
    state.fetchAllFinishedCampaignsSuccess = false;
    state.fetchAllFinishedCampaignsError = error;
  },
  [fetchExistingCampaignsInit]: (state) => {
    state.fetchExistingCampaignsLoading = true;
    state.fetchExistingCampaignsSuccess = false;
    state.fetchExistingCampaignsError = null;
    state.existingCampaigns = [];
  },
  [fetchExistingCampaignsSuccess]: (state, { payload }) => {
    const { campaigns } = payload;
    state.fetchExistingCampaignsLoading = false;
    state.fetchExistingCampaignsSuccess = true;
    state.fetchExistingCampaignsError = null;
    state.existingCampaigns = campaigns;
  },
  [fetchExistingCampaignsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchExistingCampaignsLoading = false;
    state.fetchExistingCampaignsSuccess = false;
    state.fetchExistingCampaignsError = error;
    state.existingCampaigns = [];
  },
  [fetchCampaignByIdInit]: (state) => {
    state.fetchCampaignByIdLoading = true;
    state.fetchCampaignByIdSuccess = false;
    state.fetchCampaignByIdError = null;
    state.campaign = null;
  },
  [fetchCampaignByIdSuccess]: (state, { payload }) => {
    const { campaign } = payload;
    state.fetchCampaignByIdLoading = false;
    state.fetchCampaignByIdSuccess = true;
    state.fetchCampaignByIdError = null;
    state.campaign = campaign;
  },
  [fetchCampaignByIdFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCampaignByIdLoading = false;
    state.fetchCampaignByIdSuccess = false;
    state.fetchCampaignByIdError = error;
    state.campaign = null;
  },
  [sendAdCopyInit]: (state) => {
    state.sendAdCopyLoading = true;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = null;
  },
  [sendAdCopySuccess]: (state) => {
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = true;
    state.sendAdCopyError = null;
  },
  [sendAdCopyFail]: (state, { payload }) => {
    const { error } = payload;
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = error;
  },
  [editTalkingPointsInit]: (state) => {
    state.editTalkingPointsLoading = true;
    state.editTalkingPointsSuccess = false;
    state.editTalkingPointsError = null;
  },
  [editTalkingPointsSuccess]: (state) => {
    state.editTalkingPointsLoading = false;
    state.editTalkingPointsSuccess = true;
    state.editTalkingPointsError = null;
  },
  [editTalkingPointsFail]: (state, { payload }) => {
    const { error } = payload;
    state.sendAdCopyLoading = false;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = error;
  },
  [clearTalkingPoints]: (state) => {
    state.editTalkingPointsLoading = false;
    state.editTalkingPointsSuccess = false;
    state.editTalkingPointsError = null;
  },
  [fetchCampaignCategoriesInit]: (state) => {
    state.fetchCampaignCategoriesLoading = true;
    state.fetchCampaignCategoriesSuccess = false;
    state.fetchCampaignCategoriesError = null;
  },
  [fetchCampaignCategoriesSuccess]: (state, { payload }) => {
    const { categories } = payload;
    state.fetchCampaignCategoriesLoading = false;
    state.fetchCampaignCategoriesSuccess = true;
    state.fetchCampaignCategoriesError = null;
    state.categories = categories;
  },
  [fetchCampaignCategoriesFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCampaignCategoriesLoading = false;
    state.fetchCampaignCategoriesSuccess = false;
    state.fetchCampaignCategoriesError = error;
  },
  [fetchCampaignsPendingOffersInit]: (state) => {
    state.fetchCampaignsPendingOffersLoading = true;
    state.fetchCampaignsPendingOffersLoading = true;
    state.fetchCampaignsPendingOffersSuccess = false;
    state.fetchCampaignsPendingOffersError = null;
  },
  [fetchCampaignsPendingOffersSuccess]: (state, { payload }) => {
    const { pendingOffers } = payload;
    state.fetchCampaignsPendingOffersLoading = false;
    state.fetchCampaignsPendingOffersLoading = false;
    state.fetchCampaignsPendingOffersSuccess = true;
    state.fetchCampaignsPendingOffersError = null;
    state.pendingOffers = pendingOffers;
  },
  [fetchCampaignsPendingOffersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCampaignsPendingOffersLoading = false;
    state.fetchCampaignsPendingOffersSuccess = false;
    state.fetchCampaignsPendingOffersError = error;
  },
  [fetchCampaignsPodcastsDataInit]: (state) => {
    state.fetchCampaignsPodcastsDataLoading = true;
    state.fetchCampaignsPodcastsDataSuccess = false;
    state.fetchCampaignsPodcastsDataError = null;
  },
  [fetchCampaignsPodcastsDataSuccess]: (state, { payload }) => {
    const { podcastsData } = payload;
    state.fetchCampaignsPodcastsDataLoading = false;
    state.fetchCampaignsPodcastsDataSuccess = true;
    state.fetchCampaignsPodcastsDataError = null;
    state.podcastsData = { ...state.podcastsData, ...podcastsData };
  },
  [fetchCampaignsPodcastsDataFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCampaignsPodcastsDataLoading = false;
    state.fetchCampaignsPodcastsDataSuccess = false;
    state.fetchCampaignsPodcastsDataError = error;
  },
  [fetchNewCampaignEstimationsInit]: (state) => {
    state.fetchEstimationsInCampaignLoading = true;
    state.fetchEstimationsInCampaignSuccess = false;
    state.fetchEstimationsInCampaignError = null;
  },
  [fetchNewCampaignEstimationsSuccess]: (state, { payload }) => {
    const { estimationsSummary } = payload;

    state.fetchEstimationsInCampaignLoading = false;
    state.fetchEstimationsInCampaignSuccess = true;
    state.fetchEstimationsInCampaignError = null;
    state.estimationsSummary = {
      ...state.estimationsSummary,
      ...estimationsSummary,
      filters: {
        ...state.estimationsSummary.filters,
        ...estimationsSummary.filters,
      },
    };
  },
  [fetchNewCampaignEstimationsFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchEstimationsInCampaignLoading = false;
    state.fetchEstimationsInCampaignSuccess = false;
    state.fetchEstimationsInCampaignError = error;
  },
  [clearEstimationsFilters]: (state, { payload }) => {
    const { filtersToClear } = payload || {};

    if (filtersToClear?.length) {
      filtersToClear.forEach((filter) => {
        state.estimationsSummary = {
          ...state.estimationsSummary,
          filters: {
            ...state.estimationsSummary.filters,
            [filter]: null,
          },
        };
      });
    } else {
      state.estimationsSummary = {
        ...state.estimationsSummary,
        filters: {
          categories: null,
          gender: null,
          age: null,
          income: null,
          ethnicity: null,
          maritalStatus: null,
          countries: null,
          adPlacements: null,
        },
      };
    }
  },
  [clearCampaign]: (state) => {
    state.createCampaignSuccess = false;
    state.createCampaignError = null;
    state.editCampaignSuccess = false;
    state.editCampaignError = null;
    state.addOffersSuccess = false;
    state.addOffersError = null;
    state.finishedCampaignSuccess = false;
    state.finishedCampaignError = null;
    state.sendAdCopySuccess = false;
    state.sendAdCopyError = null;

    state.deleteCampaignLoading = false;
    state.deleteCampaignSuccess = false;
    state.deleteCampaignFail = false;
  },
  [updateCampaignsTab]: (state, { payload }) => {
    const { tab } = payload;
    state.campaignsTab = tab;
  },
});

export default campaignsReducer;
