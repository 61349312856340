import React from 'react';

import { brandLogo } from 'assets/white-label';

import classes from './Header.module.scss';

const Header = () => (
  <div className={classes.header}>
    <img className={classes.logoImage} src={brandLogo} alt="Brand Logo" />
  </div>
);

export default Header;
