import React from 'react';
import PropTypes from 'prop-types';
import RcSlider from 'rc-slider';

import Handle from 'components/Common/Slider/Handle';
import progressBarStyle from 'utils/styles/progressBarStyle';

import 'rc-slider/assets/index.css';

const ProgressBar = ({ value }) => (
  <RcSlider
    min={0}
    max={100}
    value={value}
    onChange={null}
    handle={Handle}
    trackStyle={progressBarStyle.trackStyle}
    handleStyle={progressBarStyle.handleStyle}
    railStyle={progressBarStyle.railStyle}
    dotStyle={progressBarStyle.dotStyle}
    style={{ width: '100%' }}
  />
);

ProgressBar.propTypes = {
  value: PropTypes.number,
};

ProgressBar.defaultProps = {
  value: 0,
};

export default ProgressBar;
