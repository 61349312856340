/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  createCheckoutSessionInit,
  createCheckoutSessionSuccess,
  createCheckoutSessionFail,
  saveCheckoutSessionInit,
  saveCheckoutSessionSuccess,
  saveCheckoutSessionFail,
  fetchCheckoutSessionInit,
  fetchCheckoutSessionSuccess,
  fetchCheckoutSessionFail,
  clearStripe,
} from 'state/actionCreators/stripe';

export const initialState = {
  createCheckoutSessionLoading: false,
  createCheckoutSessionSuccess: false,
  createCheckoutSessionError: null,
  saveCheckoutSessionLoading: false,
  saveCheckoutSessionSuccess: false,
  saveCheckoutSessionError: null,
  fetchCheckoutSessionLoading: false,
  fetchCheckoutSessionSuccess: false,
  fetchCheckoutSessionError: null,
  session: null,
};

const stripeReducer = createReducer(initialState, {
  [createCheckoutSessionInit]: (state) => {
    state.createCheckoutSessionLoading = true;
    state.createCheckoutSessionSuccess = false;
    state.createCheckoutSessionError = null;
    state.session = null;
  },
  [createCheckoutSessionSuccess]: (state, { payload }) => {
    const { session } = payload;
    state.createCheckoutSessionLoading = false;
    state.createCheckoutSessionSuccess = true;
    state.createCheckoutSessionError = null;
    state.session = session;
  },
  [createCheckoutSessionFail]: (state, { payload }) => {
    const { error } = payload;
    state.createCheckoutSessionLoading = false;
    state.createCheckoutSessionSuccess = false;
    state.createCheckoutSessionError = error;
    state.session = null;
  },
  [saveCheckoutSessionInit]: (state) => {
    state.saveCheckoutSessionLoading = true;
    state.saveCheckoutSessionSuccess = false;
    state.saveCheckoutSessionError = null;
  },
  [saveCheckoutSessionSuccess]: (state) => {
    state.saveCheckoutSessionLoading = false;
    state.saveCheckoutSessionSuccess = true;
    state.saveCheckoutSessionError = null;
  },
  [saveCheckoutSessionFail]: (state, { payload }) => {
    const { error } = payload;
    state.saveCheckoutSessionLoading = false;
    state.saveCheckoutSessionSuccess = false;
    state.saveCheckoutSessionError = error;
  },
  [fetchCheckoutSessionInit]: (state) => {
    state.fetchCheckoutSessionLoading = true;
    state.fetchCheckoutSessionSuccess = false;
    state.fetchCheckoutSessionError = null;
    state.session = null;
  },
  [fetchCheckoutSessionSuccess]: (state, { payload }) => {
    const { session } = payload;
    state.fetchCheckoutSessionLoading = false;
    state.fetchCheckoutSessionSuccess = true;
    state.fetchCheckoutSessionError = null;
    state.session = session;
  },
  [fetchCheckoutSessionFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchCheckoutSessionLoading = false;
    state.fetchCheckoutSessionSuccess = false;
    state.fetchCheckoutSessionError = error;
    state.session = null;
  },
  [clearStripe]: (state) => {
    state.createCheckoutSessionLoading = false;
    state.createCheckoutSessionSuccess = false;
    state.createCheckoutSessionError = null;
    state.fetchCheckoutSessionLoading = false;
    state.fetchCheckoutSessionSuccess = false;
    state.fetchCheckoutSessionError = null;
    state.saveCheckoutSessionLoading = false;
    state.saveCheckoutSessionSuccess = false;
    state.saveCheckoutSessionError = null;
    state.session = null;
  },
});

export default stripeReducer;
