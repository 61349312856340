import { doc, getDoc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Errors from 'enums/errors/errors.enums';
import Collections from 'enums/firebase/collections.enum';

const fetchUserById = async ({ uid }) => {
  try {
    const userRef = doc(firestore, Collections.Brands, uid);
    const firestoreUser = await getDoc(userRef);

    if (!firestoreUser.exists()) {
      throw new Error(Errors.InvalidCredentials);
    }

    return { ...firestoreUser.data(), uid };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchUserById;
