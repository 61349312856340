import { createAction } from '@reduxjs/toolkit';

export const fetchEnabledUsersInit = createAction(
  '[Users] Fetch Enabled Users Init'
);
export const fetchEnabledUsersSuccess = createAction(
  '[Users] Fetch Enabled Users Success'
);
export const fetchEnabledUsersFail = createAction(
  '[Users] Fetch Enabled Users Fail'
);

export const fetchSelectedUsersInit = createAction(
  '[Users] Fetch Selected Users Init'
);
export const fetchSelectedUsersSuccess = createAction(
  '[Users] Fetch Selected Users Success'
);
export const fetchSelectedUsersFail = createAction(
  '[Users] Fetch Selected Users Fail'
);

export const fetchUserByIdInit = createAction('[Users] Fetch User by id Init');
export const fetchUserByIdSuccess = createAction(
  '[Users] Fetch User by id Success'
);
export const fetchUserByIdFail = createAction('[Users] Fetch User by id Fail');

export const clearUsers = createAction('[Users] Clear Users');
