import * as yup from 'yup';

import Validations from 'enums/schemas/validations.enum';
import Required from 'enums/schemas/required.enum';
import { MIN_PASSWORD_LENGTH } from 'constants/schemas';

const LoginValidationSchema = yup.object().shape({
  email: yup.string().email(Validations.InvalidEmail).required(Required.Email),
  password: yup
    .string()
    .required(Required.Password)
    .min(MIN_PASSWORD_LENGTH, Validations.MinLengthPassword),
});

export default LoginValidationSchema;
