const extractTextFromHTML = (html) => {
  const element = document.createElement('span');

  element.innerHTML = html;

  const content = element.textContent || element.innerText;

  element.remove();

  return content;
};

export default extractTextFromHTML;
