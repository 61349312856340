import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useIntercom } from 'react-use-intercom';

import SideBar from 'components/Navigation/SideBar';
import Header from 'components/Navigation/Header';
import Footer from 'components/Navigation/Footer';
import { clearAuthState } from 'state/actions/auth';
import { selectAuthenticatedUserState } from 'state/selectors/auth';

import classes from './NavigationLayout.module.scss';

const NavigationLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { boot, shutdown } = useIntercom();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const [open, setOpen] = useState(false);

  useEffect(() => {
    boot({
      email: user.email,
    });

    return () => {
      shutdown();
      dispatch(clearAuthState());
    };
  }, []);

  const onToggleMenuHandler = useCallback(() => {
    setOpen((prevState) => !prevState);
  }, []);

  return (
    <div className={classes.container}>
      <SideBar open={open} onToggleMenu={onToggleMenuHandler} />
      <Header />
      <main className={classes.content}>
        {children}
        <Footer className={classes.footer} />
      </main>
    </div>
  );
};

NavigationLayout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default NavigationLayout;
