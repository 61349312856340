export const categoriesFilter = (podcastCategories, filterCategories) =>
  filterCategories.some((filterCategory) => podcastCategories[filterCategory]);

export const genderFilter = (podcastGender, filterGender) => {
  const { female: podcastFemaleGender } = podcastGender;
  const { female } = filterGender;

  if (female === 50) {
    return true;
  }

  if (female > 50) {
    return podcastFemaleGender >= 50;
  }

  return podcastFemaleGender <= 50;
};

export const ageFilter = (podcastAge, filterAge, previousResult) => {
  let result = previousResult;

  if (podcastAge?.length && filterAge?.length) {
    result = podcastAge.some((age) => filterAge.includes(age));
  }

  return result;
};

export const incomeFilter = (podcastIncome, filterIncome, previousResult) => {
  let result = previousResult;

  if (podcastIncome?.length && filterIncome?.length) {
    result = podcastIncome.some((income) => filterIncome.includes(income));
  }

  return result;
};

export const ethnicityFilter = (
  podcastEthnicity,
  filterEthnicity,
  previousResult
) => {
  let result = previousResult;

  if (podcastEthnicity?.length && filterEthnicity?.length) {
    result = podcastEthnicity.some((ethnicity) =>
      filterEthnicity.includes(ethnicity)
    );
  }

  return result;
};

export const maritalStatusFilter = (
  podcastMaritalStatus,
  filterMaritalStatus,
  previousResult
) => {
  let result = previousResult;

  if (podcastMaritalStatus?.length && filterMaritalStatus?.length) {
    result = podcastMaritalStatus.some((maritalStatus) =>
      filterMaritalStatus.includes(maritalStatus)
    );
  }

  return result;
};

export const countriesFilter = (
  podcastCountry,
  filterCountries,
  previousResult
) => {
  let result = previousResult;

  if (podcastCountry && filterCountries?.length) {
    result = filterCountries.includes(podcastCountry);
  }

  return result;
};

export const adPlacementsFilter = (
  podcastAdPlacements,
  filterAdPlacements,
  previousResult
) => {
  let result = previousResult;

  if (podcastAdPlacements?.length && filterAdPlacements?.length) {
    result = podcastAdPlacements.some((adPlacement) =>
      filterAdPlacements.includes(adPlacement)
    );
  }

  return result;
};

export const adFormatsFilter = (
  podcastAdFormats,
  filterAdFormats,
  previousResult
) => {
  let result = previousResult;

  if (podcastAdFormats?.length && filterAdFormats?.length) {
    result = podcastAdFormats.some((adFormat) =>
      filterAdFormats.includes(adFormat)
    );
  }

  return result;
};
