import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import {
  poweredByLink,
  privacyPolicyLink,
  termsOfServiceLink,
} from 'assets/white-label';

import classes from './Footer.module.scss';

const Footer = ({ className }) => (
  <footer className={classNames(classes.footer, className)}>
    <a
      href={termsOfServiceLink}
      className={classes.footerLink}
      target="_blank"
      rel="noreferrer"
    >
      <Body
        color={BodyColor.Gray}
        size={BodySize.XXS}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.S}
      >
        Terms of service
      </Body>
    </a>
    <a
      href={privacyPolicyLink}
      className={classes.footerLink}
      target="_blank"
      rel="noreferrer"
    >
      <Body
        color={BodyColor.Gray}
        size={BodySize.XXS}
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.S}
      >
        Privacy
      </Body>
    </a>
    {poweredByLink && (
      <a href={poweredByLink} className={classes.footerLink}>
        <Body
          color={BodyColor.Black}
          size={BodySize.XXS}
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.S}
        >
          Powered By
          <img
            src={`${process.env.PUBLIC_URL}/ossa-footer-image.png`}
            alt="Ossa Logo"
            className={classes.logo}
          />
        </Body>
      </a>
    )}
  </footer>
);

Footer.propTypes = {
  className: PropTypes.string,
};

Footer.defaultProps = {
  className: '',
};

export default Footer;
