import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';

const createCheckoutSession = async ({ brandId, campaignId, couponId }) => {
  try {
    const generateCheckout = httpsCallable(
      functions,
      'campaigns-generateCheckout'
    );
    const { data } = await generateCheckout({
      brandId,
      campaignId,
      couponId,
    });

    return data;
  } catch (error) {
    throw new Error(error);
  }
};

export default createCheckoutSession;
