const removeNonAcceptedTags = (string, acceptedTags) => {
  const tagsRegex = new RegExp(
    `<\\/?(?!(${acceptedTags.join('|')}))\\b[^>]*>`,
    'gi'
  );

  return string.replace(tagsRegex, '');
};

export default removeNonAcceptedTags;
