export const HAS_CAMPAIGNS = 'has-campaigns';

export const ONE_MONTH = 28;

export const PODCASTS_TO_SHOW = 6;

export const MIN_LOGO_HEIGHT = 100;

export const PAGINATION_ITEMS = 100;

export const GENDER_VARIATION = 10;

export const PERCENTAGE_CONVERTION = 100;

export const DEFAULT_GENDER = 50;

export const ONE_WEEK = 7;

export const ONE_DAY = 1;

export const CATEGORIES_PODCASTS_PAGINATION = 15;

export const CPM_RATE = 38;

export const PODCASTER_CORRESPONDING_PERCENTAGE = 0.45;

export const CPM_MODIFIER_EMAILS = [];
