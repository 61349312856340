import Form from 'components/Common/Form';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import useCancellableTimeout from 'hooks/useCancellableTimeout';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import { getCampaignPodcastsInput } from 'utils/inputs/createCampaignInputs';
import { fetchPodcastsEstimations } from 'state/actions/podcasts';
import { CATEGORIES_PODCASTS_PAGINATION } from 'constants/campaign';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useMemo, useState } from 'react';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import {
  selectFetchEstimationPodcastsState,
  selectFetchPodcastsState,
} from 'state/selectors/podcasts';
import classes from './CampaignPodcastsForm.module.scss';

const CampaignPodcastsForm = ({
  categories,
  setPodcasts,
  podcasts: customPodcasts,
}) => {
  const dispatch = useDispatch();

  const {
    currentStep,
    formsData,
    goBack,
    onSubmit,
    goForward,
    goForwardTo,
  } = useMultiStepFormContext();

  const [podcastsMap, setPodcastsToMap] = useState({});
  const [podcastsToShow, setPodcastsToShow] = useState([]);
  const [remainingPodcasts, setRemainingPodcasts] = useState([]);
  const [selectedPodcasts, setSelectedPodcasts] = useState({});

  const { inTimeout, createTimeout } = useCancellableTimeout();

  const { podcasts } = useSelector(selectFetchPodcastsState, shallowEqual);

  const { loading, estimationsPodcasts } = useSelector(
    selectFetchEstimationPodcastsState,
    shallowEqual
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const defaultData = formsData[currentStep] || {
    podcasts: Object.keys(customPodcasts),
  };

  useEffect(() => {
    dispatch(
      fetchPodcastsEstimations({ podcasts: defaultData.podcasts ?? [] })
    );
  }, []);

  useEffect(() => {
    if (podcasts.length > 0 && podcastsToShow.length === 0) {
      const podcastsMapLength = Object.keys(podcastsMap).length;
      const allPodcasts = [];
      const newPodcastsMap = {};
      const podcastsFiltered = podcasts.filter(
        (podcast) =>
          podcast?.podcastData?.podcastId !== undefined ||
          podcast?.podcastData?.podcastId !== null
      );

      podcastsFiltered.forEach(({ podcastData }) => {
        const { podcastId, podcastTitle } = podcastData;
        if (!podcastsMapLength) {
          newPodcastsMap[podcastId] = podcastTitle;
        }
        if (formsData[currentStep]?.podcasts?.includes(podcastId)) {
          setPodcasts((prevState) => ({
            ...prevState,
            [podcastId]: podcastTitle,
          }));
        }
        allPodcasts.push({
          label: podcastTitle,
          value: podcastId,
        });
      });

      setPodcastsToMap(newPodcastsMap);

      const firstPodcasts = allPodcasts.splice(
        0,
        CATEGORIES_PODCASTS_PAGINATION
      );
      setPodcastsToShow(firstPodcasts);
      setRemainingPodcasts(allPodcasts);
    }
  }, [podcasts, podcastsToShow, podcastsMap]);

  const onClickMoresHandler = useCallback(() => {
    const allRemainingCategories = [...remainingPodcasts];
    const firstPodcasts = allRemainingCategories.splice(
      0,
      CATEGORIES_PODCASTS_PAGINATION
    );

    setPodcastsToShow((prevState) => [...prevState, ...firstPodcasts]);
    setRemainingPodcasts(allRemainingCategories);
  }, [remainingPodcasts]);

  const onChangeHandler = useCallback(
    (newValues) => {
      if (Object.keys(categories).length > 0) {
        return [];
      }

      if (formsData[currentStep]?.podcasts?.length > 0) {
        if (formsData[currentStep]?.podcasts?.length === 0) {
          delete formsData[currentStep];
          onSubmit({ ...formsData });
        }
        const filteredPodcasts = formsData[currentStep]?.podcasts?.filter(
          (podcastId) => podcastId === newValues[newValues.length - 1]
        );
        onSubmit({ podcasts: filteredPodcasts });
      }

      if (!newValues.length) {
        setPodcasts({});
        setSelectedPodcasts({});
      }

      newValues.forEach((value) => {
        if (!selectedPodcasts[value]) {
          setPodcasts((prevState) => ({
            ...prevState,
            [value]: podcastsMap[value],
          }));
          setSelectedPodcasts((prevState) => ({
            ...prevState,
            [value]: podcastsMap[value],
          }));
        }
      });

      createTimeout(() => {
        dispatch(fetchPodcastsEstimations({ podcasts: newValues }));
      }, 1500);

      return newValues;
    },
    [selectedPodcasts, podcastsMap, dispatch]
  );

  const onGoBackHandler = () => {
    setPodcasts([]);
    goBack();
  };

  const onSubmitHandler = (data) => {
    onSubmit(data);
    if (Object.keys(customPodcasts).length > 0) {
      goForwardTo(8);
    } else {
      goForward();
    }
  };

  const campaignPodcastsFields = useMemo(
    () =>
      getCampaignPodcastsInput({
        podcasts: podcastsToShow,
        defaultValues: defaultData,
        onChangeHandler,
      }),
    [podcastsToShow, defaultData, onChangeHandler]
  );
  const isLoading = inTimeout || loading;

  const isCategoriesSelected = Object.keys(categories)?.length > 0;

  return (
    <Form
      onSubmit={onSubmitHandler}
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
    >
      <div>
        <Body size={BodySize.S} color={BodyColor.Black}>
          Choose the podcasts you want to advertise.
        </Body>
        {(isCategoriesSelected || defaultData?.categories?.length > 0) && (
          <Body size={BodySize.XXS} color={BodyColor.Red}>
            You must deselect your categories before selecting podcasts.
          </Body>
        )}
        <div className={classes.fields}>
          {campaignPodcastsFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
          {remainingPodcasts.length > 0 && (
            <button
              type="button"
              className={classes.podcastsButton}
              onClick={onClickMoresHandler}
            >
              See More Podcasts
            </button>
          )}
        </div>
      </div>
      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        estimatedImpressions={estimationsPodcasts}
        loading={isLoading}
        onlyEstimations
      />
      <div className={commonClasses.buttonsContainer}>
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={onGoBackHandler}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

CampaignPodcastsForm.propTypes = {
  categories: PropTypes.arrayOf.isRequired,
  podcasts: PropTypes.arrayOf.isRequired,
  setPodcasts: PropTypes.func.isRequired,
};

export default CampaignPodcastsForm;
