import * as yup from 'yup';

import Required from 'enums/schemas/required.enum';

const startAndEndFormSchema = yup.object().shape({
  airingFrom: yup.date().nullable().required(Required.StartDate),
  airingTo: yup.date().nullable().required(Required.EndDate),
});

export default startAndEndFormSchema;
