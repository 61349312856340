import { Type as InputType } from 'components/Common/Input';

const newPasswordInputs = [
  {
    name: 'password',
    renderProps: { placeholder: 'Your New Password', type: InputType.Password },
  },
  {
    name: 'confirmPassword',
    renderProps: { placeholder: 'Confirm Password', type: InputType.Password },
  },
];

export default newPasswordInputs;
