import { useSelector, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';

import { selectFetchCampaignsPodcastsDataState } from 'state/selectors/campaigns';
import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './SendSample.module.scss';

const SendSample = ({ offer }) => {
  const { podcastsData, loading } = useSelector(
    selectFetchCampaignsPodcastsDataState,
    shallowEqual
  );

  const { podcastImageUrl, podcastName, brandName } = offer || {};

  const { mailingAddress } = podcastsData?.[offer?.podcastId] || {};

  const addressComponents = Object.keys(mailingAddress || {})
    .sort()
    .map((key) => (
      <p className={classes.addresLine}>{mailingAddress?.[key]}</p>
    ));

  const noAddress = <p>Theres no address available for this podcaster</p>;

  return (
    <div className={classes.container}>
      {!loading ? (
        <>
          <div className={classes.top}>
            <Body> Send Sample </Body>
            <img
              src={podcastImageUrl}
              alt={podcastName}
              className={classes.img}
            />
            <Body size={BodySize.XS}> {podcastName} </Body>
            <Body size={BodySize.XS} className={classes.text}>
              Use the address below to send samples of your product to the show.
              Make sure to send it before your campaign starts.
            </Body>
          </div>
          <div className={classes.bottom}>
            <div className={classes.addressContainer}>
              {addressComponents.length ? (
                <>
                  <div className={classes.names}>
                    <Body size={BodySize.XS} className={classes.name}>
                      {brandName}
                    </Body>
                    <Body size={BodySize.XS} className={classes.name}>
                      {podcastName}
                    </Body>
                  </div>
                  <div className={classes.address}>{addressComponents}</div>
                </>
              ) : (
                noAddress
              )}
            </div>
          </div>
        </>
      ) : (
        <>LOADING</>
      )}
    </div>
  );
};

SendSample.propTypes = {
  offer: PropTypes.shape({
    brandName: PropTypes.string,
    podcastImageUrl: PropTypes.string,
    podcastName: PropTypes.string,
    podcastId: PropTypes.string,
  }).isRequired,
};

export default SendSample;
