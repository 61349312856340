import { MIN_LOGO_HEIGHT } from 'constants/campaign';

const readUploadedFile = ({ file, setFile, setError, setChangedFile }) => {
  const reader = new FileReader();

  reader.readAsDataURL(file);

  reader.onload = (e) => {
    const image = new Image();

    image.src = e.target.result;

    image.onload = () => {
      const { height } = image;

      if (height < MIN_LOGO_HEIGHT) {
        setFile(null);
        setError(
          `The logo must have a minimum height of ${MIN_LOGO_HEIGHT}px.`
        );
      } else {
        setFile(file);
        setChangedFile(true);
        setError('');
      }
    };
  };
};

export default readUploadedFile;
