import StoragePaths from 'enums/storage/storagePaths.enum';
import { storage } from 'firebase';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';

const uploadCampaignImage = async ({ campaignImage, brandId, campaignId }) => {
  try {
    const logoPath = `${StoragePaths.CAMPAIGNS_IMAGES}${brandId}/${campaignId}`;

    const storageRef = ref(storage, logoPath);

    await uploadBytes(storageRef, campaignImage);

    const url = await getDownloadURL(ref(storage, logoPath));

    return url;
  } catch (error) {
    throw new Error(error);
  }
};

export default uploadCampaignImage;
