/* eslint-disable react/button-has-type */
import React from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';

import classes from './IconButton.module.scss';

export const Type = Object.freeze({
  Default: 'button',
  Reset: 'reset',
  Submit: 'submit',
});

const IconButton = ({ type, disabled, children, onClick, className }) => (
  <button
    type={type}
    className={classNames(classes.button, className, {
      [classes.disabled]: disabled,
    })}
    onClick={onClick}
    disabled={disabled}
  >
    {children}
  </button>
);

IconButton.propTypes = {
  children: PropTypes.node.isRequired,
  type: PropTypes.oneOf(Object.values(Type)),
  disabled: PropTypes.bool,
  onClick: PropTypes.func,
  className: PropTypes.string,
};

IconButton.defaultProps = {
  type: Type.Default,
  disabled: false,
  onClick: () => {},
  className: '',
};

export default IconButton;
