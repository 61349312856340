import React from 'react';
import Select from 'react-select';
import PropTypes from 'prop-types';

const SelectInput = ({
  borderColor,
  backgroundColor,
  readOnly,
  disabled,
  ...props
}) => (
  <Select
    styles={{
      control: (provided, state) => ({
        ...provided,
        textAlign: 'left',
        border: state.isFocused ? '1px solid #645aff' : borderColor,
        minHeight: '48px',
        boxShadow: '0px 12px 23px rgba(55, 125, 255, 0.06)',
        backgroundColor:
          state.isDisabled && readOnly ? 'white' : backgroundColor,
        fontSize: '0.875rem',
        '&:hover': {
          border: state.isFocused ? '1px solid #645aff' : borderColor,
        },
      }),
      valueContainer: (provided) => ({
        ...provided,
        marginLeft: '0.5rem',
      }),
      singleValue: (provided) => ({
        ...provided,
        color: disabled ? '#7b88a8' : 'black',
      }),
      placeholder: (provided) => ({
        ...provided,
        color: '#b9b9b9',
      }),
      menu: (provided) => ({
        ...provided,
        textAlign: 'left',
      }),
      option: (provided, state) => ({
        ...provided,
        fontSize: '0.875rem',
        backgroundColor: state.isSelected ? '#645aff' : 'white',
        color: state.isSelected ? 'white' : 'black',
        '&:hover': {
          backgroundColor: '#e2e0ff',
        },
      }),
    }}
    {...props}
  />
);

SelectInput.propTypes = {
  borderColor: PropTypes.string,
  backgroundColor: PropTypes.string,
  readOnly: PropTypes.bool,
  disabled: PropTypes.bool,
};

SelectInput.defaultProps = {
  borderColor: 'white',
  backgroundColor: 'white',
  readOnly: false,
  disabled: false,
};

export default SelectInput;
