/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback } from 'react';
import { useSelector, shallowEqual } from 'react-redux';
import { useHistory } from 'react-router-dom';

import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Divider from 'components/Common/Divider';
import Toast from 'components/Common/Toast';
import Link from 'components/Typography/Link';
import LoginForm from 'components/Pages/Forms/LoginForm';
import { selectLoginState } from 'state/selectors/auth';
import Path from 'enums/path.enum';
import { brandLogo } from 'assets/white-label';

import classes from './Login.module.scss';

const Login = () => {
  const history = useHistory();

  const { error } = useSelector(selectLoginState, shallowEqual);

  const onCreateAccountHandler = useCallback(() => {
    history.push(Path.SignUp);
  }, []);

  return (
    <>
      {error && <Toast text={error} id="Login error" />}
      <div className={classes.container}>
        <div className={classes.logo}>
          <img className={classes.logoImage} src={brandLogo} alt="Brand Logo" />
        </div>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Enter your account
        </Body>
        <LoginForm />
        <div className={classes.link}>
          <Link to={Path.ResetPassword} underline={false}>
            <Body
              color={BodyColor.Gray}
              size={BodySize.XXS}
              letterCase={BodyLetterCase.Uppercase}
              spacing={BodySpacing.S}
              className={classes.bold}
            >
              Forgot password?
            </Body>
          </Link>
        </div>
        <div className={classes.or}>
          <Divider />
          <Body letterCase={BodyLetterCase.Uppercase} spacing={BodySpacing.M}>
            Or
          </Body>
          <Divider />
        </div>
        <Button
          type={ButtonType.Default}
          kind={ButtonKind.Tertiary}
          onClick={onCreateAccountHandler}
          className={classes.button}
        >
          Create account
        </Button>
      </div>
    </>
  );
};

export default Login;
