import {
  collection,
  query,
  where,
  getDocs,
  limit,
  startAfter,
  orderBy,
  or,
  and,
} from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';
import { STATUS_PENDING } from 'utils/Campaign/status';

const fetchAllPendingCampaigns = async ({
  brandId,
  lastDoc,
  searchValue,
  selectedSort = {},
}) => {
  try {
    const campaignRef = collection(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns
    );

    let pendingCampaignsQuery;

    if (searchValue.length > 0) {
      pendingCampaignsQuery = query(
        campaignRef,
        or(
          and(
            where('name', '>=', searchValue),
            where('name', '<=', `${searchValue}\uf8ff`),
            where('status', '==', STATUS_PENDING)
          ),
          and(
            where(
              'name',
              '>=',
              searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
            ),
            where(
              'name',
              '<=',
              `${
                searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
              }\uf8ff`
            ),
            where('status', '==', STATUS_PENDING)
          ),
          and(
            where('name', '>=', searchValue.toLowerCase()),
            where('name', '<=', `${searchValue.toLowerCase()}\uf8ff`),
            where('status', '==', STATUS_PENDING)
          )
        ),
        orderBy('name', 'asc'),
        limit(10)
      );
    } else if (selectedSort.value) {
      pendingCampaignsQuery = query(
        campaignRef,
        where('status', '==', STATUS_PENDING),
        orderBy(selectedSort.sortBy, selectedSort.sortOrder),
        limit(10)
      );
    } else {
      pendingCampaignsQuery = query(
        campaignRef,
        where('status', '==', STATUS_PENDING),
        orderBy('airingFrom', 'desc'),
        limit(10)
      );
    }

    if (lastDoc) {
      pendingCampaignsQuery = query(pendingCampaignsQuery, startAfter(lastDoc));
    }

    const pendingCampaignsSnapshot = await getDocs(pendingCampaignsQuery);

    const lastDocInBatch =
      pendingCampaignsSnapshot.docs[pendingCampaignsSnapshot.docs.length - 1];

    const pendingCampaigns = pendingCampaignsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      uid: doc.id,
    }));

    return { pendingCampaigns, lastDoc: lastDocInBatch };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchAllPendingCampaigns;
