import PropTypes from 'prop-types';
import classNames from 'classnames';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';

import classes from './MultipleRectangleButtonsList.module.scss';

const MultipleRectangleButtonsList = ({
  containerClassName,
  buttonClassName,
  label,
  multiple,
  value,
  name,
  options,
  onChange,
  onChangeDo,
}) => {
  const onChangeHandler = (selectedValue) => {
    if (multiple) {
      const indexToDelete = value.indexOf(selectedValue);

      if (indexToDelete !== -1) {
        const newArr = [...value];

        newArr.splice(indexToDelete, 1);

        if (onChangeDo) {
          onChangeDo(newArr, { name });
        }

        return onChange(newArr);
      }

      if (onChangeDo) {
        onChangeDo(value.concat([selectedValue]), { name });
      }

      return onChange(value.concat([selectedValue]));
    }

    if (onChangeDo) {
      onChangeDo([selectedValue], { name });
    }

    return onChange(selectedValue);
  };

  return (
    <div className={containerClassName}>
      {label && (
        <Body
          size={BodySize.S}
          color={BodyColor.Black}
          className={classes.body}
        >
          {label}
        </Body>
      )}
      <div className={classes.adType}>
        {options.map((option) => (
          <IconButton
            key={option.value}
            onClick={() => {
              onChangeHandler(option.value);
            }}
            className={classNames(classes.adTypeButton, buttonClassName, {
              [classes.selectedAdType]: multiple
                ? value?.includes(option.value)
                : value === option.value,
            })}
          >
            <Body size={BodySize.XS} className={classes.adTypeTitle}>
              {option.label}
            </Body>
          </IconButton>
        ))}
      </div>
    </div>
  );
};

MultipleRectangleButtonsList.propTypes = {
  label: PropTypes.string,
  value: PropTypes.arrayOf(PropTypes.string).isRequired,
  multiple: PropTypes.bool,
  onChangeDo: PropTypes.func,
  options: PropTypes.arrayOf(
    PropTypes.shape({
      label: PropTypes.string,
      value: PropTypes.string,
    })
  ).isRequired,
  name: PropTypes.string,
  onChange: PropTypes.func.isRequired,
  containerClassName: PropTypes.string,
  buttonClassName: PropTypes.string,
};

MultipleRectangleButtonsList.defaultProps = {
  label: null,
  multiple: false,
  onChangeDo: null,
  containerClassName: '',
  buttonClassName: '',
  name: '',
};

export default MultipleRectangleButtonsList;
