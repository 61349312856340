import React from 'react';
import { useHistory } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Tabs from 'components/Common/Tabs';
import Tab from 'components/Common/Tab';
import IconButton from 'components/Common/IconButton';
import PodcastHistory from 'components/Pages/Settings/PodcastHistory';
import Path from 'enums/path.enum';
import createIcon from 'assets/icons/create.png';
import EditUserForm from 'components/Pages/Forms/EditUserForm';

import classes from './Settings.module.scss';

const Settings = () => {
  const history = useHistory();

  return (
    <div className={classes.container}>
      <IconButton
        onClick={() => history.push(Path.NewCampaign)}
        className={classes.newCampaign}
      >
        <img src={createIcon} alt="create" />
        <Body size={BodySize.XS} className={classes.newCampaignText}>
          New campaign
        </Body>
      </IconButton>
      <div className={classes.content}>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Settings
        </Body>
        <Tabs className={classes.tabs}>
          <Tab tab="Account">
            <EditUserForm />
          </Tab>
          {/*

          TODO - Implement this tabs

          <Tab tab="Email Notifications">
            <EmailNotifications />
          </Tab>
          <Tab tab="Billing">
            <div>Billing</div>
          </Tab>
          */}
          <Tab tab="Podcast History">
            <PodcastHistory />
          </Tab>
        </Tabs>
      </div>
    </div>
  );
};

export default Settings;
