import React, { useEffect } from 'react';
import PropTypes, { instanceOf } from 'prop-types';
import { useFormContext, Controller } from 'react-hook-form';
import classNames from 'classnames';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import DatePicker from 'components/Common/DatePicker';

import classes from './FormControlDatePicker.module.scss';

const FormControlDatePicker = ({
  name,
  label,
  labelClassName,
  placeholder,
  defaultValue,
  className,
  disabled,
  readOnly,
  minDate,
  maxDate,
  onManualChange,
  icon: Icon,
}) => {
  const { errors, control, watch, setValue } = useFormContext();
  const error = errors[name];

  const currentValue = watch(name);

  const normalizedDate = new Date(currentValue);
  normalizedDate.setHours(12, 0, 0, 0);

  const normalizedMinDate = new Date(minDate);
  normalizedMinDate.setHours(12, 0, 0, 0);

  useEffect(() => {
    if (
      currentValue &&
      minDate &&
      normalizedDate.getTime() < normalizedMinDate.getTime()
    ) {
      setValue(name, minDate);
    }
  }, [name, normalizedDate, normalizedMinDate, setValue, minDate]);

  return (
    <div
      className={classNames(className, classes.container, {
        [classes.error]: !!error,
      })}
    >
      {label && (
        <Body
          size={BodySize.XS}
          className={labelClassName}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <div className={classNames(classes.datePicker)}>
        <Controller
          name={name}
          defaultValue={defaultValue}
          control={control}
          render={({ onChange, value }) => (
            <span className={classes.inputWrapper}>
              <DatePicker
                onChange={(event) => {
                  onChange(event);
                  onManualChange(event);
                }}
                id={name}
                date={value}
                minDate={minDate}
                maxDate={maxDate}
                placeholder={placeholder}
                defaultValue={defaultValue}
                className={classes.input}
                disabled={disabled}
                readOnly={readOnly}
                error={!!error}
              />
              {Icon && (
                <label
                  htmlFor={name}
                  className={classNames(classes.icon, {
                    [classes.disabledCursor]: disabled,
                  })}
                >
                  {Icon}
                </label>
              )}
            </span>
          )}
        />
        {error && (
          <Body
            color={BodyColor.Red}
            size={BodySize.XXS}
            className={classes.errorText}
          >
            {error.message}
          </Body>
        )}
      </div>
    </div>
  );
};

FormControlDatePicker.propTypes = {
  name: PropTypes.string.isRequired,
  label: PropTypes.string,
  labelClassName: PropTypes.string,
  placeholder: PropTypes.string,
  className: PropTypes.string,
  defaultValue: instanceOf(Date),
  disabled: PropTypes.bool,
  readOnly: PropTypes.bool,
  minDate: PropTypes.instanceOf(Date),
  maxDate: PropTypes.instanceOf(Date),
  onManualChange: PropTypes.func,
  icon: PropTypes.elementType,
};

FormControlDatePicker.defaultProps = {
  label: null,
  placeholder: 'MM/DD/YYYY',
  className: '',
  labelClassName: '',
  defaultValue: null,
  disabled: false,
  readOnly: false,
  minDate: null,
  maxDate: null,
  onManualChange: () => {},
  icon: null,
};

export default FormControlDatePicker;
