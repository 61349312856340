import React, { useCallback, useEffect, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Button, { Type as ButtonType } from 'components/Common/Button';
import Form, { ValidationMode } from 'components/Common/Form';
import Toast from 'components/Common/Toast';
import Spinner from 'components/Common/Spinner';
import { editTalkingPoints } from 'state/actions/campaigns';
import { selectSendTalkingPointsState } from 'state/selectors/campaigns';
import FormControlTextarea from 'components/Common/FormControlTextarea';
import { Size as TextareaSize } from 'components/Common/Textarea';

import validationSchema from './EditTalkingPointsAll.schema';
import classes from './EditTalkingPointsAll.module.scss';

const EditTalkingPointsAll = ({
  campaign,
  offer,
  finishedCampaign,
  addToAll,
}) => {
  const dispatch = useDispatch();
  const { loading, error } = useSelector(
    selectSendTalkingPointsState,
    shallowEqual
  );
  const [value, setValue] = useState('');
  const [isLoading, setIsLoading] = useState(false);

  const onClickSendHandler = useCallback(
    (values) => {
      dispatch(
        editTalkingPoints({
          campaign,
          podcastId: offer?.podcastId,
          talkingPoints: values.editTalkingPoints,
          addToAll,
        })
      );
    },
    [campaign, offer]
  );

  useEffect(() => {
    setIsLoading(true);
    if (addToAll && campaign && campaign.talkingPoints) {
      setIsLoading(false);
      setValue(campaign.talkingPoints);
    }
    if (!addToAll && offer && offer.talkingPoints) {
      setIsLoading(false);
      setValue(offer.talkingPoints);
    }
  }, [campaign, offer]);

  return (
    <>
      {error && <Toast text={error} id="Send Talking Points Error" />}
      {isLoading ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.container}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
            size={BodySize.S}
          >
            {addToAll ? 'Edit talking points for all' : 'Edit talking points'}
          </Body>
          {!addToAll && (
            <>
              <div>
                <img
                  src={offer?.podcastImageUrl}
                  alt="podcast logo"
                  className={classes.logo}
                />
              </div>
              <Body size={BodySize.XS}>{offer?.podcastName}</Body>
            </>
          )}
          <Form
            validationSchema={validationSchema}
            validationMode={ValidationMode.OnChange}
            onSubmit={onClickSendHandler}
            className={classes.form}
          >
            <Body size={BodySize.XS}>
              {!addToAll
                ? `Make any necessary changes to the talking points. These changes will only be visible to ${offer?.podcastName}`
                : 'Make any necessary changes to the talking points'}
            </Body>
            <FormControlTextarea
              name="editTalkingPoints"
              size={TextareaSize.L}
              defaultValue={value}
            />
            {!finishedCampaign && (
              <Button
                className={classes.button}
                type={ButtonType.Submit}
                loading={loading}
              >
                Send Changes
              </Button>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

EditTalkingPointsAll.propTypes = {
  campaign: PropTypes.shape({
    talkingPoints: PropTypes.string,
  }),
  offer: PropTypes.shape({
    imageUrl: PropTypes.string,
    podcastId: PropTypes.string,
    podcastName: PropTypes.string,
    podcastImageUrl: PropTypes.string,
    talkingPoints: PropTypes.string,
  }),
  finishedCampaign: PropTypes.bool,
  addToAll: PropTypes.bool,
};

EditTalkingPointsAll.defaultProps = {
  campaign: null,
  offer: null,
  finishedCampaign: false,
  addToAll: false,
};

export default EditTalkingPointsAll;
