import { getAuth, onAuthStateChanged } from 'firebase/auth';

import {
  signUpInit,
  signUpSuccess,
  signUpFail,
  loginInit,
  loginSuccess,
  loginFail,
  verifyAuthInit,
  verifyAuthSuccess,
  verifyAuthFail,
  resetPasswordInit,
  resetPasswordSuccess,
  resetPasswordFail,
  logoutInit,
  logoutSuccess,
  logoutFail,
  newPasswordInit,
  newPasswordSuccess,
  newPasswordFail,
  editUserInit,
  editUserSuccess,
  editUserFail,
  clearEditUser,
  clearAuth,
  verifyBrandPrivilegesInit,
  verifyBrandPrivilegesSuccess,
  verifyBrandPrivilegesFail,
} from 'state/actionCreators/auth';
import signUpService from 'services/auth/signUp';
import uploadLogoService from 'services/auth/uploadLogo';
import loginService from 'services/auth/login';
import logoutService from 'services/auth/logout';
import resetPasswordService from 'services/auth/resetPassword';
import newPasswordService from 'services/auth/newPassword';
import fetchUserByIdService from 'services/users/fetchUserById';
import Errors from 'enums/errors/errors.enums';
import uploadNewLogo from 'services/auth/editUserServices/uploadNewLogo';
import editUserInformation from 'services/auth/editUserServices/editUserInformation';
import verifyBrandPrivilegesService from 'services/auth/verifyBrandPrivileges';
import createNewUserService from 'services/auth/createNewUser';
import changeUserPassword from 'services/auth/editUserServices/changeUserPassword';
import verifyBrandRole from 'services/auth/verifyBrandRole';
import { network } from 'assets/white-label';
import { Timestamp } from 'firebase/firestore';

export const signUp = (user, logo) => async (dispatch) => {
  dispatch(signUpInit());

  try {
    const userId = await signUpService(user);

    if (logo) {
      try {
        const url = await uploadLogoService({ logo, email: user.email });

        const newUser = {
          budgetInFavor: 0,
          createdAt: Timestamp.now(),
          email: user.email,
          enabled: false,
          linkedInUrl: user.linkedin,
          logoUrl: url,
          name: user.brandName,
          networks: [network],
          owner: user.name,
          phone: user.phone,
          url: user.brandURL,
          waitingForConfirmation: true,
        };

        await createNewUserService({ newUser, userId });
      } catch (error) {
        return dispatch(signUpFail({ error: error.message }));
      }
    }

    return dispatch(signUpSuccess());
  } catch (error) {
    return dispatch(signUpFail({ error: error.message }));
  }
};

export const login = ({ email, password }) => async (dispatch) => {
  dispatch(loginInit());

  try {
    const uid = await loginService({ email, password });

    if (uid) {
      const user = await fetchUserByIdService({ uid });

      return dispatch(loginSuccess({ user }));
    }

    return dispatch(loginFail({ error: Errors.InvalidRole }));
  } catch (error) {
    return dispatch(loginFail({ error: error.message }));
  }
};

export const verifyBrandPrivileges = () => async (dispatch) => {
  dispatch(verifyBrandPrivilegesInit());

  try {
    const isSuperUser = await verifyBrandPrivilegesService();

    return dispatch(verifyBrandPrivilegesSuccess({ isSuperUser }));
  } catch (error) {
    return dispatch(verifyBrandPrivilegesFail({ error: error.message }));
  }
};

export const verifyAuth = () => async (dispatch) => {
  const auth = getAuth();
  onAuthStateChanged(auth, async (user) => {
    dispatch(verifyAuthInit());
    if (user) {
      try {
        const isBrand = await verifyBrandRole();

        if (isBrand) {
          const userData = await fetchUserByIdService({ uid: user.uid });

          return dispatch(verifyAuthSuccess({ user: userData }));
        }

        return dispatch(verifyAuthFail({ error: Errors.InvalidRole }));
      } catch (error) {
        return dispatch(verifyAuthFail({ error: error.message }));
      }
    }
    return dispatch(verifyAuthFail({ error: Errors.NoAuthenticatedUser }));
  });
};

export const logout = () => async (dispatch) => {
  dispatch(logoutInit());

  try {
    await logoutService();

    return dispatch(logoutSuccess());
  } catch (error) {
    return dispatch(logoutFail({ error: error.message }));
  }
};

export const resetPassword = (email) => async (dispatch) => {
  dispatch(resetPasswordInit());

  try {
    await resetPasswordService(email);

    return dispatch(resetPasswordSuccess());
  } catch (error) {
    return dispatch(resetPasswordFail({ error: error.message }));
  }
};

export const newPassword = (code, password) => async (dispatch) => {
  dispatch(newPasswordInit());

  try {
    await newPasswordService(code, password);

    return dispatch(newPasswordSuccess());
  } catch (error) {
    return dispatch(newPasswordFail({ error: error.message }));
  }
};

export const editUser = ({ newUser, newLogo, email }) => async (
  dispatch,
  getState
) => {
  dispatch(editUserInit());

  const { user } = getState().auth;

  let newLogoPath = '';

  try {
    newLogoPath = await uploadNewLogo({ logo: newLogo, email });
  } catch (error) {
    return dispatch(editUserFail({ error: error.message }));
  }

  try {
    await editUserInformation({
      user: newUser,
      logo: newLogoPath,
      userId: user.uid,
    });
  } catch (error) {
    return dispatch(editUserFail({ error }));
  }

  try {
    await changeUserPassword({
      user: newUser,
      logo: newLogoPath,
    });
  } catch (error) {
    return dispatch(editUserFail({ error }));
  }

  try {
    const editedUser = await fetchUserByIdService({ uid: user.uid });

    return dispatch(editUserSuccess({ user: editedUser }));
  } catch (error) {
    return dispatch(
      editUserFail({
        error:
          'The user was successfully updated but there was a problem fetching the new information, please reload the page.',
      })
    );
  }
};

export const clearEditUserState = () => clearEditUser();

export const clearAuthState = () => clearAuth();
