import { collection, getDocs, query, where } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';

const fetchExistingCampaigns = async ({ brandId }) => {
  try {
    const campaignRef = collection(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns
    );

    const existingCampaignsQuery = query(
      campaignRef,
      where('finishedCreatingCampaign', '==', true)
    );

    const existingCampaignsSapshot = await getDocs(existingCampaignsQuery);

    const existingCampaigns = existingCampaignsSapshot.docs.map((doc) => ({
      ...doc.data(),
      uid: doc.id,
      offers: doc
        .data()
        .offers.filter((offer) => offer.offerStatus !== 'rejected'),
    }));

    return existingCampaigns;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchExistingCampaigns;
