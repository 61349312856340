import React from 'react';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import ossaLogo from 'assets/logos/ossa-logo.png';
import checkIcon from 'assets/icons/check-violet.svg';

import classes from './ResetPasswordConfirmation.module.scss';

const ResetPasswordConfirmation = () => (
  <div className={classes.container}>
    <div className={classes.logo}>
      <img src={ossaLogo} alt="Ossa Logo" />
    </div>
    <div className={classes.text}>
      <img src={checkIcon} alt="check" className={classes.icon} />
      <Body
        letterCase={BodyLetterCase.Uppercase}
        spacing={BodySpacing.M}
        className={classes.title}
      >
        Password recovery sent
      </Body>
      <Body
        letterCase={BodyLetterCase.Lowercase}
        size={BodySize.XS}
        className={classes.subtext}
      >
        Check your email to reset your password.
      </Body>
    </div>
    <div />
  </div>
);

export default ResetPasswordConfirmation;
