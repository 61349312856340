const getFutureDate = ({ startDate = null, days }) => {
  const actualStart = startDate || new Date();
  const startingDate = new Date(actualStart);
  const finishingDate = startingDate.getDate() + days;
  const finalDate = startingDate.setDate(finishingDate);
  const finalDateHours = new Date(finalDate).setHours(12);
  return new Date(finalDateHours);
};

export default getFutureDate;
