import {
  fetchPodcastersCountriesIsoCodesFail,
  fetchPodcastersCountriesIsoCodesInit,
  fetchPodcastersCountriesIsoCodesSuccess,
  fetchDefaultValuesInit,
  fetchDefaultValuesSuccess,
  fetchDefaultValuesFail,
  fetchHostingProvidersInit,
  fetchHostingProvidersSuccess,
  fetchHostingProvidersFail,
} from 'state/actionCreators/generals';
import fetchPodcastersCountriesIsoCodesService from 'services/general/fetchPodcastersCountriesIsoCodes';
import fetchDefaultValuesService from 'services/general/fetchDefaultValues';
import fetchHostingProvidersService from 'services/general/fetchHostingProviders';

export const fetchPodcastersCountriesIsoCodes = () => async (dispatch) => {
  dispatch(fetchPodcastersCountriesIsoCodesInit());

  try {
    const podcastersIsoCodes = await fetchPodcastersCountriesIsoCodesService();

    return dispatch(
      fetchPodcastersCountriesIsoCodesSuccess({ podcastersIsoCodes })
    );
  } catch (error) {
    return dispatch(
      fetchPodcastersCountriesIsoCodesFail({ error: error.message })
    );
  }
};

export const fetchDefaultValues = (network) => async (dispatch) => {
  dispatch(fetchDefaultValuesInit());

  try {
    const defaultValues = await fetchDefaultValuesService(network);

    return dispatch(fetchDefaultValuesSuccess({ defaultValues }));
  } catch (error) {
    return dispatch(fetchDefaultValuesFail({ error: error.message }));
  }
};

export const fetchHostingProviders = () => async (dispatch) => {
  dispatch(fetchHostingProvidersInit());

  try {
    const hostingProviders = await fetchHostingProvidersService();
    return dispatch(fetchHostingProvidersSuccess({ hostingProviders }));
  } catch (error) {
    return dispatch(fetchHostingProvidersFail({ error: error.message }));
  }
};
