import { createAction } from '@reduxjs/toolkit';

export const fetchPodcastersCountriesIsoCodesInit = createAction(
  '[Generals] Fetch Podcasters Countries Iso Codes Init'
);
export const fetchPodcastersCountriesIsoCodesSuccess = createAction(
  '[Generals] Fetch Podcasters Countries Iso Codes Success'
);
export const fetchPodcastersCountriesIsoCodesFail = createAction(
  '[Generals] Fetch Podcasters Countries Iso Codes Fail'
);

export const fetchDefaultValuesInit = createAction(
  '[Generals] Fetch Default Values Init'
);

export const fetchDefaultValuesSuccess = createAction(
  '[Generals] Fetch Default Values Success'
);

export const fetchDefaultValuesFail = createAction(
  '[Generals] Fetch Default Values Fail'
);

export const fetchHostingProvidersInit = createAction(
  '[Generals] Fetch Hosting Providers Init'
);

export const fetchHostingProvidersSuccess = createAction(
  '[Generals] Fetch Hosting Providers Success'
);

export const fetchHostingProvidersFail = createAction(
  '[Generals] Fetch Hosting Providers Fail'
);
