import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import { useSelector, shallowEqual } from 'react-redux';

import NavigationLayout from 'components/Layouts/NavigationLayout';
import RestrictedRoute from 'components/Navigation/RestrictedRoute';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import Path from 'enums/path.enum';

/* eslint-disable react/prop-types */
const CheckUserVerification = ({
  waitingForConfirmation,
  enabled,
  Component,
  renderProps,
}) => {
  if (waitingForConfirmation || (!waitingForConfirmation && !enabled)) {
    return <Redirect to={Path.ThanksForApplying} />;
  }

  return (
    <NavigationLayout>
      <RestrictedRoute component={Component} {...renderProps} />
    </NavigationLayout>
  );
};
/* eslint-enable react/prop-types */

const AuthenticatedRoute = ({ component: Component, ...rest }) => {
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const isLoggedIn = useMemo(() => !!user, [user]);

  return (
    <Route
      {...rest}
      render={(props) =>
        isLoggedIn ? (
          <CheckUserVerification
            waitingForConfirmation={user?.waitingForConfirmation}
            Component={Component}
            enabled={user?.enabled}
            renderProps={props}
          />
        ) : (
          <Redirect to={Path.Login} />
        )
      }
    />
  );
};

AuthenticatedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
};

export default AuthenticatedRoute;
