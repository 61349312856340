import { doc, getFirestore, getDoc } from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchPodcastersCountriesIsoCodes = async () => {
  try {
    const firestore = getFirestore();

    const countriesRef = doc(
      firestore,
      Collections.General,
      'podcastersCountries'
    );

    const countries = await getDoc(countriesRef);

    return countries.data().isoCodes;
  } catch (error) {
    return error;
  }
};

export default fetchPodcastersCountriesIsoCodes;
