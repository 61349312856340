import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';

const fetchCheckoutSession = async ({ session }) => {
  try {
    const getCheckout = httpsCallable(
      functions,
      'campaigns-getCheckout'
    );

    return await getCheckout({id: session});
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchCheckoutSession;