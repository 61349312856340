import { firestore } from 'firebase';
import { doc, updateDoc } from 'firebase/firestore';
import Collections from 'enums/firebase/collections.enum';

const sendAdCopy = async ({
  brandId,
  campaign,
  podcastId,
  adCopy,
  addToAll = false,
}) => {
  try {
    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaign.uid
    );

    const newOffers = campaign.offers.map((offer) => {
      if (addToAll || offer.podcastId === podcastId) {
        return { ...offer, adCopy };
      }
      return offer;
    });

    await updateDoc(campaignRef, { offers: newOffers });
  } catch (error) {
    throw new Error(error);
  }
};

export default sendAdCopy;
