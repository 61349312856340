import {
  collection,
  query,
  getDocs,
  limit,
  where,
  orderBy,
  startAfter,
  and,
  or,
} from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';
import { STATUS_FINISHED } from 'utils/Campaign/status';

const fetchAllFinishedCampaigns = async ({
  brandId,
  lastDoc,
  searchValue,
  selectedSort = {},
}) => {
  try {
    const campaignRef = collection(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns
    );

    let finishedCampaignsQuery;

    if (searchValue.length > 0) {
      finishedCampaignsQuery = query(
        campaignRef,
        or(
          and(
            where('name', '>=', searchValue),
            where('name', '<=', `${searchValue}\uf8ff`),
            where('status', '==', STATUS_FINISHED)
          ),
          and(
            where(
              'name',
              '>=',
              searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
            ),
            where(
              'name',
              '<=',
              `${
                searchValue.charAt(0).toUpperCase() + searchValue.slice(1)
              }\uf8ff`
            ),
            where('status', '==', STATUS_FINISHED)
          ),
          and(
            where('name', '>=', searchValue.toLowerCase()),
            where('name', '<=', `${searchValue.toLowerCase()}\uf8ff`),
            where('status', '==', STATUS_FINISHED)
          )
        ),
        orderBy('name', 'asc'),
        limit(10)
      );
    } else if (selectedSort.value) {
      finishedCampaignsQuery = query(
        campaignRef,
        where('status', '==', STATUS_FINISHED),
        orderBy(selectedSort.sortBy, selectedSort.sortOrder),
        limit(10)
      );
    } else {
      finishedCampaignsQuery = query(
        campaignRef,
        where('status', '==', STATUS_FINISHED),
        orderBy('airingTo', 'desc'),
        limit(10)
      );
    }

    if (lastDoc) {
      finishedCampaignsQuery = query(
        finishedCampaignsQuery,
        startAfter(lastDoc)
      );
    }

    const finishedCampaignsSnapshot = await getDocs(finishedCampaignsQuery);

    const lastDocInBatch =
      finishedCampaignsSnapshot.docs[finishedCampaignsSnapshot.docs.length - 1];

    const finishCampaigns = finishedCampaignsSnapshot.docs.map((doc) => ({
      ...doc.data(),
      uid: doc.id,
    }));

    return {
      finishCampaigns,
      lastDoc: lastDocInBatch,
    };
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchAllFinishedCampaigns;
