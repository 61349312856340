import { createAction } from '@reduxjs/toolkit';

export const fetchPodcastsInit = createAction('[Podcasts] Fetch Podcasts Init');
export const fetchPodcastsSuccess = createAction(
  '[Podcasts] Fetch Podcasts Success'
);
export const fetchPodcastsFail = createAction('[Podcasts] Fetch Podcasts Fail');
export const fetchPodcastsEstimationsInit = createAction(
  '[Podcasts] Fetch Podcasts Estimations Init'
);
export const fetchPodcastsEstimationsSuccess = createAction(
  '[Podcasts] Fetch Podcasts Estimations Success'
);
export const fetchPodcastsEstimationsFail = createAction(
  '[Podcasts] Fetch Podcasts Estimations Fail'
);
export const clearEstimationsPodcasts = createAction(
  '[Podcasts] Clear Podcasts Estimations'
);
