import { collection, getDocs } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';

const fetchPodcastHistory = async ({ brandId }) => {
  try {
    const podcastsRef = collection(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Podcasts
    );

    const podcastsSapshot = await getDocs(podcastsRef);

    const podcastsData = podcastsSapshot.docs.map((doc) => doc.data());

    return podcastsData;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchPodcastHistory;
