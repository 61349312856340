import Tab from 'components/Common/Tab';
import Tabs from 'components/Common/Tabs';
import classNames from 'classnames';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import { useEffect, useState } from 'react';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import { useDispatch } from 'react-redux';
import { clearEstimationsPodcastsState } from 'state/actions/podcasts';
import CampaignPodcastsForm from '../CampaignPodcastsForm';
import CampaignCategoriesForm from '../CampaignCategoriesForm';
import classes from './CampaignCategoriesAndPodcastsForm.module.scss';

const DefaultTabs = {
  Categories: 'Categories',
  Podcasts: 'Podcasts',
};

const CampaignCategoriesAndPodcastsForm = () => {
  const dispatch = useDispatch();
  const [tabs, setTabs] = useState(DefaultTabs.Categories);
  const [podcasts, setPodcasts] = useState({});
  const [categories, setCategories] = useState({});

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  useEffect(() => {
    if (Object.keys(podcasts).length === 0) {
      dispatch(clearEstimationsPodcastsState());
    }
  }, [podcasts]);

  return (
    <div className={classNames(commonClasses.form, classes.form)}>
      <Body size={BodySize.XS} color={BodyColor.Black}>
        You can filter by categories or specific podcasts. You can skip this
        step if you wish.
      </Body>
      <Tabs
        initialActiveTab={tabs !== DefaultTabs.Categories ? tabs : null}
        className={classes.customTabs}
      >
        <Tab
          onClick={() => setTabs(DefaultTabs.Categories)}
          tab={DefaultTabs.Categories}
          key={DefaultTabs.Categories}
        >
          <CampaignCategoriesForm
            categories={categories}
            setCategories={setCategories}
            podcasts={podcasts}
            setPodcasts={setPodcasts}
          />
        </Tab>
        <Tab
          onClick={() => setTabs(DefaultTabs.Podcasts)}
          tab={DefaultTabs.Podcasts}
          key={DefaultTabs.Podcasts}
        >
          <CampaignPodcastsForm
            categories={categories}
            setCategories={setCategories}
            podcasts={podcasts}
            setPodcasts={setPodcasts}
          />
        </Tab>
      </Tabs>
    </div>
  );
};

export default CampaignCategoriesAndPodcastsForm;
