/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchEnabledUsersInit,
  fetchEnabledUsersSuccess,
  fetchEnabledUsersFail,
  fetchUserByIdInit,
  fetchUserByIdSuccess,
  fetchUserByIdFail,
  fetchSelectedUsersInit,
  fetchSelectedUsersSuccess,
  fetchSelectedUsersFail,
  clearUsers,
} from 'state/actionCreators/users';

export const initialState = {
  fetchEnabledUsersLoading: false,
  fetchEnabledUsersSuccess: false,
  fetchEnabledUsersError: null,
  fetchUserByIdLoading: false,
  fetchUserByIdSuccess: false,
  fetchUserByIdError: null,
  fetchPodcastUserByIdSuccess: false,
  fetchPodcastUserByIdLoading: false,
  fetchPodcastUserByIdError: null,
  fetchSelectedUsersLoading: false,
  fetchSelectedUsersSuccess: false,
  fetchSelectedUsersError: null,
  users: [],
  lastUser: null,
  selectedUsers: [],
  podcastUser: null,
  user: null,
};

const usersReducer = createReducer(initialState, {
  [fetchEnabledUsersInit]: (state) => {
    state.fetchEnabledUsersLoading = true;
    state.fetchEnabledUsersSuccess = false;
    state.fetchEnabledUsersError = null;
    state.users = [];
  },
  [fetchEnabledUsersSuccess]: (state, { payload }) => {
    const { users, lastUser } = payload;
    state.fetchEnabledUsersLoading = false;
    state.fetchEnabledUsersSuccess = true;
    state.fetchEnabledUsersError = null;
    state.users = users;
    state.lastUser = lastUser;
  },
  [fetchEnabledUsersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchEnabledUsersLoading = false;
    state.fetchEnabledUsersSuccess = false;
    state.fetchEnabledUsersError = error;
    state.users = [];
  },
  [fetchUserByIdInit]: (state) => {
    state.fetchUserByIdLoading = true;
    state.fetchUserByIdSuccess = false;
    state.fetchUserByIdError = null;
    state.user = null;
  },
  [fetchUserByIdSuccess]: (state, { payload }) => {
    const { user } = payload;
    state.fetchUserByIdLoading = false;
    state.fetchUserByIdSuccess = true;
    state.fetchUserByIdError = null;
    state.user = user;
  },
  [fetchUserByIdFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchUserByIdLoading = false;
    state.fetchUserByIdSuccess = false;
    state.fetchUserByIdError = error;
    state.user = null;
  },
  [fetchSelectedUsersInit]: (state) => {
    state.fetchSelectedUsersLoading = true;
    state.fetchSelectedUsersSuccess = false;
    state.fetchSelectedUsersError = null;
    state.selectedUsers = [];
  },
  [fetchSelectedUsersSuccess]: (state, { payload }) => {
    const { users } = payload;
    state.fetchSelectedUsersLoading = false;
    state.fetchSelectedUsersSuccess = true;
    state.fetchSelectedUsersError = null;
    state.selectedUsers = users;
  },
  [fetchSelectedUsersFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchSelectedUsersLoading = false;
    state.fetchSelectedUsersSuccess = false;
    state.fetchSelectedUsersError = error;
    state.selectedUsers = [];
  },
  [clearUsers]: (state) => {
    state.fetchEnabledUsersSuccess = false;
    state.fetchEnabledUsersError = null;
    state.fetchSelectedUsersSuccess = false;
    state.fetchSelectedUsersError = null;
    state.users = [];
    state.selectedUsers = [];
    state.lastUser = null;
  },
});

export default usersReducer;
