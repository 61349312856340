import * as yup from 'yup';

import Required from 'enums/schemas/required.enum';
import { PATTERN_TWO_DECIMALS_AFTER_COMMA } from 'constants/patterns';

const CampaignBudgetFormSchema = ({ max, isDynamicTimeAd }) => {
  const schemaFields = {
    budget: yup
      .number()
      .transform((value, originalValue) => {
        if (
          originalValue?.toString()?.trim() === '' ||
          Number(originalValue) <= 0
        ) {
          return null;
        }

        return value;
      })
      .nullable()
      .required(Required.Budget),
    cpmRate: yup
      .number()
      .transform((_, val) => (val === '' ? null : Number(val)))
      .nullable()
      .moreThan(-1, 'CPM Rate must be greater than or equal to 0')
      .test({
        name: 'interdependency1',
        message: 'If Number of Episodes is set, CPM Rate is required',
        test: (val, context) => {
          const { numberOfEpisodes } = context.parent;
          const isCpmRateInvalid = val === 0 ? false : !val;
          if (numberOfEpisodes && isCpmRateInvalid) {
            return false;
          }

          return true;
        },
      })
      .test({
        name: 'is-decimal',
        message:
          'The amount should be a decimal with maximum two digits after comma',
        test: (val, context) => {
          const { numberOfEpisodes } = context.parent;

          if (numberOfEpisodes && val !== undefined) {
            return PATTERN_TWO_DECIMALS_AFTER_COMMA.test(val);
          }

          return true;
        },
      }),
  };

  if (!isDynamicTimeAd) {
    schemaFields.numberOfEpisodes = yup
      .number()
      .transform((_, val) => (val === '' || val === 0 ? null : Number(val)))
      .nullable()
      .moreThan(0, 'Number of Episodes must be greater than or equal to 1')
      .max(
        max,
        `Number of Episodes must be less or equal than the campaign days (${max})`
      )
      .test({
        name: 'interdependency2',
        message: 'If CPM Rate is set, Number of Episodes is required',
        test: (val, context) => {
          const { cpmRate } = context.parent;

          if (cpmRate && !val) {
            return false;
          }

          return true;
        },
      });
  }

  return yup.object().shape(schemaFields);
};

export default CampaignBudgetFormSchema;
