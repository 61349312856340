import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { shallowEqual, useSelector, useDispatch } from 'react-redux';
import { useHistory, useParams, Redirect } from 'react-router-dom';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import SelectedPodcast from 'components/Pages/Campaign/SelectedPodcast';
import Spinner from 'components/Common/Spinner';
import IconButton from 'components/Common/IconButton';
import Budget from 'components/Pages/Campaign/Budget';
import Summary from 'components/Pages/Campaign/Summary';
import Button from 'components/Common/Button';
import Modal from 'components/Common/Modal';
import Confirmation from 'components/Common/Confirmation';
import Toast from 'components/Common/Toast';
import {
  addOffers,
  clearCampaignState,
  fetchCampaignById,
} from 'state/actions/campaigns';
import {
  selectAddOffersState,
  selectFetchCampaignByIdState,
} from 'state/selectors/campaigns';
import {
  createCheckoutSession,
  saveCheckoutSession,
} from 'state/actions/stripe';
import {
  selectCreateCheckoutSessionState,
  selectSaveCheckoutSessionState,
} from 'state/selectors/stripe';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import Path from 'enums/path.enum';
import campaignAdTypes from 'enums/campaign/campaignAdTypes.enum';
import ModalType from 'enums/modal/modalTypes';
import createStripeCheckout from 'utils/functions/createStripeCheckout';
import getCampaignSummaryData from 'utils/functions/setCampaignSummaryData';
import useModal from 'hooks/useModal';
import { CPM_MODIFIER_EMAILS } from 'constants/campaign';

import classes from './CampaignSummary.module.scss';

const CampaignSummary = () => {
  const dispatch = useDispatch();
  const history = useHistory();
  const { error, campaignId } = useParams();

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const {
    campaign,
    success: successFetchingCampaign,
    loading: loadingFetchingCampaign,
  } = useSelector(selectFetchCampaignByIdState, shallowEqual);

  const { loading, success, error: errorAddingOffers } = useSelector(
    selectAddOffersState,
    shallowEqual
  );

  const {
    loading: loadingSavingSession,
    success: successSavingSession,
    error: errorSavingSession,
  } = useSelector(selectSaveCheckoutSessionState, shallowEqual);

  const {
    loading: loadingCheckout,
    success: successCheckout,
    error: errorCheckout,
    session,
  } = useSelector(selectCreateCheckoutSessionState, shallowEqual);

  const [adTypes, setAdTypes] = useState(null);
  const [budgets, setBudgets] = useState(null);
  const [allBudgetsSum, setAllBudgetsSum] = useState(0);
  const [impressions, setImpressions] = useState(null);
  const [episodes, setEpisodes] = useState(null);
  const [adTypesErrorMessage, setAdTypesErrorMessage] = useState(null);

  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const [showError, setShowError] = useState(!!error);

  useEffect(() => {
    if (campaignId) {
      dispatch(fetchCampaignById({ campaignId }));
    }
  }, [campaignId]);

  useEffect(() => {
    if (campaign && successFetchingCampaign) {
      const {
        podcastsAdTypes,
        podcastsBudgets,
        podcastsImpressions,
        podcastsEpisodes,
        allBadgets,
      } = getCampaignSummaryData({ campaign });

      setAllBudgetsSum(allBadgets);
      setAdTypes(podcastsAdTypes);
      setBudgets(podcastsBudgets);
      setImpressions(podcastsImpressions);
      setEpisodes(podcastsEpisodes);
    }
  }, [campaign, successFetchingCampaign]);

  useEffect(() => {
    if (success && campaignId) {
      dispatch(fetchCampaignById({ campaignId }));
      onCloseModalHandler();
    }
  }, [success, campaignId]);

  useEffect(() => {
    if (showError) {
      setShowError(false);
    }
  }, [showError]);

  useEffect(() => {
    if (successCheckout) {
      if (session) {
        dispatch(saveCheckoutSession());
      } else {
        history.push(`${Path.Submitted}/${campaign.uid}`);
      }
    }
  }, [session, successCheckout]);

  useEffect(() => {
    if (successFetchingCampaign && !campaign) {
      history.push(Path.Campaigns);
    }
  }, [successFetchingCampaign, campaign]);

  useEffect(() => {
    if (budgets) {
      const newTotalBudget = Object.values(budgets).reduce(
        (totalBudgets, budget) => totalBudgets + budget,
        0
      );
      setAllBudgetsSum(Number(newTotalBudget.toFixed(2)));
    }
  }, [budgets]);

  const onClickAdTypeHandler = useCallback(
    (podcastId, adType) => {
      const newState = { ...adTypes };
      const prevAdType = newState[podcastId][adType];
      newState[podcastId][adType] = !prevAdType;
      setAdTypes(newState);
      setAdTypesErrorMessage(null);
    },
    [adTypes]
  );

  const onDeletePodcastHandler = useCallback((offer) => {
    onOpenModalHandler(ModalType.DELETE_PODCAST, {
      offer,
    });
  }, []);

  const onAcceptDeleteHandler = useCallback(() => {
    const newOffers = [...campaign.offers];
    const deleteIndex = newOffers.findIndex(
      (offer) => offer.podcastId === modal.offer.podcastId
    );
    newOffers.splice(deleteIndex, 1);
    dispatch(
      addOffers({
        campaignId: campaign.uid,
        offers: { offers: newOffers },
      })
    );
  }, [campaign, modal]);

  const onChangeBudgetHandler = useCallback((value, podcastId) => {
    setBudgets((prevState) => ({ ...prevState, [podcastId]: Number(value) }));
  }, []);

  const onCreateCheckoutHandler = useCallback(() => {
    const selectedAdTypes = Object.values(adTypes).every(
      (ad) =>
        ad[campaignAdTypes.preRoll] ||
        ad[campaignAdTypes.midRoll] ||
        ad[campaignAdTypes.postRoll] ||
        ad[campaignAdTypes.backCatalog] ||
        ad[campaignAdTypes.bakedIn] ||
        ad[campaignAdTypes.dynamicAd]
    );

    if (selectedAdTypes) {
      const { offers, newOffers } = createStripeCheckout({
        campaign,
        budgets,
        adTypes,
      });

      let updatedOffers = newOffers;

      if (CPM_MODIFIER_EMAILS.includes(user.email)) {
        updatedOffers = newOffers.map((offer) => {
          const newOffer = structuredClone(offer);
          newOffer.amount = Number(budgets[offer.podcastId]).toFixed(2);
          return newOffer;
        });
      }

      dispatch(
        addOffers({
          campaignId: campaign.uid,
          offers: { offers: updatedOffers },
        })
      );

      dispatch(createCheckoutSession({ offers }));
    } else {
      setAdTypesErrorMessage('Please select each offer ad type.');
    }
  }, [campaign, budgets, adTypes]);

  const onClickAddPodcastHandler = useCallback(() => {
    dispatch(clearCampaignState());
    history.push(`${Path.Campaigns}/${campaignId}`);
  }, [campaignId]);

  const redirectCheckout = useMemo(
    () => successSavingSession && <Redirect to={Path.Checkout} />,
    [successSavingSession]
  );

  const redirectNoCampaignId = useMemo(
    () => successFetchingCampaign && !campaign && <Redirect to={Path.Home} />,
    [campaignId]
  );

  return (
    <>
      {redirectNoCampaignId}
      {redirectCheckout}
      <Modal
        isOpen={modal.type === ModalType.DELETE_PODCAST}
        onClose={onCloseModalHandler}
        className={classes.deletePodcastModal}
      >
        <Confirmation
          title="Delete podcast"
          description="Are you sure you want to delete the podcast from your selected podcasts list?"
          onAccept={onAcceptDeleteHandler}
          onCancel={onCloseModalHandler}
          loading={loading}
        />
      </Modal>
      {showError && (
        <Toast
          text="There was a problem processing the payment, please try again"
          id="Stripe error"
        />
      )}
      {errorAddingOffers && (
        <Toast text={errorAddingOffers} id="Delete podcast error" />
      )}
      {errorCheckout && (
        <Toast text={errorCheckout} id="Create checkout session error" />
      )}
      {errorSavingSession && (
        <Toast text={errorSavingSession} id="Save checkout session error" />
      )}
      <div className={classes.container}>
        <Body
          letterCase={BodyLetterCase.Uppercase}
          spacing={BodySpacing.M}
          className={classes.title}
        >
          Campaign summary
        </Body>
        {!adTypes || !budgets || loadingFetchingCampaign ? (
          <Spinner className={classes.spinner} />
        ) : (
          <div className={classes.components}>
            <div className={classes.summary}>
              <Summary campaign={campaign} />
            </div>
            <div className={classes.selectedPodcasts}>
              <Body
                size={BodySize.XS}
                className={classes.selectedPodcastsTitle}
              >
                Selected Podcasts
              </Body>
              {campaign.offers
                .filter((offer) => !offer.isPaid)
                .map((offer) => (
                  <SelectedPodcast
                    key={offer.podcastId}
                    podcast={offer}
                    adTypes={adTypes[offer.podcastId]}
                    onClickAdType={onClickAdTypeHandler}
                    budget={budgets[offer.podcastId]}
                    impressions={impressions[offer.podcastId]}
                    episodes={episodes[offer.podcastId]}
                    onDelete={onDeletePodcastHandler}
                    onChangeBudget={onChangeBudgetHandler}
                    email={user.email}
                  />
                ))}
              <IconButton
                className={classes.addPodcast}
                onClick={onClickAddPodcastHandler}
              >
                <Body size={BodySize.XS}>+ Add podcast</Body>
              </IconButton>
              <Budget
                currentBudget={allBudgetsSum}
                totalBudget={campaign.budget}
                budgetInFavor={user.budgetInFavor || 0}
              />
              <Button
                className={classes.checkoutButton}
                onClick={onCreateCheckoutHandler}
                loading={loadingCheckout || loadingSavingSession}
                disabled={
                  campaign.offers.filter((offer) => !offer.isPaid).length === 0
                }
              >
                Checkout
              </Button>
              {adTypesErrorMessage && (
                <Body size={BodySize.XS} className={classes.adTypeError}>
                  {adTypesErrorMessage}
                </Body>
              )}
            </div>
          </div>
        )}
      </div>
    </>
  );
};

export default CampaignSummary;
