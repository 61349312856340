import { useState } from 'react';

const useCancellableTimeout = () => {
  const [inTimeout, setInTimeout] = useState(false);

  const [timer, setTimer] = useState(null);

  const createTimeout = (callback, time) => {
    if (timer) {
      clearTimeout(timer);
    }

    setInTimeout(true);

    const newTimer = setTimeout(() => {
      setInTimeout(false);

      return callback();
    }, time);

    setTimer(newTimer);
  };

  return {
    inTimeout,
    createTimeout,
  };
};

export default useCancellableTimeout;
