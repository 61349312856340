import { getAuth } from 'firebase/auth';

const verifyBrandPrivileges = async () => {
  try {
    const auth = getAuth();

    if (auth.currentUser) {
      const { claims } = await auth.currentUser.getIdTokenResult(true);

      return !!claims?.superUser;
    }

    return false;
  } catch (error) {
    throw new Error(error);
  }
};

export default verifyBrandPrivileges;
