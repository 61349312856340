import { useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { shallowEqual, useSelector } from 'react-redux';

import { getCampaignDescriptionInputs } from 'utils/inputs/createCampaignInputs';
import {
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import { selectFetchEstimationPodcastsState } from 'state/selectors/podcasts';
import validationSchema from './CampaignDescriptionForm.schema';
import classes from './CampaignDescriptionForm.module.scss';

const CampaignDescriptionForm = () => {
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const { estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { loading, estimationsPodcasts } = useSelector(
    selectFetchEstimationPodcastsState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const defaultData = formsData[currentStep] ?? campaign;

  const campaignDescriptionFields = useMemo(
    () => getCampaignDescriptionInputs({ defaultValues: defaultData }),
    [defaultData]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          This is where you will be able to include all the details of your
          campaign. Be as detailed as possible. So the podcast understands what
          your campaign is about.
          <br />
          <a
            href="https://help.ossacollective.com/en/articles/6495887-get-started-guide-for-advertisers#h_a6ef2db82d"
            rel="noreferrer"
            target="_blank"
            className={commonClasses.link}
          >
            Learn More
          </a>
        </Body>
        <div className={classes.fields}>
          {campaignDescriptionFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>

      {estimationsPodcasts > 0 &&
      Object.values(formsData).find((elem) => elem.podcasts).podcasts.length >
        0 ? (
        <EstimatedImpressions
          className={classes.estimatedImpressions}
          titleClassName={classes.estimatedImpressionsTitle}
          textClassName={classes.estimatedImpressionsText}
          estimatedImpressions={estimationsPodcasts}
          loading={loading}
          onlyEstimations
        />
      ) : (
        <EstimatedImpressions
          className={classes.estimatedImpressions}
          titleClassName={classes.estimatedImpressionsTitle}
          textClassName={classes.estimatedImpressionsText}
          potentialPodcasts={potentialPodcasts}
          estimatedImpressions={estimatedImpressions}
        />
      )}
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={goBack}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignDescriptionForm;
