import PropTypes from 'prop-types';
import parse from 'html-react-parser';

import Button from 'components/Common/Button';
import Body, { Size as BodySize } from 'components/Typography/Body';

import classes from './NotesToTheBrand.module.scss';

const NotesToTheBrand = ({ offer, selected, onSelect, onClose }) => {
  if (!offer) return null;
  const { podcastName, podcastImageUrl, coverLetter } = offer;

  return (
    <div className={classes.container}>
      <div className={classes.top}>
        <Body> Notes to the brand </Body>
        <img src={podcastImageUrl} alt={podcastName} className={classes.img} />
        <Body size={BodySize.XS}> {podcastName} </Body>
      </div>
      <p className={classes.notesToTheBrand}> {parse(coverLetter)} </p>
      <Button
        className={classes.button}
        onClick={() => {
          onSelect(offer);
          onClose();
        }}
      >
        {selected ? 'Added' : 'Add'}
      </Button>
    </div>
  );
};

NotesToTheBrand.propTypes = {
  offer: PropTypes.shape({
    image: PropTypes.string,
    podcastImageUrl: PropTypes.string,
    podcastName: PropTypes.string,
    budget: PropTypes.number,
    adTypes: PropTypes.arrayOf(PropTypes.string),
    adCopy: PropTypes.shape({
      copy: PropTypes.string,
      notes: PropTypes.string,
    }),
    coverLetter: PropTypes.string,
  }).isRequired,
  selected: PropTypes.bool.isRequired,
  onSelect: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
};

export default NotesToTheBrand;
