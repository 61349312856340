/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  signUpInit,
  signUpSuccess,
  signUpFail,
  loginInit,
  loginSuccess,
  loginFail,
  verifyAuthInit,
  verifyAuthSuccess,
  verifyAuthFail,
  logoutInit,
  logoutSuccess,
  logoutFail,
  resetPasswordInit,
  resetPasswordSuccess,
  resetPasswordFail,
  newPasswordInit,
  newPasswordSuccess,
  newPasswordFail,
  editUserInit,
  editUserSuccess,
  editUserFail,
  clearEditUser,
  clearAuth,
  verifyBrandPrivilegesInit,
  verifyBrandPrivilegesSuccess,
  verifyBrandPrivilegesFail,
} from 'state/actionCreators/auth';

export const initialState = {
  signUpLoading: false,
  signUpSuccess: false,
  signUpError: null,
  loginLoading: false,
  loginSuccess: false,
  loginError: null,
  verifyBrandPrivilegesLoading: false,
  verifyBrandPrivilegesSuccess: false,
  verifyBrandPrivilegesError: null,
  verifyAuthLoading: false,
  verifyAuthSuccess: false,
  verifyAuthError: null,
  logoutLoading: false,
  logoutSuccess: false,
  logoutError: null,
  resetPasswordLoading: false,
  resetPasswordSuccess: false,
  resetPasswordError: null,
  newPasswordLoading: false,
  newPasswordSuccess: false,
  newPasswordError: null,
  editUserLoading: false,
  editUserSuccess: false,
  editUserError: null,
  user: null,
};

const authReducer = createReducer(initialState, {
  [signUpInit]: (state) => {
    state.signUpLoading = true;
    state.signUpSuccess = false;
    state.signUpError = null;
  },
  [signUpSuccess]: (state) => {
    state.signUpLoading = false;
    state.signUpSuccess = true;
    state.signUpError = null;
  },
  [signUpFail]: (state, { payload }) => {
    const { error } = payload;
    state.signUpLoading = false;
    state.signUpSuccess = false;
    state.signUpError = error;
  },
  [loginInit]: (state) => {
    state.loginLoading = true;
    state.loginSuccess = false;
    state.loginError = null;
  },
  [loginSuccess]: (state, { payload }) => {
    const { user } = payload;
    state.loginLoading = false;
    state.loginSuccess = true;
    state.loginError = null;
    state.user = user;
  },
  [loginFail]: (state, { payload }) => {
    const { error } = payload;
    state.loginLoading = false;
    state.loginSuccess = false;
    state.loginError = error;
  },
  [verifyBrandPrivilegesInit]: (state) => {
    state.verifyBrandPrivilegesLoading = true;
    state.verifyBrandPrivilegesSuccess = false;
    state.verifyBrandPrivilegesError = null;
  },
  [verifyBrandPrivilegesSuccess]: (state, { payload }) => {
    const { isSuperUser } = payload;
    state.verifyBrandPrivilegesLoading = false;
    state.verifyBrandPrivilegesSuccess = true;
    state.verifyBrandPrivilegesError = null;
    state.user = { ...state.user, isSuperUser };
  },
  [verifyBrandPrivilegesFail]: (state, { payload }) => {
    const { error } = payload;
    state.verifyBrandPrivilegesLoading = false;
    state.verifyBrandPrivilegesSuccess = false;
    state.verifyBrandPrivilegesError = error;
  },
  [verifyAuthInit]: (state) => {
    state.verifyAuthLoading = true;
    state.verifyAuthSuccess = false;
    state.verifyAuthError = null;
  },
  [verifyAuthSuccess]: (state, { payload }) => {
    const { user } = payload;
    state.verifyAuthLoading = false;
    state.verifyAuthSuccess = true;
    state.verifyAuthError = null;
    state.user = user;
  },
  [verifyAuthFail]: (state, { payload }) => {
    const { error } = payload;
    state.verifyAuthLoading = false;
    state.verifyAuthSuccess = false;
    state.verifyAuthError = error;
  },
  [logoutInit]: (state) => {
    state.logoutLoading = true;
    state.logoutSuccess = false;
    state.logoutError = null;
  },
  [logoutSuccess]: (state) => {
    state.logoutLoading = false;
    state.logoutSuccess = true;
    state.logoutError = null;
    state.user = null;
  },
  [logoutFail]: (state, { payload }) => {
    const { error } = payload;
    state.logoutLoading = false;
    state.logoutSuccess = false;
    state.logoutError = error;
  },
  [resetPasswordInit]: (state) => {
    state.resetPasswordLoading = true;
    state.resetPasswordSuccess = false;
    state.resetPasswordError = null;
  },
  [resetPasswordSuccess]: (state) => {
    state.resetPasswordLoading = false;
    state.resetPasswordSuccess = true;
    state.resetPasswordError = null;
  },
  [resetPasswordFail]: (state, { payload }) => {
    const { error } = payload;
    state.resetPasswordLoading = false;
    state.resetPasswordSuccess = false;
    state.resetPasswordError = error;
  },
  [newPasswordInit]: (state) => {
    state.newPasswordLoading = true;
    state.newPasswordSuccess = false;
    state.newPasswordError = null;
  },
  [newPasswordSuccess]: (state) => {
    state.newPasswordLoading = false;
    state.newPasswordSuccess = true;
    state.newPasswordError = null;
  },
  [newPasswordFail]: (state, { payload }) => {
    const { error } = payload;
    state.newPasswordLoading = false;
    state.newPasswordSuccess = false;
    state.newPasswordError = error;
  },
  [editUserInit]: (state) => {
    state.editUserLoading = true;
    state.editUserSuccess = false;
    state.editUserError = null;
  },
  [editUserSuccess]: (state, { payload }) => {
    const { user } = payload;
    state.editUserLoading = false;
    state.editUserSuccess = true;
    state.editUserError = null;
    state.user = user;
  },
  [editUserFail]: (state, { payload }) => {
    const { error } = payload;
    state.editUserLoading = false;
    state.editUserSuccess = false;
    state.editUserError = error;
  },
  [editUserFail]: (state, { payload }) => {
    const { error } = payload;
    state.editUserLoading = false;
    state.editUserSuccess = false;
    state.editUserError = error;
  },
  [clearEditUser]: (state) => {
    state.editUserLoading = false;
    state.editUserSuccess = false;
    state.editUserError = null;
  },
  [clearAuth]: (state) => {
    state.signUpLoading = false;
    state.signUpSuccess = false;
    state.signUpError = null;
    state.loginLoading = false;
    state.loginSuccess = false;
    state.loginError = null;
    state.logoutLoading = false;
    state.logoutSuccess = false;
    state.logoutError = null;
  },
});

export default authReducer;
