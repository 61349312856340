import React, { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import History from 'components/Pages/Podcast/History';
import Spinner from 'components/Common/Spinner';
import Toast from 'components/Common/Toast';
import Body, {
  LetterCase as BodyLetterCase,
  Size as BodySize,
} from 'components/Typography/Body';
import { fetchPodcastHistory } from 'state/actions/settings';
import { selectFetchPodcastHistoryState } from 'state/selectors/settings';

import classes from './PodcastHistory.module.scss';

const PodcastHistory = () => {
  const dispatch = useDispatch();

  const { podcasts, success, loading, error } = useSelector(
    selectFetchPodcastHistoryState,
    shallowEqual
  );

  const [podcastHistory, setPodcastHistory] = useState([]);

  useEffect(() => {
    dispatch(fetchPodcastHistory());
  }, []);

  useEffect(() => {
    if (success && podcasts.length > 0 && podcastHistory.length === 0) {
      const transformedPodcasts = podcasts.map((podcast) => {
        const allCampaigns = podcast.campaigns.join(', ');
        return { ...podcast, campaigns: allCampaigns };
      });
      setPodcastHistory(transformedPodcasts);
    }
  }, [success, podcasts, podcastHistory]);

  const isLoading = useMemo(
    () =>
      loading ||
      (success && podcasts.length > 0 && podcastHistory.length === 0),
    [loading, success, podcasts, podcastHistory]
  );

  return (
    <>
      {error && <Toast text={error} id="Fetch podcast history error" />}
      {isLoading ? (
        <Spinner className={classes.spinner} />
      ) : (
        <div className={classes.container}>
          {podcastHistory.map((podcast, index) => (
            <History podcast={podcast} index={index} key={podcast.title} />
          ))}
          {podcastHistory.length === 0 && (
            <Body letterCase={BodyLetterCase.Uppercase} size={BodySize.XS}>
              There are no podcasts that accepted offers yet
            </Body>
          )}
        </div>
      )}
    </>
  );
};

export default PodcastHistory;
