import { Type as InputType } from 'components/Common/Input';

const loginInputs = [
  {
    name: 'email',
    renderProps: { placeholder: 'Email Address', type: InputType.Email },
  },
  {
    name: 'password',
    renderProps: { placeholder: 'Password', type: InputType.Password },
  },
];

export default loginInputs;
