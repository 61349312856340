import {
  getAuth,
  verifyPasswordResetCode,
  confirmPasswordReset,
} from 'firebase/auth';

const newPassword = async (actionCode, password) => {
  try {
    const auth = getAuth();

    await verifyPasswordResetCode(auth, actionCode);

    await confirmPasswordReset(auth, actionCode, password);
  } catch (error) {
    throw new Error(error);
  }
};

export default newPassword;
