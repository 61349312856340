import React, { useRef } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import classes from './Textarea2.module.scss';

export const Size = Object.freeze({
  M: 'M',
  S: 'S',
  XS: 'XS',
});

export const IconPosition = Object.freeze({
  Left: 'left',
  Right: 'right',
});

const Textarea2 = ({
  label,
  name,
  defaultValue,
  required,
  placeholder,
  disabled,
  readOnly,
  error,
  onChange,
  size,
  className,
  ...rest
}) => {
  const ref = useRef(null);

  return (
    <textarea
      className={classNames(className, classes.textarea, classes[size], {
        [classes.hasError]: error,
        [classes.isDisabled]: disabled,
        [classes.readOnly]: readOnly,
      })}
      id={name}
      name={name}
      ref={(currentRef) => {
        ref.current = currentRef;
      }}
      placeholder={placeholder}
      onChange={onChange}
      disabled={disabled}
      readOnly={readOnly}
      {...rest}
    />
  );
};

Textarea2.propTypes = {
  name: PropTypes.string.isRequired,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  readOnly: PropTypes.bool,
  defaultValue: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
};

Textarea2.defaultProps = {
  required: false,
  placeholder: '',
  onChange: () => {},
  error: false,
  disabled: false,
  size: Size.XS,
  className: '',
  readOnly: false,
  defaultValue: null,
  label: null,
};

export default Textarea2;
