import { createSelector } from '@reduxjs/toolkit';

const selectPodcastsState = (state) => state.podcasts;

export const selectFetchPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchPodcastsLoading,
    fetchPodcastsSuccess,
    fetchPodcastsError,
    podcasts,
  }) => ({
    loading: fetchPodcastsLoading,
    success: fetchPodcastsSuccess,
    error: fetchPodcastsError,
    podcasts,
  })
);

export const selectFetchEstimationPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchEstimationPodcastsLoading,
    fetchEstimationPodcastsSuccess,
    fetchEstimationPodcastsError,
    estimationsPodcasts,
  }) => ({
    loading: fetchEstimationPodcastsLoading,
    success: fetchEstimationPodcastsSuccess,
    error: fetchEstimationPodcastsError,
    estimationsPodcasts,
  })
);

export const clearEstimationPodcastsState = createSelector(
  selectPodcastsState,
  ({
    fetchEstimationPodcastsLoading,
    fetchEstimationPodcastsSuccess,
    fetchEstimationPodcastsError,
    estimationsPodcasts,
  }) => ({
    loading: fetchEstimationPodcastsLoading,
    success: fetchEstimationPodcastsSuccess,
    error: fetchEstimationPodcastsError,
    estimationsPodcasts,
  })
);
