/* eslint-disable no-param-reassign */
import { createReducer } from '@reduxjs/toolkit';

import {
  fetchPodcastHistoryInit,
  fetchPodcastHistorySuccess,
  fetchPodcastHistoryFail,
} from 'state/actionCreators/settings';

export const initialState = {
  fetchPodcastHistoryLoading: false,
  fetchPodcastHistorySuccess: false,
  fetchPodcastHistoryError: null,
  podcasts: [],
};

const campaignsReducer = createReducer(initialState, {
  [fetchPodcastHistoryInit]: (state) => {
    state.fetchPodcastHistoryLoading = true;
    state.fetchPodcastHistorySuccess = false;
    state.fetchPodcastHistoryError = null;
  },
  [fetchPodcastHistorySuccess]: (state, { payload }) => {
    const { podcasts } = payload;
    state.fetchPodcastHistoryLoading = false;
    state.fetchPodcastHistorySuccess = true;
    state.fetchPodcastHistoryError = null;
    state.podcasts = podcasts;
  },
  [fetchPodcastHistoryFail]: (state, { payload }) => {
    const { error } = payload;
    state.fetchPodcastHistoryLoading = false;
    state.fetchPodcastHistorySuccess = false;
    state.fetchPodcastHistoryError = error;
  },
});

export default campaignsReducer;
