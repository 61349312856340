import campaignAdTypes from 'enums/campaign/campaignAdTypes.enum';

const adTypeInputs = [
  {
    type: campaignAdTypes.preRoll,
    label: 'PRE-ROLL',
  },
  {
    type: campaignAdTypes.midRoll,
    label: 'MID-ROLL',
  },
  {
    type: campaignAdTypes.postRoll,
    label: 'POST-ROLL',
  },
  {
    type: campaignAdTypes.backCatalog,
    label: 'BACK CATALOG',
  },
  {
    type: campaignAdTypes.bakedIn,
    label: 'BAKED-IN',
  },
  {
    type: campaignAdTypes.dynamicAd,
    label: 'DYNAMIC AD',
  },
];

export default adTypeInputs;
