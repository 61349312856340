import { useEffect, useMemo, useState } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getStartAndEndDatesInputs } from 'utils/inputs/createCampaignInputs';
import { selectFetchUnfinishedCampaignState } from 'state/selectors/campaigns';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Form from 'components/Common/Form';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import validationSchema from './StartAndEndDatesForm.schema';

const StartAndEndDateForm = () => {
  const {
    onSubmit,
    goForward,
    goBack,
    currentStep,
    formsData,
  } = useMultiStepFormContext();

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const defaultData = formsData[currentStep] ?? campaign;

  const [startDate, setStartDate] = useState(defaultData?.airingFrom ?? null);

  const onSubmitHandler = (data) => {
    onSubmit({
      airingFrom: new Date(new Date(data.airingFrom).setHours(12, 0, 0, 0)),
      airingTo: new Date(new Date(data.airingTo).setHours(12, 0, 0, 0))
    });
    goForward();
  };

  const startAndEndDatesFields = useMemo(
    () =>
      getStartAndEndDatesInputs({
        airingFromState: { value: startDate, onChange: setStartDate },
        defaultValues: defaultData,
      }),
    [startDate, defaultData]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <Form
      className={classNames(commonClasses.content, commonClasses.form)}
      validationSchema={validationSchema}
      onSubmit={onSubmitHandler}
    >
      {startAndEndDatesFields.map(
        ({
          component: Component,
          name,
          props: componentProps,
          defaultValue,
        }) => (
          <Component
            key={name}
            name={name}
            defaultValue={defaultValue}
            {...componentProps}
          />
        )
      )}
      <div className={commonClasses.buttonsContainer}>
        <Button
          kind={ButtonKind.Tertiary}
          className={commonClasses.goBackButton}
          onClick={goBack}
        >
          Back
        </Button>
        <Button className={commonClasses.submitButton} type={ButtonType.Submit}>
          Next
        </Button>
      </div>
    </Form>
  );
};

export default StartAndEndDateForm;
