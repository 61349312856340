import React from 'react';
import classNames from 'classnames';
import parse from 'html-react-parser';
import { getName } from 'country-list';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';
import Body, { Size as BodySize } from 'components/Typography/Body';
import Badge from 'components/Common/Badge';
import classes from 'components/Pages/Campaign/Summary/Summary.module.scss';
import incomeOptions from '../incomeOptions';
import { ageOptions } from '../ageOptions';
import ethnicityOptions from '../ethnicityOptions';

const getCampaignFields = (campaign) => [
  {
    label: 'Name',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign.name || ''}
      </Body>
    ),
  },
  {
    label: 'Airing Between',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign.airingFrom?.toDateString() || ''} -{' '}
        {campaign.airingTo?.toDateString() || ''}
      </Body>
    ),
  },
  {
    label: 'Categories',
    component: (props) => (
      <div className={classes.categories}>
        {campaign?.categories?.length ? (
          campaign?.categories?.map(({ id, name }) => (
            <Badge
              {...props}
              text={name}
              className={classes.category}
              key={id}
            />
          ))
        ) : (
          <Body
            {...props}
            size={BodySize.XS}
            className={classes.informationText}
          >
            -
          </Body>
        )}
      </div>
    ),
  },
  {
    label: 'Gender',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        Male: {campaign.gender?.male || '0'} % <br />
        Female: {campaign.gender?.female || '0'} %
      </Body>
    ),
  },
  {
    label: 'Age Ranges',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        <div className={classes.categories}>
          {campaign?.age?.length ? (
            campaign.age.map((ageValue) => (
              <Badge
                {...props}
                text={
                  ageOptions.find((ageOption) => ageValue === ageOption.value)
                    ?.label || ''
                }
                className={classes.category}
                key={ageValue}
              />
            ))
          ) : (
            <Body
              {...props}
              size={BodySize.XS}
              className={classes.informationText}
            >
              -
            </Body>
          )}
        </div>
      </Body>
    ),
  },
  {
    label: 'Campaign Total Budget',
    component: (props) => (
      <Body
        {...props}
        size={BodySize.XS}
        className={classNames(classes.informationText, classes.green)}
      >
        {`$${formatNumberWithComas(campaign.budget)}` || '-'}
      </Body>
    ),
  },
  {
    label: 'Ethnicities',
    component: (props) => (
      <div className={classes.categories}>
        {campaign?.ethnicity?.length ? (
          campaign.ethnicity.map((ethnicityValue) => (
            <Badge
              {...props}
              text={
                ethnicityOptions.find(
                  (ethnicityOption) => ethnicityValue === ethnicityOption.value
                )?.label || ''
              }
              className={classes.category}
              key={ethnicityValue}
            />
          ))
        ) : (
          <Body
            {...props}
            size={BodySize.XS}
            className={classes.informationText}
          >
            -
          </Body>
        )}
      </div>
    ),
  },
  {
    label: 'CPM',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign?.cpmRate || '-'}
      </Body>
    ),
  },
  {
    label: 'Income Ranges',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        <div className={classes.categories}>
          {campaign?.income?.length
            ? campaign.income.map((income) => (
                <Badge
                  {...props}
                  text={
                    incomeOptions.find(
                      (incomeOption) => incomeOption.value === income
                    )?.label || ''
                  }
                  className={classes.category}
                  key={income}
                />
              ))
            : '-'}
        </div>
      </Body>
    ),
  },
  {
    label: 'Split',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {campaign.percentages ? (
          <>
            Network: {Math.round(campaign.percentages?.network * 100)} % <br />
            Podcast: {Math.round(campaign.percentages?.podcaster * 100)} %
          </>
        ) : (
          '-'
        )}
      </Body>
    ),
  },

  {
    label: 'Countries',
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        <div className={classes.countries}>
          {campaign?.countries?.length
            ? campaign.countries.map((countryCode) => (
                <>
                  <Body
                    {...props}
                    size={BodySize.XS}
                    className={classes.informationText}
                  >
                    {getName(countryCode) || ''}
                  </Body>
                  <br />
                </>
              ))
            : '-'}
        </div>
      </Body>
    ),
  },

  {
    label: 'Description',
    className: classes.descriptionGrid,
    component: (props) => (
      <Body {...props} size={BodySize.XS} className={classes.informationText}>
        {parse(campaign.description) || ''}
      </Body>
    ),
  },
];

export default getCampaignFields;
