import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';

const saveCheckoutSession = async ({ brandId, campaignId, session }) => {
  try {
    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaignId
    );
    await updateDoc(campaignRef, { session: session.id });
  } catch (error) {
    throw new Error(error);
  }
};

export default saveCheckoutSession;
