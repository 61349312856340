import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Button, { Type as ButtonType } from 'components/Common/Button';
import Form, { ValidationMode } from 'components/Common/Form';
import Toast from 'components/Common/Toast';
import Spinner from 'components/Common/Spinner';
import { sendAdCopy } from 'state/actions/campaigns';
import { selectSendAdCopyState } from 'state/selectors/campaigns';
import FormControlTextarea from 'components/Common/FormControlTextarea';
import Textarea2 from 'components/Common/Textarea2';
import { Size as TextareaSize } from 'components/Common/Textarea';
import validationSchema from './AdCopy.schema';
import classes from './AdCopy.module.scss';

const AdCopy = ({ campaign, offer, finishedCampaign, addToAll }) => {
  const dispatch = useDispatch();
  const [notesValue, setNotesValue] = useState('');

  const { loading, error } = useSelector(selectSendAdCopyState, shallowEqual);

  const offerData = useMemo(() => offer, [offer]);

  useEffect(() => {
    setNotesValue('');
    if (offer?.adCopy) {
      setNotesValue(offer.adCopy?.notes.replace(/<[^>]*>/g, ' ').trim());
    }
  }, [offer, campaign]);

  const handleChange = (e) => {
    setNotesValue(e.target.value);
  };

  const onClickSendHandler = useCallback(
    (values) => {
      dispatch(
        sendAdCopy({
          campaign,
          podcastId: offer?.podcastId,
          adCopy: {
            ...values,
            notes: notesValue,
          },
          addToAll,
        })
      );
    },
    [campaign, offer, notesValue]
  );

  const topText = useMemo(() => {
    const baseText = 'Insert Your Ad Copy for ';
    if (!addToAll) {
      return `${baseText}${offerData?.podcastName}`;
    }
    return `${baseText}all the podcasts in this campaign.`;
  }, [addToAll, offerData]);

  const bottomText = useMemo(
    () =>
      addToAll
        ? 'Later if you wish, you will be able to edit the copy individually.'
        : '',
    [addToAll]
  );

  return (
    <>
      {error && <Toast text={error} id="Send Ad Copy Error" />}
      {!offerData && !addToAll ? (
        <div className={classes.spinner}>
          <Spinner />
        </div>
      ) : (
        <div className={classes.container}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
            size={BodySize.S}
          >
            Send podcast ad copy
          </Body>
          {!addToAll && (
            <>
              <div>
                <img
                  src={offerData?.podcastImageUrl}
                  alt="podcast logo"
                  className={classes.logo}
                />
              </div>
              <Body className={classes.podcastName} size={BodySize.XS}>
                {offerData?.podcastName}
              </Body>
            </>
          )}
          <Form
            validationSchema={validationSchema}
            validationMode={ValidationMode.OnChange}
            onSubmit={onClickSendHandler}
            className={classes.form}
          >
            <Body size={BodySize.XS}>
              {topText}
              <br />
              {bottomText}
            </Body>
            <FormControlTextarea
              name="copy"
              className={classes.formControl}
              size={TextareaSize.L}
              defaultValue={offerData?.adCopy?.copy || ''}
              disabled={finishedCampaign}
            />
            <Body className={classes.notesText} size={BodySize.XS}>
              If you have any additional notes, add below (optional)
            </Body>
            <Textarea2
              name="notes"
              size={TextareaSize.S}
              disabled={finishedCampaign}
              value={notesValue}
              onChange={handleChange}
              className={classes.formControl}
            />
            {!finishedCampaign && (
              <Button
                className={classes.button}
                type={ButtonType.Submit}
                loading={loading}
              >
                {offerData?.adCopy ? 'Send changes' : 'Send'}
              </Button>
            )}
          </Form>
        </div>
      )}
    </>
  );
};

AdCopy.propTypes = {
  campaign: PropTypes.shape({}),
  offer: PropTypes.shape({
    imageUrl: PropTypes.string,
    title: PropTypes.string,
    podcastId: PropTypes.string,
    adCopy: PropTypes.shape({
      copy: PropTypes.string,
      notes: PropTypes.string,
    }),
  }),
  finishedCampaign: PropTypes.bool,
  addToAll: PropTypes.bool,
};

AdCopy.defaultProps = {
  campaign: null,
  offer: null,
  finishedCampaign: false,
  addToAll: false,
};

export default AdCopy;
