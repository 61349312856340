import Roles from 'enums/firebase/roles.enum';
import { getAuth } from 'firebase/auth';

const verifyBrandRole = async () => {
  try {
    const auth = getAuth();

    if (auth.currentUser) {
      const { claims } = await auth.currentUser.getIdTokenResult(true);

      return claims?.role === Roles.Brand;
    }

    return false;
  } catch (error) {
    throw new Error(error);
  }
};

export default verifyBrandRole;
