import React from 'react';
import { useLocation } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Header from 'components/Navigation/SideBar/Header';
import Item from 'components/Navigation/SideBar/Item';
import closeIcon from 'assets/icons/close.svg';
import sideBarRoutes from 'utils/routes/sideBarRoutes';

import classes from './SideBar.module.scss';

const SideBar = ({ open, onToggleMenu }) => {
  const location = useLocation();

  return (
    <>
      <nav
        className={classNames(classes.content, {
          [classes.open]: open,
          [classes.close]: !open,
        })}
      >
        <div className={classes.header}>
          <button
            type="button"
            className={classes.closeButton}
            onClick={onToggleMenu}
          >
            <img src={closeIcon} alt="close" />
          </button>
          <Header />
        </div>
        {sideBarRoutes.map(({ title, to, pathname }) => (
          <Item
            key={title}
            title={title}
            to={to}
            selected={location.pathname === pathname}
          />
        ))}
      </nav>
      {open && (
        <div
          name="background"
          role="button"
          className={classes.background}
          aria-label="background"
          onClick={onToggleMenu}
          onKeyDown={onToggleMenu}
          tabIndex={0}
        />
      )}
      <button
        type="button"
        className={classes.iconButton}
        onClick={onToggleMenu}
        aria-label="menu-button"
      >
        <div className={classes.menuIcon} />
        <div className={classes.menuIcon} />
        <div className={classes.menuIcon} />
      </button>
    </>
  );
};

SideBar.propTypes = {
  open: PropTypes.bool.isRequired,
  onToggleMenu: PropTypes.func.isRequired,
};

export default SideBar;
