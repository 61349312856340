/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import PropTypes from 'prop-types';
import { Route, Redirect } from 'react-router-dom';
import Path from 'enums/path.enum';

const RestrictedRoute = ({
  component: Component,
  condition,
  redirectTo,
  ...rest
}) => (
  <Route
    {...rest}
    render={(props) =>
      condition ? <Component {...props} /> : <Redirect to={redirectTo} />
    }
  />
);

RestrictedRoute.propTypes = {
  component: PropTypes.elementType.isRequired,
  condition: PropTypes.bool,
  redirectTo: PropTypes.string,
};

RestrictedRoute.defaultProps = {
  condition: true,
  redirectTo: Path.Login,
};

export default RestrictedRoute;
