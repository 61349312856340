import fetchEnabledUsersService from 'services/users/fetchEnabledUsers';
import fetchUserByIdService from 'services/users/fetchUserById';
import fetchSelectedUsersService from 'services/users/fetchSelectedUsers';

import {
  fetchEnabledUsersInit,
  fetchEnabledUsersSuccess,
  fetchEnabledUsersFail,
  fetchUserByIdInit,
  fetchUserByIdSuccess,
  fetchUserByIdFail,
  fetchSelectedUsersInit,
  fetchSelectedUsersSuccess,
  fetchSelectedUsersFail,
  clearUsers,
} from 'state/actionCreators/users';

export const fetchEnabledUsers = ({ lastItemPagination, first }) => async (
  dispatch
) => {
  dispatch(fetchEnabledUsersInit());

  try {
    const { enabledUsers, lastUser } = await fetchEnabledUsersService({
      lastItemPagination,
      first,
    });

    return dispatch(
      fetchEnabledUsersSuccess({ users: enabledUsers, lastUser })
    );
  } catch (error) {
    return dispatch(fetchEnabledUsersFail({ error: error.message }));
  }
};

export const fetchSelectedUsers = ({ selectedIds }) => async (dispatch) => {
  dispatch(fetchSelectedUsersInit());

  try {
    const users = await fetchSelectedUsersService({ selectedIds });

    return dispatch(fetchSelectedUsersSuccess({ users }));
  } catch (error) {
    return dispatch(fetchSelectedUsersFail({ error: error.message }));
  }
};

export const fetchUserById = (userId) => async (dispatch) => {
  dispatch(fetchUserByIdInit());

  try {
    const user = await fetchUserByIdService(userId);

    return dispatch(fetchUserByIdSuccess({ user }));
  } catch (error) {
    return dispatch(fetchUserByIdFail({ error: error.message }));
  }
};

export const clearUsersState = () => clearUsers();
