import { functions } from 'firebase';
import { httpsCallable } from 'firebase/functions';

const downloadCampaignOffers = async ({
  brandId,
  campaignId,
  emailAddress,
}) => {
  try {
    const requestDownload = httpsCallable(
      functions,
      'campaigns-exportApplicationsAsCsv'
    );
    return await requestDownload({ brandId, campaignId, emailAddress });
  } catch (error) {
    throw new Error(error);
  }
};

export default downloadCampaignOffers;
