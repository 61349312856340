/* eslint-disable func-names */
import * as yup from 'yup';

import Validations from 'enums/schemas/validations.enum';
import Required from 'enums/schemas/required.enum';
import { MIN_PASSWORD_LENGTH } from 'constants/schemas';

const passwordRegex = `^(?:.{${MIN_PASSWORD_LENGTH},}|)$`;

const editUserValidationSchema = yup.object().shape({
  password: yup.string().matches(passwordRegex, Validations.MinLengthPassword),
  confirmPassword: yup
    .string()
    .test(
      'matchOrBothEmpty',
      Validations.PasswordsMustMatch,
      function (confirmPassword) {
        const { password } = this.parent;
        return confirmPassword === password || (!password && !confirmPassword);
      }
    ),
  owner: yup.string().required(Required.Owner),
  phone: yup.string().required(Required.Phone),
  name: yup.string().required(Required.BrandName),
  url: yup.string().required(Required.BrandUrl),
  linkedInUrl: yup.string().required(Required.BrandUrl),
});

export default editUserValidationSchema;
