const sortingOptions = [
  {
    label: 'A-Z',
    value: 'a-z',
    sortBy: 'name',
    sortOrder: 'asc',
  },
  {
    label: 'Z-A',
    value: 'z-a',
    sortBy: 'name',
    sortOrder: 'desc',
  },
  {
    label: 'Start date',
    value: 'start-date',
    sortBy: 'airingFrom',
    sortOrder: 'asc',
  },
  {
    label: 'End date',
    value: 'end-date',
    sortBy: 'airingTo',
    sortOrder: 'desc',
  },
];

export default sortingOptions;
