import { createAction } from '@reduxjs/toolkit';

export const fetchPodcastHistoryInit = createAction(
  '[Settings] Fetch Podcast History Init'
);
export const fetchPodcastHistorySuccess = createAction(
  '[Settings] Fetch Podcast History Success'
);
export const fetchPodcastHistoryFail = createAction(
  '[Settings] Fetch Podcast History Fail'
);
