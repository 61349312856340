import { createSelector } from '@reduxjs/toolkit';

const selectUsersState = (state) => state.users;

export const selectFetchEnabledUsersState = createSelector(
  selectUsersState,
  ({
    fetchEnabledUsersLoading,
    fetchEnabledUsersSuccess,
    fetchEnabledUsersError,
    users,
    lastUser,
  }) => ({
    loading: fetchEnabledUsersLoading,
    success: fetchEnabledUsersSuccess,
    error: fetchEnabledUsersError,
    users,
    lastUser,
  })
);

export const selectFetchUserByIdState = createSelector(
  selectUsersState,
  ({
    fetchUserByIdLoading,
    fetchUserByIdSuccess,
    fetchUserByIdError,
    user,
  }) => ({
    loading: fetchUserByIdLoading,
    success: fetchUserByIdSuccess,
    error: fetchUserByIdError,
    user,
  })
);

export const selectFetchPodcastUserByIdState = createSelector(
  selectUsersState,
  ({
    fetchPodcastUserByIdLoading,
    fetchPodcastUserByIdSuccess,
    fetchPodcastUserByIdError,
    podcastUser,
  }) => ({
    loading: fetchPodcastUserByIdLoading,
    success: fetchPodcastUserByIdSuccess,
    error: fetchPodcastUserByIdError,
    podcastUser,
  })
);

export const selectFetchSelectedUsersState = createSelector(
  selectUsersState,
  ({
    fetchSelectedUsersLoading,
    fetchSelectedUsersSuccess,
    fetchSelectedUsersError,
    selectedUsers,
  }) => ({
    loading: fetchSelectedUsersLoading,
    success: fetchSelectedUsersSuccess,
    error: fetchSelectedUsersError,
    selectedUsers,
  })
);
