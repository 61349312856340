import React, { useCallback, useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize } from 'components/Typography/Body';
import readUploadedFile from 'utils/functions/readUploadedFile';

import classes from './UploadFile.module.scss';

const validTypes = [
  'image/jpeg',
  'image/jpg',
  'image/png',
  'image/gif',
  'image/x-icon',
];

const UploadFile = ({ selectedFile, setSelectedFile, name = '' }) => {
  const [errorMessage, setErrorMessage] = useState('');
  const [showImage, setShowImage] = useState(null || selectedFile);
  const [changedFile, setChangedFile] = useState(false);

  const fileInputRef = useRef();

  useEffect(() => {
    if (selectedFile && changedFile) {
      const reader = new FileReader();
      reader.readAsDataURL(selectedFile);
      reader.onload = (e) => {
        setShowImage(e.target.result);
      };
    }
  }, [selectedFile, changedFile]);

  const fileInputClicked = () => {
    fileInputRef.current.click();
  };

  const preventDefault = useCallback((e) => {
    e.preventDefault();
  }, []);

  const handleFile = useCallback((file) => {
    if (validTypes.includes(file.type)) {
      readUploadedFile({
        file,
        setFile: setSelectedFile,
        setError: setErrorMessage,
        setChangedFile,
      });
    } else {
      setErrorMessage('File type not permitted. Please upload an image.');
    }
  }, []);

  const fileDrop = useCallback((e) => {
    e.preventDefault();
    const { files } = e.dataTransfer;
    setShowImage(null);
    if (files.length === 1) {
      handleFile(files[0]);
    } else if (files.length > 1) {
      setErrorMessage('Please select only one file');
      setSelectedFile(null);
    }
  }, []);

  const filesSelected = useCallback(() => {
    const { files } = fileInputRef.current;

    if (files.length > 0) {
      handleFile(files[0]);
      setShowImage(null);
    }
  }, [fileInputRef]);

  return (
    <div className={classes.container}>
      <div
        className={classes.dropContainer}
        onDragOver={preventDefault}
        onDragEnter={preventDefault}
        onDragLeave={preventDefault}
        onDrop={fileDrop}
        onClick={fileInputClicked}
        onKeyDown={fileInputClicked}
        role="button"
        tabIndex={0}
      >
        {showImage && (
          <img src={showImage} alt="logo" className={classes.logoDisplay} />
        )}
        <Body size={BodySize.XS} className={classes.text}>
          Drag and drop here <br /> or
        </Body>
        <Body size={BodySize.XS} className={classes.browseText}>
          browse
        </Body>
        <input
          name={name}
          ref={fileInputRef}
          className={classes.fileInput}
          type="file"
          multiple
          onChange={filesSelected}
          accept="image/*"
          data-testid="fileInput"
        />
      </div>
      <div className={classes.uploadedFile}>
        {errorMessage && (
          <Body size={BodySize.XS} className={classes.errorMessage}>
            {errorMessage}
          </Body>
        )}
        {selectedFile && <Body size={BodySize.XS}>{selectedFile.name}</Body>}
      </div>
    </div>
  );
};

UploadFile.propTypes = {
  selectedFile: PropTypes.shape({ name: PropTypes.string }),
  setSelectedFile: PropTypes.func.isRequired,
  name: PropTypes.string,
};

UploadFile.defaultProps = {
  selectedFile: {},
  name: '',
};

export default UploadFile;
