import { Type as InputType } from 'components/Common/Input';

const updateUserInputs = [
  {
    name: 'email',
    className: '',
    renderProps: { placeholder: 'Your Email', type: InputType.Email },
  },
  {
    name: 'password',
    className: '',
    renderProps: {
      placeholder: 'Your New Password',
      type: InputType.Password,
    },
  },
  {
    name: 'confirmPassword',
    className: 'spacing',
    renderProps: {
      placeholder: 'Confirm Password',
      type: InputType.Password,
    },
  },
  {
    name: 'owner',
    className: '',
    renderProps: {
      placeholder: 'Your Name',
      type: InputType.Text,
    },
  },
  {
    name: 'phone',
    className: 'spacing',
    renderProps: {
      placeholder: 'Your Phone Number',
      type: InputType.Tel,
      validKeys: {
        ...Array(10)
          .fill(0)
          .reduce((acc, _, i) => ({ ...acc, [i]: true }), {}),
        '-': true,
        '(': true,
        ')': true,
        '+': true,
        Backspace: true,
      },
    },
  },
  {
    name: 'name',
    className: '',
    renderProps: {
      placeholder: 'Brand Name',
      type: InputType.Text,
    },
  },
  {
    name: 'url',
    className: '',
    renderProps: {
      placeholder: 'Brand URL',
      type: InputType.Text,
    },
  },
  {
    name: 'linkedInUrl',
    className: '',
    renderProps: {
      placeholder: 'Linkedin Profile',
      type: InputType.Text,
    },
  },
];

export default updateUserInputs;
