import { PODCASTS_TO_SHOW } from 'constants/campaign';
import getPodchaserFilters from 'utils/functions/getPodchaserFilters';

const manageLoadMorePodcasts = ({
  selectedPodcasts,
  remainingPodcasts,
  campaign,
  setPodcastsToShow,
  setRemainingPodcasts,
  fetchMorePodcastUsers,
}) => {
  const totalPodcasts = [...selectedPodcasts];

  const filteredPodcasts = getPodchaserFilters({
    allPodcasts: remainingPodcasts,
    campaign,
  });

  if (totalPodcasts.length > 0) {
    const notSelectedPodcasts = filteredPodcasts.filter(
      (podcast) =>
        !selectedPodcasts.some(
          (selected) => selected.podcastId === podcast.podcastId
        )
    );
    totalPodcasts.push(...notSelectedPodcasts);
  } else {
    totalPodcasts.push(...filteredPodcasts);
  }

  let firstPodcasts = [];

  if (totalPodcasts.length >= PODCASTS_TO_SHOW) {
    firstPodcasts = totalPodcasts.splice(0, PODCASTS_TO_SHOW);

    setRemainingPodcasts(totalPodcasts);
    setPodcastsToShow((prevState) => {
      const isRepeated = prevState.some(
        (podcast) => podcast.podcastId === firstPodcasts[0].podcastId
      );

      if (isRepeated) {
        return prevState;
      }

      return [...prevState, ...firstPodcasts];
    });
  } else if (totalPodcasts.length < PODCASTS_TO_SHOW) {
    setRemainingPodcasts((prevState) => {
      const isRepeated = prevState.some(
        (podcast) => podcast.podcastId === totalPodcasts[0].podcastId
      );

      if (isRepeated) {
        return prevState;
      }

      return [...prevState, ...totalPodcasts];
    });
    fetchMorePodcastUsers();
  }
};

export default manageLoadMorePodcasts;
