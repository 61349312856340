import { collection, getDocs, query, where } from 'firebase/firestore';

import { firestore } from 'firebase';
import Collections from 'enums/firebase/collections.enum';
import splitArray from 'utils/functions/splitArray';

import {
  genderFilter,
  adFormatsFilter,
  adPlacementsFilter,
  ageFilter,
  countriesFilter,
  ethnicityFilter,
  incomeFilter,
  maritalStatusFilter,
  categoriesFilter,
} from './filters';

const filterPodcasts = ({
  podcasts,
  gender,
  age,
  income,
  countries,
  adPlacements,
  adFormats,
  maritalStatus,
  ethnicity,
  categories,
} = {}) => {
  const filteredPodcasts = podcasts.filter(
    ({
      podcastData,
      country: podcastCountry,
      adPlacements: podcastAdPlacements,
      adFormats: podcastAdFormats,
    }) => {
      const { audienceInformation, categories: podcastCategories } =
        podcastData || {};

      const {
        gender: podcastGender,
        ageRanges: podcastAge,
        incomeRanges: podcastIncome,
        ethnicities: podcastEthnicity,
        maritalStatuses: podcastMaritalStatus,
      } = audienceInformation || {};

      let result = true;

      // TODO: this should be removed when the app is updated
      // to use the categories field as an array of ids
      if (categories?.length) {
        result = !!podcastCategories;

        if (result) {
          result = categoriesFilter(podcastCategories, categories);
        }
      }

      if (result && gender && podcastGender) {
        result = genderFilter(podcastGender, gender, result);
      }

      if (result && age && podcastAge?.length) {
        result = ageFilter(podcastAge, age, result);
      }

      if (result && income?.length && podcastIncome?.length) {
        result = incomeFilter(podcastIncome, income, result);
      }

      if (result && ethnicity?.length && podcastEthnicity?.length) {
        result = ethnicityFilter(podcastEthnicity, ethnicity, result);
      }

      if (result && maritalStatus?.length && podcastMaritalStatus?.length) {
        result = maritalStatusFilter(podcastMaritalStatus, maritalStatus);
      }

      if (result && countries?.length && podcastCountry) {
        result = countriesFilter(podcastCountry, countries, result);
      }

      if (result && adPlacements && podcastAdPlacements) {
        result = adPlacementsFilter(podcastAdPlacements, adPlacements, result);
      }

      if (result && adFormats && podcastAdFormats) {
        result = adFormatsFilter(podcastAdFormats, adFormats, result);
      }

      return result;
    }
  );

  return filteredPodcasts;
};

const getPodcastsData = (podcastsDocs, network) => {
  const { acc: podcastsData } = podcastsDocs.reduce(
    ({ acc, accMap }, curr) => {
      curr.docs.forEach((doc) => {
        const podcaster = doc.data();

        if (
          !accMap[doc.id] &&
          podcaster.podcastData &&
          podcaster.networks[0] === network
        ) {
          // eslint-disable-next-line no-param-reassign
          accMap[doc.id] = true;
          acc.push(podcaster);
        }
      });

      return { acc, accMap };
    },
    { acc: [], accMap: {} }
  );

  return podcastsData;
};

const fetchAllEnabledUsersWithFilter = async (args, localFilter, network) => {
  const { categories } = args || {};

  if (localFilter) {
    return filterPodcasts(args);
  }

  // TODO: change in the app the categories field to be
  // an array of ids and use it instead of categoriesIds
  try {
    const queries = [];

    const userRef = collection(firestore, Collections.PodcastUsers);

    const generateEnabledUserQuery = (constraints = []) =>
      getDocs(
        query(
          userRef,
          where('enabled', '==', true),
          where('networks', 'array-contains', network),
          ...constraints
        )
      );

    const splittedCategories = splitArray(categories || [], 10);

    if (!categories?.length) {
      const users = await generateEnabledUserQuery();

      const podcastsData = getPodcastsData([users], network);

      const filteredPodcasts = filterPodcasts({
        ...args,
        podcasts: podcastsData,
      });

      return filteredPodcasts;
    }

    splittedCategories.forEach((splittedCategory) => {
      queries.push(
        generateEnabledUserQuery([
          where(
            'podcastData.categories',
            'array-contains-any',
            splittedCategory
          ),
        ])
      );
    });

    const users = await Promise.all(queries);

    const podcastsData = getPodcastsData(users, network);

    const filteredPodcasts = filterPodcasts({
      ...args,
      podcasts: podcastsData,
    });

    return filteredPodcasts;
  } catch (e) {
    const error = e?.message ?? e;

    throw new Error(error);
  }
};

export default fetchAllEnabledUsersWithFilter;
