const progressBarStyle = {
  trackStyle: {
    background: 'linear-gradient(to right, #a573ff, #645aff)',
    height: '0.6rem',
    borderRadius: 0,
  },
  handleStyle: {
    display: 'none',
    cursor: 'pointer',
  },
  railStyle: { backgroundColor: '#ebebeb', height: '0.6rem' },
  dotStyle: {
    display: 'none',
    cursor: 'pointer',
  },
};

export default progressBarStyle;
