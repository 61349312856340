import { doc, updateDoc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const editCampaign = async ({ brandId, campaignId, campaign }) => {
  try {
    const campaignRef = doc(
      firestore,
      Collections.Brands,
      brandId,
      Collections.Campaigns,
      campaignId
    );
    await updateDoc(campaignRef, campaign);
  } catch (error) {
    throw new Error(error);
  }
};

export default editCampaign;
