import * as yup from 'yup';

import extractTextFromHTML from 'utils/schemas/extractTextFromHTML';
import Required from 'enums/schemas/required.enum';

const CampaignTalkingPointsFormSchema = yup.object().shape({
  talkingPoints: yup
    .string()
    .nullable()
    .required(Required.TalkingPoints)
    .test({
      name: 'is-valid-talking-points',
      test: (value) => extractTextFromHTML(value).trim() !== '',
      message: Required.TalkingPoints,
    }),
});

export default CampaignTalkingPointsFormSchema;
