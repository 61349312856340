/* eslint-disable react/jsx-props-no-spreading */
import React, { useCallback, useEffect, useMemo, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input from 'components/Common/Input';
import Button, { Type as ButtonType } from 'components/Common/Button';
import UploadFile from 'components/Common/UploadFile';
import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import { signUp } from 'state/actions/auth';
import {
  selectAuthenticatedUserState,
  selectSignUpState,
} from 'state/selectors/auth';
import signUpInputs from 'utils/inputs/signUpInputs';
import validationSchema from './SignUpForm.schema';

import classes from './SignUpForm.module.scss';

const SignUpForm = () => {
  const dispatch = useDispatch();

  const { loading: loadingSignUp } = useSelector(
    selectSignUpState,
    shallowEqual
  );

  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const [logo, setLogo] = useState(user?.logo || null);
  const [logoError, setLogoError] = useState(null);

  useEffect(() => {
    if (logoError) {
      setLogoError(null);
    }
  }, [logoError]);

  const onSubmitHandler = useCallback(
    (values) => {
      if (logo) {
        const signUpValues = {
          ...values,
          waitingForConfirmation: true,
          enabled: false,
          budgetInFavor: 0,
        };

        delete signUpValues.confirmPassword;

        dispatch(signUp(signUpValues, logo));
      } else {
        setLogoError('Please upload a logo. You can edit it afterwards.');
      }
    },
    [logo]
  );

  const onChangeLogoHandler = useCallback((file) => {
    setLogo(file);
  }, []);

  const isLoading = useMemo(() => loadingSignUp, [loadingSignUp]);

  return (
    <>
      {logoError && <Toast text={logoError} id="Logo error" />}
      <Form
        validationMode={ValidationMode.OnChange}
        validationSchema={validationSchema}
        onSubmit={onSubmitHandler}
        className={classes.form}
      >
        {signUpInputs.map(({ name, renderProps }) => (
          <FormControl
            key={name}
            name={name}
            className={classes.formControl}
            render={(props) => (
              <Input
                {...props}
                {...renderProps}
                defaultValue={user ? user[name] : ''}
                disabled={user && name === 'email'}
              />
            )}
          />
        ))}
        <div className={classes.logo}>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.logoTitle}
          >
            Upload your company&apos;s logo
          </Body>
          <Body className={classes.logoDescription}>
            Your logo is gonna be shown <br /> to the podcaster when they <br />
            are looking at your offer.
          </Body>
          <Body size={BodySize.XS} className={classes.logoDescription}>
            Minimum height 100px.
          </Body>
          <div className={classes.uploadFile}>
            <UploadFile
              selectedFile={logo}
              setSelectedFile={onChangeLogoHandler}
            />
          </div>
        </div>
        <Button
          type={ButtonType.Submit}
          loading={isLoading}
          className={classes.button}
        >
          Sign up
        </Button>
      </Form>
    </>
  );
};

export default SignUpForm;
