const createStripeCheckout = ({ campaign, budgets, adTypes }) => {
  const offers = [];

  campaign.offers.forEach((offer) => {
    if (!offer.isPaid) {
      offers.push({
        name: offer.podcastName,
        budget: Number(budgets[offer.podcastId]).toFixed(2),
      });
    }
  });

  const newOffers = campaign.offers.map((offer) => {
    if (adTypes[offer.podcastId]) {
      const newAdTypes = [];
      Object.entries(adTypes[offer.podcastId]).forEach(([adKey, adValue]) => {
        if (adValue) {
          newAdTypes.push(adKey);
        }
      });

      return { ...offer, adTypes: newAdTypes };
    }
    return offer;
  });

  return { offers, newOffers };
};

export default createStripeCheckout;
