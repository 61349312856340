import React from 'react';
import { useHistory } from 'react-router-dom';

import IconButton from 'components/Common/IconButton';
import Path from 'enums/path.enum';
import { brandLogo } from 'assets/white-label';
import classes from './Header.module.scss';

const Header = () => {
  const history = useHistory();

  return (
    <div className={classes.container}>
      <IconButton onClick={() => history.push(Path.Home)}>
        <img src={brandLogo} alt="Brand Logo" className={classes.logo} />
      </IconButton>
    </div>
  );
};
export default Header;
