import {
  collection,
  query,
  where,
  getFirestore,
  getDocs,
} from 'firebase/firestore';
import { network } from 'assets/white-label';

import Collections from 'enums/firebase/collections.enum';

const fetchPodcasts = async () => {
  const firestore = getFirestore();

  const podcastsUsersRef = collection(firestore, Collections.PodcastUsers);

  const baseQuery = query(
    podcastsUsersRef,
    where('enabled', '==', true),
    where('networks', 'array-contains', network)
  );

  const podcastsSnapshot = await getDocs(baseQuery);
  const podcasts = podcastsSnapshot.docs.map((doc) => ({
    ...doc.data(),
    uid: doc.id,
  }));

  return podcasts;
};

export default fetchPodcasts;
