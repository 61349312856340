import {
  collection,
  query,
  where,
  getDocs,
  getFirestore,
} from 'firebase/firestore';

import Collections from 'enums/firebase/collections.enum';

const fetchNewPodcastsEstimationsId = async (podcastsIds) => {
  const promises = [];
  const firestore = getFirestore();
  const podcastsUsersRef = collection(firestore, Collections.PodcastUsers);

  if (podcastsIds.length > 0) {
    podcastsIds.forEach((podcastId) => {
      const podcastsQuery = query(
        podcastsUsersRef,
        where('podcastData.podcastId', '==', podcastId)
      );

      promises.push(getDocs(podcastsQuery));
    });
  } else {
    const podcastsQuery = query(podcastsUsersRef, where('enabled', '==', true));

    promises.push(getDocs(podcastsQuery));
  }

  const snapshots = await Promise.all(promises);

  const podcastsSnapshot = snapshots.flatMap((snapshot) => snapshot.docs);

  const podcastsData = podcastsSnapshot.map((doc) => doc.data());

  let estimatedImpressions = 0;
  podcastsData.forEach((podcast) => {
    const { audienceEstimate } = podcast?.podcastData;
    if (audienceEstimate) {
      estimatedImpressions += Number(audienceEstimate);
    }
  });

  return estimatedImpressions;
};

export default fetchNewPodcastsEstimationsId;
