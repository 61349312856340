import { getAuth, signOut } from 'firebase/auth';

const logout = async () => {
  try {
    const auth = getAuth();

    await signOut(auth);
  } catch (error) {
    throw new Error(error);
  }
};

export default logout;
