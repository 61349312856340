import { doc, deleteDoc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const deleteCampaign = async ({ brandId, campaignId }) => {
  try {
    await deleteDoc(
      doc(
        firestore,
        Collections.Brands,
        brandId,
        Collections.Campaigns,
        campaignId
      )
    );
  } catch (error) {
    throw new Error(error);
  }
};
export default deleteCampaign;
