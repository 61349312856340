import {
  fetchPodcastHistoryInit,
  fetchPodcastHistorySuccess,
  fetchPodcastHistoryFail,
} from 'state/actionCreators/settings';

import fetchPodcastHistoryService from 'services/settings/fetchPodcastHistory';

// eslint-disable-next-line import/prefer-default-export
export const fetchPodcastHistory = () => async (dispatch, getState) => {
  dispatch(fetchPodcastHistoryInit());

  const { user } = getState().auth;

  try {
    const podcasts = await fetchPodcastHistoryService({
      brandId: user.uid,
    });

    return dispatch(fetchPodcastHistorySuccess({ podcasts }));
  } catch (error) {
    return dispatch(fetchPodcastHistoryFail({ error: error.message }));
  }
};
