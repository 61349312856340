import { useCallback, useEffect, useMemo } from 'react';
import classNames from 'classnames';
import { shallowEqual, useDispatch, useSelector } from 'react-redux';

import { selectFetchPodcastersCountriesIsoCodes } from 'state/selectors/generals';
import {
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import isoToName from 'utils/functions/isoToName';
import { getCampaignCountriesInput } from 'utils/inputs/createCampaignInputs';
import useCancellableTimeout from 'hooks/useCancellableTimeout';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import {
  clearEstimationsFiltersState,
  fetchNewCampaignEstimations,
} from 'state/actions/campaigns';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import classes from './CampaignCountryForm.module.scss';

const CampaignCountryForm = () => {
  const dispatch = useDispatch();
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const { inTimeout, createTimeout } = useCancellableTimeout();

  const { isoCodes } = useSelector(
    selectFetchPodcastersCountriesIsoCodes,
    shallowEqual
  );

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const { estimationsSummary, loading: estimatingPodcasters } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const parsedCountriesNames = useMemo(() => {
    const countriesNamesWithIsoCode = isoToName(isoCodes);

    const countriesNames = countriesNamesWithIsoCode.map(
      ({ name, isoCode }) => ({
        label: name,
        value: isoCode,
      })
    );

    return countriesNames;
  }, [isoCodes]);

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const defaultData = formsData[currentStep] ?? campaign;

  const { countries: persistedCountries } = defaultData || {};

  const calculateCampaignEstimations = useCallback(
    (args) => {
      createTimeout(() => {
        dispatch(
          fetchNewCampaignEstimations({ filters: args, localFilter: true })
        );
      }, 1500);
    },
    [createTimeout, dispatch]
  );

  const onChangeDo = useCallback(
    (data, { name }) => {
      if (name === 'countries') {
        calculateCampaignEstimations({ countries: data });
      }
    },
    [calculateCampaignEstimations]
  );

  const campaignCountriesFields = useMemo(
    () =>
      getCampaignCountriesInput({
        defaultValues: defaultData,
        countryOptions: parsedCountriesNames,
        onChangeDo,
      }),
    [defaultData, parsedCountriesNames, onChangeDo]
  );

  const onGoBackHandler = () => {
    if (!persistedCountries?.length) {
      dispatch(
        clearEstimationsFiltersState({
          filters: ['countries'],
          reEstimate: true,
          localFilter: true,
        })
      );
    }

    goBack();
  };

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const isLoading = inTimeout || estimatingPodcasters;

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Choose where the podcast is from. You can also skip this step if you
          want.
        </Body>
        <div className={classes.fields}>
          {campaignCountriesFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>

      <EstimatedImpressions
        className={classes.estimatedImpressions}
        titleClassName={classes.estimatedImpressionsTitle}
        textClassName={classes.estimatedImpressionsText}
        potentialPodcasts={potentialPodcasts}
        estimatedImpressions={estimatedImpressions}
        loading={inTimeout}
      />
      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={onGoBackHandler}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={isLoading}
        >
          Next
        </Button>
      </div>
    </Form>
  );
};

export default CampaignCountryForm;
