import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import Tooltip from 'components/Common/Tooltip';
import Body, { Size as BodySize } from 'components/Typography/Body';
import questionIcon from 'assets/icons/question.png';
import getBudgets from 'utils/Campaign/budgets';

import classes from './Budget.module.scss';

const Budget = ({ currentBudget, budgetInFavor }) => {
  const budgets = useMemo(
    () => getBudgets({ currentBudget, budgetInFavor, classes }),
    [currentBudget]
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  return (
    <div className={classes.container}>
      <div className={classes.content}>
        {budgets.map((budget, index) => (
          <div className={classes.budget} key={budget.title}>
            <div className={classes.text}>
              <Body size={BodySize.XS} className={classes.budgetTitle}>
                {budget.title}
              </Body>
              <Tooltip
                component={() => (
                  <img src={questionIcon} alt="info" className={classes.info} />
                )}
                className={classNames(classes.tooltipMargin, {
                  [classes.lastTooltipMargin]: index === budgets.length - 1,
                })}
              >
                <div className={classes.tooltip}>
                  <Body size={BodySize.XXS} className={classes.tooltipText}>
                    {budget.tooltip}
                  </Body>
                </div>
              </Tooltip>
            </div>
            <Body size={BodySize.XS} className={budget.className}>
              {budget.value}
            </Body>
          </div>
        ))}
      </div>
    </div>
  );
};

Budget.propTypes = {
  currentBudget: PropTypes.number.isRequired,
  budgetInFavor: PropTypes.number.isRequired,
};

export default Budget;
