import React, { useCallback, useEffect, useMemo, useState } from 'react';
import classNames from 'classnames';
import PropTypes from 'prop-types';
import { convertToRaw } from 'draft-js';
import { Editor } from 'react-draft-wysiwyg';
import draftToHtml from 'draftjs-to-html';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';

import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';

import removeNonAcceptedTags from 'utils/functions/removeNonAcceptedTags';
import convertHtmlToDraft from 'utils/functions/convertHtmlToDraft';

import classes from './Textarea.module.scss';

export const Size = Object.freeze({
  L: 'L',
  M: 'M',
  S: 'S',
  XS: 'XS',
});

const TOOLBAR_OPTIONS = ['inline', 'link', 'list'];
const INLINE_OPTIONS = ['bold', 'italic'];
const LIST_OPTIONS = ['unordered', 'ordered'];

const ACCEPTED_TAGS = ['p', 'a', 'em', 'ul', 'ol', 'li', 'strong'];

const Textarea = ({
  name,
  value,
  onChange,
  label,
  labelClassName,
  placeholder,
  className,
  disabled,
  containerClassName,
  size,
  error,
  readOnly,
  toolbarHidden,
  ...rest
}) => {
  const editorState = convertHtmlToDraft(value);

  const [editor, setEditor] = useState(editorState);

  const editorCurrentContent = editor?.getCurrentContent();
  const firstElementType = editorCurrentContent.getBlockMap().first().getType();

  const shouldHidePlaceholder = useMemo(() => {
    const elementsToCheck = ['unordered-list-item', 'ordered-list-item'];

    return (
      !editorCurrentContent.hasText() &&
      elementsToCheck.includes(firstElementType)
    );
  }, [firstElementType, editorCurrentContent]);

  const editorContent = useMemo(
    () => draftToHtml(convertToRaw(editorCurrentContent)),
    [editorCurrentContent]
  );

  const onChangeHandler = useCallback(
    (inputValue) => {
      const editedEditorContent = draftToHtml(
        convertToRaw(inputValue?.getCurrentContent())
      );

      const sanitizedContent = removeNonAcceptedTags(
        editedEditorContent,
        ACCEPTED_TAGS
      );

      if (
        sanitizedContent !== editedEditorContent &&
        sanitizedContent !== editorContent
      ) {
        const result = convertHtmlToDraft(sanitizedContent);

        return setEditor(result);
      }

      return setEditor(inputValue);
    },
    [editorContent]
  );

  useEffect(() => {
    if (value !== editorContent) {
      onChange(editorContent);
    }
  }, [onChange, value, editorContent]);

  return (
    <div className={classNames(containerClassName, classes.container)}>
      {label && (
        <Body
          size={BodySize[size]}
          className={classNames(labelClassName, classes.labelText)}
          color={BodyColor.Black}
        >
          {label}
        </Body>
      )}
      <Editor
        toolbar={{
          options: TOOLBAR_OPTIONS,
          inline: {
            options: INLINE_OPTIONS,
          },
          list: {
            options: LIST_OPTIONS,
          },
        }}
        defaultEditorState={editorState}
        editorState={editor}
        editorClassName={classNames(classes.textarea, classes[size], {
          [classes.hasError]: error,
          [classes.isDisabled]: disabled,
          [classes.readOnly]: readOnly,
        })}
        wrapperId={name}
        onEditorStateChange={onChangeHandler}
        readOnly={disabled}
        toolbarHidden={toolbarHidden}
        placeholder={shouldHidePlaceholder ? '' : placeholder}
        handlePastedText={() => false}
        {...rest}
      />
    </div>
  );
};

Textarea.propTypes = {
  name: PropTypes.string.isRequired,
  cols: PropTypes.number,
  rows: PropTypes.number,
  required: PropTypes.bool,
  placeholder: PropTypes.string,
  onChange: PropTypes.func,
  error: PropTypes.bool,
  disabled: PropTypes.bool,
  register: PropTypes.func,
  size: PropTypes.oneOf(Object.values(Size)),
  className: PropTypes.string,
  containerClassName: PropTypes.string,
  labelClassName: PropTypes.string,
  readOnly: PropTypes.bool,
  value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  label: PropTypes.string,
  defaultValue: PropTypes.string,
  toolbarHidden: PropTypes.bool,
};

Textarea.defaultProps = {
  cols: 1,
  rows: 1,
  register: null,
  required: false,
  placeholder: '',
  onChange: () => {},
  error: false,
  disabled: false,
  size: Size.XS,
  className: '',
  containerClassName: '',
  labelClassName: '',
  readOnly: false,
  value: '<p></p>',
  label: null,
  defaultValue: '',
  toolbarHidden: false,
};

export default Textarea;
