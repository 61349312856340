import { setDoc } from 'firebase/firestore';

const createCampaign = async ({ campaignReference, campaign }) => {
  try {
    await setDoc(campaignReference, campaign);
  } catch (error) {
    throw new Error(error);
  }
};

export default createCampaign;
