import { createSelector } from '@reduxjs/toolkit';

const selectSettingsState = (state) => state.settings;

// eslint-disable-next-line import/prefer-default-export
export const selectFetchPodcastHistoryState = createSelector(
  selectSettingsState,
  ({
    fetchPodcastHistoryLoading,
    fetchPodcastHistorySuccess,
    fetchPodcastHistoryError,
    podcasts,
  }) => ({
    loading: fetchPodcastHistoryLoading,
    success: fetchPodcastHistorySuccess,
    error: fetchPodcastHistoryError,
    podcasts,
  })
);
