import { collection, getDocs, orderBy, query } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const fetchCampaignCategories = async () => {
  try {
    const categoriesRef = collection(firestore, Collections.Categories);

    const categoriesQuery = query(categoriesRef, orderBy('id'));

    const categoriesSnapshot = await getDocs(categoriesQuery);

    const categoriesData = categoriesSnapshot.docs.map((doc) => doc.data());

    return categoriesData;
  } catch (error) {
    throw new Error(error);
  }
};

export default fetchCampaignCategories;
