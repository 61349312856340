import { useEffect, useMemo } from 'react';
import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';

import { getCampaignTalkingPointsInputs } from 'utils/inputs/createCampaignInputs';
import {
  selectCreateCampaignState,
  selectEditCampaignState,
  selectFetchEstimationsInCampaignState,
  selectFetchUnfinishedCampaignState,
} from 'state/selectors/campaigns';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import Body, {
  Color as BodyColor,
  Size as BodySize,
} from 'components/Typography/Body';
import Form from 'components/Common/Form';
import EstimatedImpressions from 'components/Pages/CreateCampaign/EstimatedImpressions';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

import Spinner, {
  Color as SpinnerColor,
  Size as SpinnerSize,
} from 'components/Common/Spinner';
import { selectFetchEstimationPodcastsState } from 'state/selectors/podcasts';
import validationSchema from './CampaignTalkingPointsForm.schema';
import classes from './CampaignTalkingPointsForm.module.scss';

const CampaignTalkingPointsForm = () => {
  const {
    onSubmit,
    goBack,
    goForward,
    formsData,
    currentStep,
  } = useMultiStepFormContext();

  const onSubmitHandler = (data) => {
    onSubmit(data);
    goForward();
  };

  const { campaign } = useSelector(
    selectFetchUnfinishedCampaignState,
    shallowEqual
  );

  const { estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { loading, estimationsPodcasts } = useSelector(
    selectFetchEstimationPodcastsState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const { loading: createCampaignLoading } = useSelector(
    selectCreateCampaignState,
    shallowEqual
  );

  const { loading: editCampaignLoading } = useSelector(
    selectEditCampaignState,
    shallowEqual
  );

  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: 'smooth' });
  }, []);

  const defaultData = formsData[currentStep] ?? campaign;

  const campaignTalkingPointsFields = useMemo(
    () => getCampaignTalkingPointsInputs({ defaultValues: defaultData }),
    [defaultData]
  );

  const isLoading = createCampaignLoading || editCampaignLoading;

  return (
    <Form
      className={classNames(
        commonClasses.content,
        commonClasses.form,
        classes.form
      )}
      onSubmit={onSubmitHandler}
      validationSchema={validationSchema}
    >
      <div
        className={classNames(
          classes.content,
          commonClasses.buttonsContainerWidth
        )}
      >
        <Body size={BodySize.S} color={BodyColor.Black}>
          Talking points
          <br />
          <a
            href="https://help.ossacollective.com/en/articles/6495887-get-started-guide-for-advertisers#h_ad6c747510"
            rel="noreferrer"
            target="_blank"
            className={commonClasses.link}
          >
            Learn More
          </a>
        </Body>
        <div className={classes.fields}>
          {campaignTalkingPointsFields.map(
            ({
              component: Component,
              name,
              props: componentProps,
              defaultValue,
            }) => (
              <Component
                key={name}
                name={name}
                defaultValue={defaultValue}
                {...componentProps}
              />
            )
          )}
        </div>
      </div>

      {estimationsPodcasts > 0 &&
      Object.values(formsData).find((elem) => elem.podcasts).podcasts.length >
        0 ? (
        <EstimatedImpressions
          className={classes.estimatedImpressions}
          titleClassName={classes.estimatedImpressionsTitle}
          textClassName={classes.estimatedImpressionsText}
          estimatedImpressions={estimationsPodcasts}
          loading={loading}
          onlyEstimations
        />
      ) : (
        <EstimatedImpressions
          className={classes.estimatedImpressions}
          titleClassName={classes.estimatedImpressionsTitle}
          textClassName={classes.estimatedImpressionsText}
          potentialPodcasts={potentialPodcasts}
          estimatedImpressions={estimatedImpressions}
        />
      )}

      <div
        className={classNames(
          commonClasses.buttonsContainer,
          classes.buttonsContainer
        )}
      >
        <Button
          kind={ButtonKind.Tertiary}
          className={classNames(commonClasses.goBackButton, classes.buttons)}
          onClick={goBack}
          disabled={isLoading}
        >
          Back
        </Button>
        <Button
          className={classNames(commonClasses.submitButton, classes.buttons)}
          type={ButtonType.Submit}
          disabled={isLoading}
        >
          {isLoading ? (
            <Spinner color={SpinnerColor.White} size={SpinnerSize.S} />
          ) : (
            'Next'
          )}
        </Button>
      </div>
    </Form>
  );
};

export default CampaignTalkingPointsForm;
