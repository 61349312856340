import { createSelector } from '@reduxjs/toolkit';

const selectStripeState = (state) => state.stripe;

export const selectCreateCheckoutSessionState = createSelector(
  selectStripeState,
  ({
    createCheckoutSessionLoading,
    createCheckoutSessionSuccess,
    createCheckoutSessionError,
    session,
  }) => ({
    loading: createCheckoutSessionLoading,
    success: createCheckoutSessionSuccess,
    error: createCheckoutSessionError,
    session,
  })
);

export const selectSaveCheckoutSessionState = createSelector(
  selectStripeState,
  ({
    saveCheckoutSessionLoading,
    saveCheckoutSessionSuccess,
    saveCheckoutSessionError,
  }) => ({
    loading: saveCheckoutSessionLoading,
    success: saveCheckoutSessionSuccess,
    error: saveCheckoutSessionError,
  })
);

export const selectFetchCheckoutSessionState = createSelector(
  selectStripeState,
  ({
    fetchCheckoutSessionLoading,
    fetchCheckoutSessionSuccess,
    fetchCheckoutSessionError,
    session,
  }) => ({
    loading: fetchCheckoutSessionLoading,
    success: fetchCheckoutSessionSuccess,
    error: fetchCheckoutSessionError,
    session,
  })
);
