import React, { useMemo, useEffect, useCallback } from 'react';
import { useSelector, shallowEqual, useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import classNames from 'classnames';
import dayjs from 'dayjs';

import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
  Size as BodySize,
} from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';
import Expanded from 'components/Pages/Campaign/Expanded';
import Button from 'components/Common/Button';
import ViewIcon from 'assets/icons/view.png';
import ExportIcon from 'assets/icons/export.svg';
import sitbackIcon from 'assets/icons/sitback.svg';
import offerStatus from 'enums/campaign/offerStatus.enum';
import { selectFetchCampaignsPodcastsDataState } from 'state/selectors/campaigns';
import { fetchCampaignsPodcastsData } from 'state/actions/campaigns';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';
import useModal from 'hooks/useModal';
import ModalType from 'enums/modal/modalTypes';
import Modal from 'components/Common/Modal';
import adFormats from 'utils/Campaign/adFormats';
import classes from './Details.module.scss';
import EditTalkingPointsAll from '../EditTalkingPointsAllModal';

const statusMessage = {
  [offerStatus.accepted]: 'Copy Sent',
  [offerStatus.waiting]: 'Waiting For Copy',
};

const Details = ({
  campaign,
  onClickMoreDetails,
  onClickRejectedOffer,
  onClickAdCopy,
  onClickExportCampaign,
  finishedCampaign,
}) => {
  const dispatch = useDispatch();
  const { modal, onOpenModalHandler, onCloseModalHandler } = useModal();

  const { podcastsData } = useSelector(
    selectFetchCampaignsPodcastsDataState,
    shallowEqual
  );

  useEffect(() => {
    const podcastsIds = campaign?.offers?.map((offer) => offer.podcastId);
    if (!podcastsData || !podcastsIds.every((id) => podcastsData?.[id])) {
      dispatch(fetchCampaignsPodcastsData({ podcastsIds }));
    }
  }, [campaign]);

  const noAdCopys = useMemo(
    () => campaign.offers?.every((offer) => !offer.adCopy),
    [campaign]
  );

  const impressions = useMemo(
    () => campaign?.offers?.reduce((acc, offer) => acc + offer.impressions, 0),
    [campaign]
  );

  const onClickEditTalkingPoints = useCallback(
    ({ offer, finished, addToAll }) => {
      onOpenModalHandler(ModalType.EDIT_TALKING_POINTS, {
        campaign,
        offer,
        finished,
        addToAll,
      });
    },
    []
  );
  const onClickEditTalkingPointsForAll = useCallback(
    ({ offer, finished, addToAll }) => {
      onOpenModalHandler(ModalType.EDIT_TALKING_POINTS_ALL, {
        campaign,
        offer,
        finished,
        addToAll,
      });
    },
    []
  );

  return (
    <>
      <div className={classNames(classes.container, classes.expandedContainer)}>
        <div className={classes.content}>
          <div className={classes.upperContent}>
            {campaign.adFormats?.length > 0 && (
              <div className={classes.adFormatContainer}>
                <Body size={BodySize.S} className={classes.title}>
                  {
                    adFormats.find(
                      (addFormat) => campaign.adFormats[0] === addFormat.value
                    )?.label
                  }
                </Body>
              </div>
            )}
            <div className={classes.containerLeftColumn}>
              <div className={classes.containerTitleImage}>
                {campaign?.image && (
                  <img
                    src={campaign.image}
                    alt="Campaign_Image"
                    className={classes.image}
                  />
                )}
              </div>
              <div>
                <Body size={BodySize.S} className={classes.title}>
                  {campaign.name || 'Campaign title'}
                </Body>
                <div className={classes.campaignDetails}>
                  <div className={classes.details}>
                    <Body size={BodySize.XS} className={classes.text}>
                      Budget
                    </Body>
                    <Body
                      size={BodySize.XS}
                      className={classNames(classes.budgetAmount, classes.text)}
                    >
                      {`$${formatNumberWithComas(campaign.budget)}` ||
                        'Campaign budget'}
                    </Body>
                  </div>
                  <div className={classes.details}>
                    <Body size={BodySize.XS} className={classes.text}>
                      Airing between
                    </Body>
                    <Body
                      size={BodySize.XS}
                      className={classNames(classes.budgetAmount, classes.text)}
                    >
                      {dayjs(campaign.airingFrom).format('MMMM DD, YYYY')} to{' '}
                      {dayjs(campaign.airingTo).format('MMMM DD, YYYY')}
                    </Body>
                  </div>
                </div>
              </div>
            </div>
            <div>
              <div className={classes.offerStatus}>
                <div
                  className={classNames(classes.status, {
                    [classes.green]: !noAdCopys,
                    [classes.yellow]: noAdCopys,
                  })}
                />
                <Body
                  size={BodySize.XS}
                  className={classNames(classes.bold, classes.noMargin)}
                >
                  {noAdCopys
                    ? statusMessage[offerStatus.waiting]
                    : statusMessage[offerStatus.accepted]}
                </Body>
              </div>
              <IconButton
                className={classes.seeMore}
                onClick={onClickMoreDetails}
              >
                <img src={ViewIcon} alt="view" className={classes.icon} />
                <Body size={BodySize.XXS}>More details</Body>
              </IconButton>
              <IconButton
                className={classes.seeMore}
                onClick={onClickExportCampaign}
              >
                <img src={ExportIcon} alt="view" className={classes.icon} />
                <Body size={BodySize.XXS}>Export campaign</Body>
              </IconButton>
            </div>
          </div>
          <div
            className={classNames(classes.statistics, {
              [classes.finishedCampaign]: finishedCampaign,
            })}
          >
            <div className={classes.estimateContainer}>
              <Body size={BodySize.L} className={classes.value}>
                {formatNumberWithComas(campaign?.offers?.length) || 0}
              </Body>
              <Body size={BodySize.XS} className={classes.label}>
                PODCASTS ADDED
              </Body>
            </div>
            <div className={classes.estimateContainer}>
              <Body size={BodySize.L} className={classes.value}>
                {formatNumberWithComas(impressions)}
              </Body>
              <Body size={BodySize.XS} className={classes.label}>
                ESTIMATE IMPRESSIONS
              </Body>
            </div>
            {!finishedCampaign && (
              <div className={classes.containerButtons}>
                {noAdCopys && (
                  <Button
                    className={classes.talkingPoints}
                    onClick={(offer, addToAll) =>
                      onClickEditTalkingPointsForAll({
                        offer,
                        campaign,
                        finished: false,
                        addToAll,
                      })
                    }
                  >
                    Edit talking points for all
                  </Button>
                )}
                <Button
                  className={classes.sendButton}
                  onClick={() => onClickAdCopy(null, true)}
                >
                  {noAdCopys ? 'SEND COPY' : 'EDIT COPY FOR ALL'}
                </Button>
              </div>
            )}
          </div>
        </div>
        <div className={classNames(classes.expanded, classes.visible)}>
          {campaign.offers?.length === 0 ? (
            <div className={classes.noOffers}>
              <div className={classes.noContent}>
                <img
                  src={sitbackIcon}
                  alt="success"
                  className={classes.sitBack}
                />
                <Body
                  letterCase={BodyLetterCase.Uppercase}
                  spacing={BodySpacing.M}
                  className={classes.title}
                >
                  Campaign submitted
                </Body>
                <Body
                  letterCase={BodyLetterCase.Lowercase}
                  size={BodySize.XS}
                  className={classes.subtext}
                >
                  Sit back while we connect you with the right podcasters.
                </Body>
                <Body
                  letterCase={BodyLetterCase.Lowercase}
                  size={BodySize.XS}
                  className={classes.subtext}
                >
                  We&apos;re gonna send you an email when it&apos;s ready.
                </Body>
              </div>
            </div>
          ) : (
            campaign.offers
              ?.filter((offer) => offer.isPaid)
              .map((offer, index) => (
                <Expanded
                  campaign={campaign}
                  offer={offer}
                  index={index}
                  key={`${offer.title} PCCC - ${index + 1}`}
                  onClickRejectedOffer={onClickRejectedOffer}
                  onClickAdCopy={() => onClickAdCopy(offer)}
                  onClickEditTalkingPoints={() =>
                    onClickEditTalkingPoints(offer)
                  }
                  noAdCopys={noAdCopys}
                  finishedCampaign={finishedCampaign}
                />
              ))
          )}
        </div>
      </div>
      <Modal
        isOpen={modal.type === ModalType.EDIT_TALKING_POINTS_ALL}
        onClose={onCloseModalHandler}
        className={classes.talkingPointsAllModal}
      >
        <EditTalkingPointsAll
          campaign={modal.campaign}
          offer={modal.offer}
          finishedCampaign={modal.finished}
          addToAll
        />
      </Modal>
    </>
  );
};

Details.propTypes = {
  campaign: PropTypes.shape({
    name: PropTypes.string.isRequired,
    image: PropTypes.string.isRequired,
    budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string])
      .isRequired,
    adFormats: PropTypes.oneOfType([PropTypes.string]).isRequired,
    airingFrom: PropTypes.instanceOf(Date).isRequired,
    airingTo: PropTypes.instanceOf(Date).isRequired,
    offers: PropTypes.arrayOf(
      PropTypes.shape({
        podcastImageUrl: PropTypes.string,
        imageUrl: PropTypes.string,
        title: PropTypes.string,
        budget: PropTypes.number,
        adTypes: PropTypes.arrayOf(PropTypes.string),
        offerStatus: PropTypes.string,
      })
    ),
  }).isRequired,
  onClickMoreDetails: PropTypes.func.isRequired,
  onClickRejectedOffer: PropTypes.func,
  onClickExportCampaign: PropTypes.func,
  onClickAdCopy: PropTypes.func,
  finishedCampaign: PropTypes.bool,
};

Details.defaultProps = {
  onClickRejectedOffer: null,
  onClickExportCampaign: null,
  onClickAdCopy: null,
  finishedCampaign: false,
};

export default Details;
