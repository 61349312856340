import React from 'react';
import PropTypes from 'prop-types';

import Body, {
  Size as BodySize,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import getCampaignFields from 'utils/Campaign/summaryFields';

import classNames from 'classnames';
import classes from './Summary.module.scss';

const Summary = ({ campaign, title }) => {
  const campaignFields = getCampaignFields(campaign);

  return (
    campaign && (
      <div className={classes.container}>
        {title && (
          <Body
            size={BodySize.S}
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            {title}
          </Body>
        )}
        <div
          className={classNames(classes.information, {
            [classes.removeMargin]: !!title,
          })}
        >
          {campaignFields.map(({ label, component: Component, className }) => (
            <div className={classNames(classes.item, className)} key={label}>
              <Body size={BodySize.XS} className={classes.itemTitle}>
                {label}
              </Body>
              <Component />
            </div>
          ))}
        </div>
      </div>
    )
  );
};

Summary.propTypes = {
  campaign: PropTypes.shape({ description: PropTypes.string }),
  title: PropTypes.string,
};

Summary.defaultProps = {
  campaign: null,
  title: '',
};

export default Summary;
