import React, { useCallback } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';

import Form, { ValidationMode } from 'components/Common/Form';
import FormControl from 'components/Common/FormControl';
import Input, { Type as InputType } from 'components/Common/Input';
import Button, { Type as ButtonType } from 'components/Common/Button';
import Body, {
  Size as BodySize,
  Color as BodyColor,
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import Toast from 'components/Common/Toast';
import Link from 'components/Typography/Link';
import { resetPassword } from 'state/actions/auth';
import { selectResetPasswordState } from 'state/selectors/auth';
import ossaLogo from 'assets/logos/ossa-logo.png';
import Path from 'enums/path.enum';

import validationSchema from './ResetPassword.schema';
import classes from './ResetPassword.module.scss';

const ResetPassword = () => {
  const dispatch = useDispatch();

  const { loading, error } = useSelector(
    selectResetPasswordState,
    shallowEqual
  );

  const onSubmitHandler = useCallback(({ email }) => {
    dispatch(resetPassword(email));
  }, []);

  return (
    <>
      {error && <Toast text={error} id="Reset password error" />}
      <div className={classes.container}>
        <div className={classes.logo}>
          <img src={ossaLogo} alt="Ossa Logo" />
        </div>
        <div>
          <Body
            letterCase={BodyLetterCase.Uppercase}
            spacing={BodySpacing.M}
            className={classes.title}
          >
            Password Recovery
          </Body>
          <Form
            validationMode={ValidationMode.OnChange}
            validationSchema={validationSchema}
            onSubmit={onSubmitHandler}
          >
            <FormControl
              name="email"
              render={(props) => (
                <Input
                  placeholder="Your Email"
                  type={InputType.Email}
                  {...props}
                />
              )}
            />
            <Button type={ButtonType.Submit} isFullWidth loading={loading}>
              Recover
            </Button>
          </Form>
          <div className={classes.link}>
            <Link to={Path.Login} underline={false}>
              <Body
                color={BodyColor.Gray}
                size={BodySize.XXS}
                letterCase={BodyLetterCase.Uppercase}
                spacing={BodySpacing.S}
                className={classes.bold}
              >
                Or go back to sign in
              </Body>
            </Link>
          </div>
        </div>
      </div>
    </>
  );
};

export default ResetPassword;
