import { doc, setDoc } from 'firebase/firestore';
import { firestore } from 'firebase';

import Collections from 'enums/firebase/collections.enum';

const createNewUser = async ({ newUser, userId }) => {
  try {
    const brandRef = doc(firestore, Collections.Brands, userId);

    await setDoc(brandRef, newUser);
  } catch (error) {
    throw new Error(error);
  }
};

export default createNewUser;
