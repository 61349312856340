import MultipleRectangleButtonsList from 'components/Common/MultipleRectangleButtonsList';
import FormControlDatePicker from 'components/Common/FormControlDatePicker';
import MultipleBadgeList from 'components/Common/MultipleBadgeList';
import { Size as TextareaSize } from 'components/Common/Textarea';
import FormControl from 'components/Common/FormControl';
import Slider from 'components/Common/Slider';
import Input, {
  Type as InputType,
  Size as InputSize,
} from 'components/Common/Input';
import FormControlTextarea from 'components/Common/FormControlTextarea';
import campaignCategoriesClasses from 'components/Pages/Forms/CreateCampaign/CampaignCategoriesForm/CampaignCategoriesForm.module.scss';
import { ONE_DAY } from 'constants/campaign';
import getFutureDate from 'utils/functions/getFutureDate';
import { ageOptions } from 'utils/Campaign/ageOptions';
import maritalStatusOptions from 'utils/Campaign/maritalStatusOptions';
import ethnicityOptions from 'utils/Campaign/ethnicityOptions';
import incomeOptions from 'utils/Campaign/incomeOptions';
import adPlacements from 'utils/Campaign/adPlacements';
import adFormats from 'utils/Campaign/adFormats';
import ArrowDownIcon from 'assets/icons/calendar.png';
import { brandName } from 'assets/white-label';

import classes from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';

const getCampaignNameInputs = ({ defaultValues }) => [
  {
    component: FormControl,
    name: 'name',
    defaultValue: defaultValues?.name,
    props: {
      name: 'name',
      render: (props) => (
        <Input
          {...props}
          label={
            'Choose a name for your campaign.\nThis will be shown to the podcasters.'
          }
          labelClassName={classes.formControlLabel}
          className={classes.formControlInput}
          placeholder="Campaign Name"
          type={InputType.Text}
          size={InputSize.M}
        />
      ),
    },
  },
];

const getStartAndEndDatesInputs = ({ defaultValues, airingFromState }) => [
  {
    component: FormControlDatePicker,
    name: 'airingFrom',
    defaultValue: defaultValues?.airingFrom,
    props: {
      className: classes.formControlInput,
      labelClassName: classes.formControlLabel,
      name: 'airingFrom',
      label: 'When does your campaign start?',
      placeholder: 'Start',
      onManualChange: airingFromState.onChange,
      minDate: getFutureDate({ days: ONE_DAY }),
      icon: <img alt="start-calendar-icon" src={ArrowDownIcon} />,
    },
  },
  {
    component: FormControlDatePicker,
    name: 'airingTo',
    defaultValue: defaultValues?.airingTo,
    props: {
      className: classes.formControlInput,
      labelClassName: classes.formControlLabel,
      name: 'airingTo',
      label: 'And when does it end?',
      placeholder: 'End',
      minDate: getFutureDate({
        startDate: airingFromState.value,
        days: ONE_DAY,
      }),
      disabled: !airingFromState.value,
      icon: <img alt="end-calendar-icon" src={ArrowDownIcon} />,
    },
  },
];

const getCampaignBudgetInputs = ({
  defaultValues,
  onChangeModifier,
  isDynamicTimeAd,
  onChangeDo,
}) => {
  const formControlArray = [
    {
      component: FormControl,
      name: 'budget',
      defaultValue: defaultValues?.budget,
      props: {
        name: 'budget',
        className: classes.buttonsContainerWidth,
        onChange: onChangeModifier,
        render: (props) => (
          <Input
            {...props}
            label="What is your budget? This will be used more as a reference since you can choose as many podcasts for your campaign."
            placeholder="$"
            type={InputType.Number}
            className={classes.formControlInput}
            labelClassName={classes.formControlLabel}
            allowNegativeValues={false}
            invalidKeys={{
              '-': true,
              '+': true,
              e: true,
              E: true,
            }}
          />
        ),
      },
    },
    {
      component: FormControl,
      name: 'cpmRate',
      defaultValue: defaultValues?.cpmRate || null,
      props: {
        name: 'cpmRate',
        className: classes.buttonsContainerWidth,
        onChange: onChangeModifier,
        render: (props) => (
          <Input
            {...props}
            label="CPM"
            step=".01"
            placeholder="$"
            type={InputType.Number}
            className={classes.formControlInput}
            labelClassName={classes.formControlLabel}
            allowNegativeValues={false}
            invalidKeys={{
              '-': true,
              '+': true,
              e: true,
              E: true,
            }}
          />
        ),
      },
    },
    ...(!isDynamicTimeAd
      ? [
          {
            component: FormControl,
            name: 'numberOfEpisodes',
            defaultValue: defaultValues?.numberOfEpisodes || null,
            props: {
              name: 'numberOfEpisodes',
              className: classes.buttonsContainerWidth,
              onChange: onChangeModifier,
              render: (props) => (
                <Input
                  {...props}
                  label="Number of Episodes"
                  type={InputType.Number}
                  className={classes.formControlInput}
                  labelClassName={classes.formControlLabel}
                  allowNegativeValues={false}
                  invalidKeys={{
                    '-': true,
                    '+': true,
                    ',': true,
                    '.': true,
                    e: true,
                    E: true,
                  }}
                />
              ),
            },
          },
        ]
      : []),
    {
      component: FormControl,
      name: 'splitBudget',
      props: {
        className: classes.formControlSlider,
        name: 'splitBudget',
        onChange: onChangeDo,
        render: (props) => (
          <Slider
            className={classes.slider}
            label="Split"
            startText={brandName}
            endText="Podcast"
            marks={{ 50: '' }}
            budgetHandle
            {...props}
            defaultValue={(defaultValues?.percentages?.podcaster || 0.5) * 100}
          />
        ),
      },
    },
  ];
  return formControlArray;
};

const getCampaignCategoriesInput = ({
  categories,
  defaultValues,
  onChangeHandler,
}) => [
  {
    component: FormControl,
    name: 'categories',
    defaultValue: defaultValues?.categories ?? [],
    props: {
      name: 'categories',
      onChange: onChangeHandler,
      render: (props) => (
        <MultipleBadgeList
          items={categories}
          className={campaignCategoriesClasses.categoriesContainer}
          {...props}
        />
      ),
    },
  },
];

const getCampaignPodcastsInput = ({
  podcasts,
  defaultValues,
  onChangeHandler,
}) => [
  {
    component: FormControl,
    name: 'podcasts',
    defaultValue: defaultValues?.podcasts ?? [],
    props: {
      name: 'podcasts',
      onChange: onChangeHandler,
      render: (props) => (
        <MultipleBadgeList
          items={podcasts}
          className={campaignCategoriesClasses.categoriesContainer}
          {...props}
        />
      ),
    },
  },
];

const getCampaignGenderAgeAndIncomeInputs = ({ defaultValues, onChangeDo }) => [
  {
    component: FormControl,
    name: 'gender',
    defaultValue: defaultValues?.gender?.female ?? 50,
    props: {
      name: 'gender',
      onChangeDo,
      render: (props) => (
        <Slider
          label="Gender"
          startText="Male"
          endText="Female"
          marks={{ 50.5: '' }}
          {...props}
        />
      ),
    },
  },
  {
    component: FormControl,
    name: 'age',
    defaultValue: defaultValues?.age ?? [],
    props: {
      name: 'age',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          multiple
          options={ageOptions}
          onChangeDo={onChangeDo}
          containerClassName={classes.multipleRectangleButtonsList}
          {...props}
        />
      ),
      className: classes.formControl,
      label: 'Age',
    },
  },
  {
    component: FormControl,
    name: 'income',
    defaultValue: defaultValues?.income ?? [],
    props: {
      name: 'income',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          multiple
          options={incomeOptions}
          containerClassName={classes.multipleRectangleButtonsList}
          {...props}
        />
      ),
      className: classes.formControl,
      label: 'Income',
    },
  },
];

const getCampaignEthnicityAndMaritalStatusInputs = ({
  defaultValues,
  onChangeDo,
}) => [
  {
    component: FormControl,
    name: 'ethnicity',
    defaultValue: defaultValues?.ethnicity ?? [],
    props: {
      name: 'ethnicity',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          multiple
          options={ethnicityOptions}
          containerClassName={classes.multipleRectangleButtonsList}
          {...props}
        />
      ),
      className: classes.formControl,
      label: 'Ethnicity',
    },
  },
  {
    component: FormControl,
    name: 'maritalStatus',
    defaultValue: defaultValues?.maritalStatus ?? [],
    props: {
      name: 'maritalStatus',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          multiple
          options={maritalStatusOptions}
          containerClassName={classes.multipleRectangleButtonsList}
          {...props}
        />
      ),
      className: classes.formControl,
      label: 'Marital Status',
    },
  },
];

const getCampaignCountriesInput = ({
  defaultValues,
  countryOptions,
  onChangeDo,
}) => [
  {
    component: FormControl,
    name: 'countries',
    defaultValue: defaultValues?.countries ?? [],
    props: {
      name: 'countries',
      onChangeDo,
      render: (props) => (
        <MultipleBadgeList
          items={countryOptions}
          className={campaignCategoriesClasses.categoriesContainer}
          {...props}
        />
      ),
    },
  },
];

const getCampaigAdPlacementInputs = ({ defaultValues, onChangeDo }) => [
  {
    component: FormControl,
    name: 'adPlacements',
    defaultValue: defaultValues?.adPlacements ?? [],
    props: {
      name: 'adPlacements',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          multiple
          options={adPlacements}
          containerClassName={classes.multipleRectangleButtonsList}
          buttonClassName={classes.multipleRectangleButtonsListButton}
          {...props}
        />
      ),
      className: classes.formControl,
    },
  },
];

const getCampaigAdFormatsInputs = ({ defaultValues, onChangeDo }) => [
  {
    component: FormControl,
    name: 'adFormats',
    defaultValue: defaultValues?.adFormats ?? [],
    props: {
      name: 'adFormats',
      onChangeDo,
      render: (props) => (
        <MultipleRectangleButtonsList
          options={adFormats.filter(({ value }) => value !== 'dynamicAd')}
          buttonClassName={classes.multipleRectangleButtonsListButton}
          containerClassName={classes.multipleRectangleButtonsList}
          {...props}
        />
      ),
      className: classes.formControl,
    },
  },
];

const getCampaignDescriptionInputs = ({ defaultValues }) => [
  {
    component: FormControlTextarea,
    name: 'description',
    props: {
      name: 'description',
      className: classes.textarea,
      placeholder: 'Type your Description',
      size: TextareaSize.M,
      defaultValue: defaultValues?.description ?? '<p></p>',
    },
  },
];

const getCampaignTalkingPointsInputs = ({ defaultValues }) => [
  {
    component: FormControlTextarea,
    name: 'talkingPoints',
    props: {
      name: 'talkingPoints',
      className: classes.textarea,
      placeholder: 'Type your Talking Points',
      size: TextareaSize.M,
      defaultValue: defaultValues?.talkingPoints ?? '<p></p>',
    },
  },
];

export {
  getCampaignNameInputs,
  getStartAndEndDatesInputs,
  getCampaignBudgetInputs,
  getCampaignCategoriesInput,
  getCampaignGenderAgeAndIncomeInputs,
  getCampaignEthnicityAndMaritalStatusInputs,
  getCampaignCountriesInput,
  getCampaigAdFormatsInputs,
  getCampaigAdPlacementInputs,
  getCampaignDescriptionInputs,
  getCampaignTalkingPointsInputs,
  getCampaignPodcastsInput,
};
