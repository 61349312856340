import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import Body, { Size as BodySize, Weight } from 'components/Typography/Body';
import IconButton from 'components/Common/IconButton';
import Checkbox from 'components/Common/Checkbox';
import deleteIcon from 'assets/icons/delete.svg';
import adTypeInputs from 'utils/inputs/addTypeInputs';
import Input, { Type } from 'components/Common/Input';
import { CPM_MODIFIER_EMAILS } from 'constants/campaign';
import plural from 'utils/commons/plural';
import formatNumberWithComas from 'utils/functions/formatNumberWithComas';

import classes from './SelectedPodcast.module.scss';

const SelectedPodcast = ({
  podcast,
  adTypes,
  onClickAdType,
  onDelete,
  budget,
  impressions,
  episodes,
  onChangeBudget,
  email,
}) => {
  const onClickAdTypeHandler = useCallback(
    (adType) => {
      onClickAdType(podcast.podcastId, adType);
    },
    [podcast.podcastId]
  );

  const onDeleteHandler = useCallback(() => {
    onDelete(podcast);
  }, [podcast]);

  return (
    <div className={classes.container}>
      <img src={podcast.podcastImageUrl} alt="logo" className={classes.image} />
      <div className={classes.info}>
        <Body size={BodySize.XS} className={classes.title}>
          {podcast.podcastName}
        </Body>
        <div className={classes.modifierContainer}>
          <div className={classes.budgetCan}>
            <div className={classes.budgetContainer}>
              <Body size={BodySize.XS} className={classes.episode}>
                {episodes}&nbsp;{plural('episode', episodes > 1)}
              </Body>
              <div className={classes.impressions}>
                <Body
                  size={BodySize.XS}
                  weight={Weight.Bold}
                  className={classes.body}
                >
                  {formatNumberWithComas(impressions)}
                </Body>
                <Body size={BodySize.XS} className={classes.body}>
                  Impressions
                </Body>
              </div>
              <Body size={BodySize.XS} className={classes.budget}>
                ${formatNumberWithComas(budget)}
              </Body>
            </div>
            <IconButton onClick={onDeleteHandler} className={classes.delete}>
              <img src={deleteIcon} alt="delete" />
            </IconButton>
          </div>
          {CPM_MODIFIER_EMAILS.includes(email) && (
            <div className={classes.inputContainer}>
              $
              <Input
                type={Type.Number}
                size="XS"
                value={budget}
                className={classes.manipulation}
                onChange={(event) =>
                  onChangeBudget(event.target.value, podcast.podcastId)
                }
              />
            </div>
          )}
        </div>
        <div className={classes.checkboxes}>
          {adTypeInputs.map(({ type, label }) => (
            <Checkbox
              key={type}
              name={type}
              label={label}
              selected={adTypes?.[type] || false}
              setSelected={() => onClickAdTypeHandler(type)}
              className={classes.checkboxItem}
              labelClassName={classes.checkboxLabel}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

SelectedPodcast.propTypes = {
  podcast: PropTypes.objectOf(PropTypes.string).isRequired,
  adTypes: PropTypes.objectOf(PropTypes.bool).isRequired,
  onClickAdType: PropTypes.func.isRequired,
  onDelete: PropTypes.func.isRequired,
  budget: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  impressions: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  episodes: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
  onChangeBudget: PropTypes.func.isRequired,
  email: PropTypes.objectOf(PropTypes.string).isRequired,
};

SelectedPodcast.defaultProps = {
  budget: 0,
  impressions: 0,
  episodes: 1,
};

export default SelectedPodcast;
