import React from 'react';
import { Link as RouterLink } from 'react-router-dom';
import PropTypes from 'prop-types';
import classNames from 'classnames';

import classes from './Link.module.scss';

const Link = ({ to, children, underline, target, onClick }) => (
  <RouterLink
    className={classNames({
      [classes.removeUnderline]: !underline,
    })}
    onClick={onClick}
    to={to}
    target={target}
  >
    {children}
  </RouterLink>
);

Link.propTypes = {
  to: PropTypes.oneOfType([PropTypes.object, PropTypes.string]).isRequired,
  children: PropTypes.node.isRequired,
  underline: PropTypes.bool,
  target: PropTypes.string,
  onClick: PropTypes.func,
};

Link.defaultProps = {
  underline: true,
  target: '',
  onClick: () => {},
};

export default Link;
