import { shallowEqual, useSelector } from 'react-redux';
import classNames from 'classnames';
import { useEffect, useState } from 'react';
import Button, {
  Type as ButtonType,
  Kind as ButtonKind,
} from 'components/Common/Button';
import {
  selectFetchCampaignCategoriesState,
  selectFetchEstimationsInCampaignState,
} from 'state/selectors/campaigns';
import commonClasses from 'pages/NewCampaign/CreateCampaign/CreateCampaign.module.scss';
import { useMultiStepFormContext } from 'components/Common/MultiStepForm';
import Form from 'components/Common/Form';
import Checkbox from 'components/Common/Checkbox';
import Body, {
  LetterCase as BodyLetterCase,
  Spacing as BodySpacing,
} from 'components/Typography/Body';
import formatNumberWithSuffix from 'utils/functions/formatNumberWithSuffix';
import {
  getCampaignSummaryFields,
  getCampaignSummaryFieldsFullWidth,
} from 'utils/inputs/campaignSummary';
import { selectFetchDefaultValues } from 'state/selectors/generals';
import { selectAuthenticatedUserState } from 'state/selectors/auth';
import Spinner from 'components/Common/Spinner';
import {
  selectFetchEstimationPodcastsState,
  selectFetchPodcastsState,
} from 'state/selectors/podcasts';
import classes from './CampaignSummaryReview.module.scss';

const CampaignSummaryReview = () => {
  const { onSubmit, goBack, formsData } = useMultiStepFormContext();
  const [isChecked, setIsChecked] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [formattedData, setFormattedData] = useState({});
  const [image, setImage] = useState('');
  const [isCreatingCampaign, setIsCreatingCampaign] = useState(false);
  const { user } = useSelector(selectAuthenticatedUserState, shallowEqual);

  const handleChecked = () => {
    setIsChecked(!isChecked);
  };

  const onSubmitHandler = (data) => {
    if (isChecked) {
      setIsCreatingCampaign(true);
    }
    onSubmit(data);
  };

  const { estimationsSummary } = useSelector(
    selectFetchEstimationsInCampaignState,
    shallowEqual
  );

  const { potentialPodcasts, estimatedImpressions } = estimationsSummary;

  const { estimationsPodcasts } = useSelector(
    selectFetchEstimationPodcastsState,
    shallowEqual
  );

  const { categories } = useSelector(
    selectFetchCampaignCategoriesState,
    shallowEqual
  );

  const { defaultValues } = useSelector(selectFetchDefaultValues, shallowEqual);
  const { podcasts } = useSelector(selectFetchPodcastsState, shallowEqual);

  useEffect(() => {
    if (user.isSuperUser && formsData) {
      const isSuperUserFormat = {
        name: formsData['0'].name,
        airingFrom: formsData['1'].airingFrom,
        airingTo: formsData['1'].airingTo,
        adFormats: formsData['2']?.adFormats,
        budget: formsData['3'].budget,
        splitBudget: formsData['3'].splitBudget,
        cpmRate: formsData['3'].cpmRate ?? defaultValues.cpmRate,
        categories: formsData['4']?.categories ?? [],
        podcasts: formsData['4']?.podcasts ?? [],
        income: formsData['5']?.income ?? [],
        age: formsData['5']?.age ?? [],
        gender: formsData['5']?.gender ?? [],
        ethnicity: formsData['6']?.ethnicity ?? [],
        maritalStatus: formsData['6']?.maritalStatus ?? [],
        countries: formsData['7']?.countries ?? [],
        adPlacements: formsData['8'].adPlacements,
        description: formsData['9'].description,
        talkingPoints: formsData['10'].talkingPoints || '',
      };
      if (formsData['11'].image) {
        const reader = new FileReader();
        reader.readAsDataURL(formsData['11'].image);
        reader.onload = (e) => {
          setImage(e.target.result);
        };
      }
      setFormattedData(isSuperUserFormat);
    } else if (formsData && !user.isSuperUser) {
      const isNotSuperUser = {
        age: formsData['4'].age,
        name: formsData['0'].name,
        airingTo: formsData['1'].airingTo,
        airingFrom: formsData['1'].airingFrom,
        budget: formsData['2'].budget,
        cpmRate: formsData['2'].cpmRate ?? defaultValues.cpmRate,
        splitBudget: formsData['2'].splitBudget,
        categories: formsData['3']?.categories ?? [],
        podcasts: formsData['3']?.podcasts ?? [],
        income: formsData['4']?.income ?? [],
        gender: formsData['4']?.gender ?? [],
        ethnicity: formsData['5']?.ethnicity ?? [],
        maritalStatus: formsData['5']?.maritalStatus ?? [],
        countries: formsData['6']?.countries ?? [],
        adPlacements: formsData['7'].adPlacements,
        description: formsData['8'].description,
        talkingPoints: formsData['9'].talkingPoints || '',
      };
      setFormattedData(isNotSuperUser);
      if (formsData['10'].image) {
        const reader = new FileReader();
        reader.readAsDataURL(formsData['10'].image);
        reader.onload = (e) => {
          setImage(e.target.result);
        };
      }
    }
    setIsLoading(false);
  }, [isLoading]);

  useEffect(() => {
    setIsLoading(true);
    if (user.isSuperUser) {
      if (formsData['3'].splitBudget && formsData['3'].splitBudget > 0) {
        formsData['3'].splitBudget = {
          podcaster: formsData['3'].splitBudget / 100,
          network: (100 - formsData['3'].splitBudget) / 100,
        };
      } else if (!formsData['3'].splitBudget?.podcaster) {
        formsData['3'].splitBudget = {
          podcaster: defaultValues.percentages?.podcaster,
          network: defaultValues.percentages?.network,
        };
      }
    } else if (!user.isSuperUser) {
      if (formsData['2'].splitBudget && formsData['2'].splitBudget > 0) {
        formsData['2'].splitBudget = {
          podcaster: formsData['2'].splitBudget / 100,
          network: (100 - formsData['2'].splitBudget) / 100,
        };
      } else if (!formsData['2'].splitBudget?.podcaster) {
        formsData['2'].splitBudget = {
          podcaster: defaultValues.percentages?.podcaster,
          network: defaultValues.percentages?.network,
        };
      }
    }
    setIsLoading(false);
  }, [isLoading]);

  const campaignFields = getCampaignSummaryFields(
    formattedData,
    categories,
    podcasts
  );

  const campaignFieldsFullWidth = getCampaignSummaryFieldsFullWidth(
    formattedData
  );

  return (
    <Form
      className={classNames(commonClasses.content, commonClasses.form)}
      onSubmit={onSubmitHandler}
    >
      {isLoading ? (
        <div className={classes.spinnerContainer}>
          <Spinner className={classes.spinner} />
        </div>
      ) : (
        <>
          <div>
            <Body
              letterCase={BodyLetterCase.Uppercase}
              spacing={BodySpacing.M}
              className={classes.title}
            >
              Campaign review
            </Body>
            <div className={classes.container}>
              {estimationsPodcasts > 0 &&
              Object.values(formsData).find((elem) => elem.podcasts).podcasts
                .length > 0 ? (
                <div className={classes.estimatedImpressions}>
                  <div className={classes.numbersEstimated}>
                    <span className={classes.number}>
                      {`${formatNumberWithSuffix(estimationsPodcasts)} `}
                    </span>
                    <div>estimated impressions</div>
                  </div>
                </div>
              ) : (
                <div className={classes.estimatedImpressions}>
                  <div className={classes.numbersEstimated}>
                    <span
                      className={classes.number}
                    >{`${potentialPodcasts} `}</span>
                    <div>potential podcasts</div>
                  </div>
                  <div className={classes.title}>
                    <span>with a</span>
                  </div>
                  <div className={classes.numbersEstimated}>
                    <span className={classes.number}>
                      {`${formatNumberWithSuffix(estimatedImpressions)} `}
                    </span>
                    <div>estimated impressions</div>
                  </div>
                </div>
              )}
              {image && (
                <div className={classes.logoContainer}>
                  <img
                    src={image}
                    alt="campaign_image"
                    className={classes.logo}
                  />
                </div>
              )}
              <div className={classes.containerGrids}>
                {campaignFields.map(({ label, component: Component }) => (
                  <div className={classes.grid}>
                    <Body className={classes.title}>{label}</Body>
                    <Component className={classes.body} />
                  </div>
                ))}
              </div>
              {campaignFieldsFullWidth.map(
                ({ label, component: Component }) => (
                  <div className={classes.containerTexts}>
                    <Body className={classes.title}>{label}</Body>
                    <Component className={classes.description} />
                  </div>
                )
              )}
              <div className={classes.checkbox}>
                <Checkbox
                  key="type"
                  name="type"
                  label="I checked all the details of this campaign and it's ready to be sent to all the podcasters."
                  selected={isChecked}
                  setSelected={handleChecked}
                  labelClassName={classes.labelCheckBox}
                />
              </div>
            </div>
          </div>
          <div
            className={classNames(
              commonClasses.buttonsContainer,
              classes.buttonsContainer
            )}
          >
            <Button
              kind={ButtonKind.Tertiary}
              className={classNames(
                commonClasses.goBackButton,
                classes.buttons
              )}
              onClick={goBack}
              disabled={false}
            >
              Back
            </Button>
            <Button
              className={classNames(
                commonClasses.submitButton,
                classes.buttons
              )}
              type={ButtonType.Submit}
              disabled={isCreatingCampaign || !isChecked}
            >
              Next
            </Button>
          </div>
        </>
      )}
    </Form>
  );
};

export default CampaignSummaryReview;
