import { createSelector } from '@reduxjs/toolkit';

const selectAuthState = ({ auth }) => auth;

export const selectSignUpState = createSelector(
  selectAuthState,
  ({ signUpLoading, signUpSuccess, signUpError }) => ({
    loading: signUpLoading,
    success: signUpSuccess,
    error: signUpError,
  })
);

export const selectLoginState = createSelector(
  selectAuthState,
  ({ loginLoading, loginSuccess, loginError }) => ({
    loading: loginLoading,
    success: loginSuccess,
    error: loginError,
  })
);

export const selectVerifyAuthState = createSelector(
  selectAuthState,
  ({ verifyAuthLoading, verifyAuthSuccess, verifyAuthError }) => ({
    loading: verifyAuthLoading,
    success: verifyAuthSuccess,
    error: verifyAuthError,
  })
);

export const selectVerifyBrandPrivilegesState = createSelector(
  selectAuthState,
  ({
    verifyBrandPrivilegesLoading,
    verifyBrandPrivilegesSuccess,
    verifyBrandPrivilegesError,
    user: { isSuperUser },
  }) => ({
    loading: verifyBrandPrivilegesLoading,
    success: verifyBrandPrivilegesSuccess,
    error: verifyBrandPrivilegesError,
    isSuperUser,
  })
);

export const selectAuthenticatedUserState = createSelector(
  selectAuthState,
  ({ user }) => ({ user })
);

export const selectLogoutState = createSelector(
  selectAuthState,
  ({ logoutLoading, logoutSuccess, logoutError }) => ({
    loading: logoutLoading,
    success: logoutSuccess,
    error: logoutError,
  })
);

export const selectResetPasswordState = createSelector(
  selectAuthState,
  ({ resetPasswordLoading, resetPasswordSuccess, resetPasswordError }) => ({
    loading: resetPasswordLoading,
    success: resetPasswordSuccess,
    error: resetPasswordError,
  })
);

export const selectNewPasswordState = createSelector(
  selectAuthState,
  ({ newPasswordLoading, newPasswordSuccess, newPasswordError }) => ({
    loading: newPasswordLoading,
    success: newPasswordSuccess,
    error: newPasswordError,
  })
);

export const selectEditUserState = createSelector(
  selectAuthState,
  ({ editUserLoading, editUserSuccess, editUserError, user }) => ({
    loading: editUserLoading,
    success: editUserSuccess,
    error: editUserError,
    user,
  })
);
